<ng-template [ngIf]="hasCurrentUserWriteAccess" [ngIfElse]="valueOnly">
  <a
    xpo-link
    *ngIf="uiEntityProperty?.propertyName === 'equipmentFullId'"
    matTooltip="Click to go to scale tests for this scale"
    (click)="onScaleTestSearchClicked(uiEntityData)"
  >
    {{ uiEntityData[uiEntityProperty.propertyName] }}
  </a>
</ng-template>
<ng-template #valueOnly>
  {{ uiEntityData[uiEntityProperty.propertyName] }}
</ng-template>
