import {Injectable} from '@angular/core';
import {UiEntity} from '@shared/classes/entities/ui-entity';
import {UiEntityExpandableTableEntity} from '@shared/classes/entities/ui-entity-expandable-table-entity';
import {UiEntityProperty} from '@shared/classes/entities/ui-entity-property';
import {UiEntityTableEntity} from '@shared/classes/entities/ui-entity-table-entity';
import {UiEntityUtil} from '@shared/classes/utils/ui-entity-util';
import {UiEntityMatTableComponent} from '@shared/components/ui-entity-mat-table/ui-entity-mat-table-component';
import {UiEntityTableCellComponentBase} from '../../../directives/ui-entity-table-cell-component-base';

/**
 * datasource is found into entity which implements
 */
@Injectable()
export abstract class UiEntityExpandableMatTableComponent<
  TUiEntityExpandedTableEntity extends UiEntityTableEntity & UiEntityExpandableTableEntity<UiEntityTableEntity>,
  TtableCustomComponent extends UiEntityTableCellComponentBase<TUiEntityExpandedTableEntity>,
> extends UiEntityMatTableComponent<TUiEntityExpandedTableEntity, TtableCustomComponent> {

  readonly EXPAND_COLLAPSE_COL_DEF_TITLE: string = 'expandCollapse';

  expandedUiEntityProperties: UiEntityProperty[];
  expandedVisibleColumns: string[];
  isTableColumnsExpandedForExpandedRow: boolean = false;
  isExpandedRowVisible: boolean = true;

  /**
   * Override
   */
  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.expandedUiEntityProperties = this.getExpandedUiEntity().properties;
    this.expandedVisibleColumns = this.getExpandedVisibleColumns();
  }

  getExpandedVisibleColumns(): string[] {
    const result: string[] = UiEntityUtil.toVisibleColumnNames(
      this.getExpandedUiEntity(),
      null,
      !this.isTableColumnsExpandedForExpandedRow,
      this.isTableColumnsExpandedForExpandedRow
    );
    // console.debug('getVisibleColumns for ' + this.getUiEntity().entityTypeName, result);
    return result;
  }

  abstract getExpandedUiEntity(): UiEntity;

  getExpandedElementTitle(aUiEntityProperty: UiEntityProperty): string {
    return UiEntityUtil.getHeaderName(aUiEntityProperty.propertyName, this.getExpandedUiEntity().properties);
  }

  getExpandedElementTableHeaderTooltip(aUiEntityProperty: UiEntityProperty): string {
    const result: string = this.getExpandedElementTableHeaderTitle(aUiEntityProperty);
    return result;
  }

  getExpandedElementTableHeaderTitle(aUiEntityProperty: UiEntityProperty): string {
    return UiEntityUtil.getHeaderName(aUiEntityProperty.propertyName, this.getExpandedUiEntity().properties);
  }

  /**
   * Show/hide expanded row
   * @param $event
   */
  toggleShowExpandedRow($event: MouseEvent) {
    this.isExpandedRowVisible = !this.isExpandedRowVisible;
  }

  /**
   * Show more/less columns for expanded row table
   *
   * @param $event
   */
  toggleTableColumnsExpandedForExpandedRow($event: MouseEvent) {
    this.isTableColumnsExpandedForExpandedRow = !this.isTableColumnsExpandedForExpandedRow;
    this.expandedVisibleColumns = this.getExpandedVisibleColumns();
  }

  /**
   * override
   */
  getVisibleColumns(): string[] {
    const result: string[] = super.getVisibleColumns();
    result.unshift(this.EXPAND_COLLAPSE_COL_DEF_TITLE);
    return result;
  }


  abstract isExpandDetailRowsButtonVisible(): boolean;

}
