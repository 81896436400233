import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reweigh-header-summary-dialog',
  templateUrl: './reweigh-header-summary-dialog.component.html',
  styleUrls: ['./reweigh-header-summary-dialog.component.scss'],
})
export class ReweighHeaderSummaryDialogComponent implements OnInit {
  logHeaderId: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.logHeaderId = data.logHeaderId;
    if (!this.logHeaderId || this.logHeaderId <= 0) {
      throw Error('Invalid logHeaderId given');
    }
  }

  static getDialogConfig(aLogHeaderId: number): any {
    const result: any = {
      maxWidth: '95vw',
      maxHeight: '95vh',
      data: {
        title: `Summary for Log Header ID ${aLogHeaderId}`,
        logHeaderId: aLogHeaderId,
      },
    };
    return result;
  }

  ngOnInit(): void {}
}
