import { Router } from '@angular/router';
import { AppRoutes } from '@shared/enums';
import { ScaleTestRouting } from '@shared/interfaces/scale-test-routing';

export class ScaleTestUtil {
  static navigateToScaleTestsFor(aScaleTestRouting: ScaleTestRouting, aRouter: Router) {
    aRouter.navigate([
      AppRoutes.REWEIGH_CALIBRATION_HEADERS,
      {
        vendorScaleId: aScaleTestRouting.vendorScaleId,
        equipmentPrefix: aScaleTestRouting.equipmentIdPrefix,
        equipmentSuffix: aScaleTestRouting.equipmentIdSuffixNbr,
      },
    ]);
  }
}
