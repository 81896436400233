import { DateHelper } from '@shared/classes/date-helper';
import { MiscUtil } from '@shared/classes/utils/misc-util';

export class ScaleForkliftReport {

  constructor(
    vendorScaleId: string,
    contentDate: Date,
    sicCode: string,
    equipmentIdPrefix: string,
    equipmentIdSuffixNbr: number,
    recordsCount: number
  ) {
    this.vendorScaleId = vendorScaleId;
    this.contentDate = contentDate;
    this.sicCode = sicCode;
    this.equipmentIdPrefix = equipmentIdPrefix;
    this.equipmentIdSuffixNbr = equipmentIdSuffixNbr;
    this.recordsCount = recordsCount;
    this.setExtradata();
  }
  static readonly EQUIPMENT_ID_SUFFIX_STRING_CHAR_COUNT: number = 4;

  vendorScaleId: string;
  contentDate: Date;
  sicCode: string;
  equipmentIdPrefix: string;
  equipmentIdSuffixNbr: number;
  equipmentFullId: string;
  recordsCount: number;
  monthYear: string;
  uniqueKeyField: string; // a unique key field for ag-grid
  isDuplicateVendorScaleId: boolean = false;
  isDuplicateEquipmentId: boolean = false;
  isDuplicateVendorScaleEquipmentId: boolean = false;
  isScaleIdZero: boolean = false;
  isDuplicateVendorScaleSic: boolean = false;

  static getByUniqueId(haystack: ScaleForkliftReport[], uniqueKeyFieldNeedle: string): ScaleForkliftReport[] {
    const result: ScaleForkliftReport[] = [];
    for (const scaleForkliftReport of haystack) {
      if (scaleForkliftReport.uniqueKeyField === uniqueKeyFieldNeedle) {
        result.push(scaleForkliftReport);
      }
    }
    return result;
  }

  static leftPaddedEquipmentIdSuffix(equipmentIdSuffix: number): string {
    return MiscUtil.leftPadWithZeros(equipmentIdSuffix, ScaleForkliftReport.EQUIPMENT_ID_SUFFIX_STRING_CHAR_COUNT);
  }

  static getEquipmentFullId(equipmentIdPrefix: string, equipmentIdSuffixNbr: number): string | undefined {
    let result: string;
    if (equipmentIdPrefix && !isNaN(equipmentIdSuffixNbr)) {
      result = equipmentIdPrefix + '-' + ScaleForkliftReport.leftPaddedEquipmentIdSuffix(equipmentIdSuffixNbr);
    }

    return result;
  }

  protected setExtradata() {
    this.equipmentFullId = ScaleForkliftReport.getEquipmentFullId(this.equipmentIdPrefix, this.equipmentIdSuffixNbr);
    this.monthYear = DateHelper.getPortandTzFormattedDate(this.contentDate, 'MM/YYYY');
    this.uniqueKeyField = '' + Date.now() + Math.random();
    this.isScaleIdZero = +this.vendorScaleId === 0;
  }

  isInvalidEquipmentId(): boolean {
    return this.equipmentIdPrefix?.length === 0 || isNaN(this.equipmentIdSuffixNbr) || this.equipmentIdSuffixNbr === 0;
  }

  getVendorEquipmentId(): string {
    return this.vendorScaleId + '-' + this.equipmentFullId;
  }

  getVendorSicId(): string {
    return this.vendorScaleId + '-' + this.sicCode;
  }

  isValidEquipmentId(): boolean {
    let result: boolean = false;
    if (this.equipmentIdPrefix && this.equipmentIdSuffixNbr) {
      result = this.equipmentIdPrefix.length > 0 && !isNaN(this.equipmentIdSuffixNbr);
    }
    return result;
  }
}
