import { AccessRole } from '@shared/enums';
import { User } from '@xpo-ltl/sdk-common';

export class XpoLtlLoggedInUser {
  constructor(aRawUser: User) {
    this.rawUser = aRawUser;
    this.setHighestReweighApplicationRole();
  }
  protected static readonly REMOVE_DOMAIN_REGEX = /.*\//gm;

  protected rawUser: User;
  protected highestReweighApplicationRole: AccessRole;

  /**
   *
   * @param aVal: any value from AccessRole enum
   * @protected
   */
  static getApplicationRoleFromValue(aVal: string): AccessRole {
    const accessRoleValues: AccessRole[] = Object.values(AccessRole);
    for (const accessRole of accessRoleValues) {
      if (accessRole.valueOf() === aVal) {
        return accessRole; // cant break :(
      }
    }
    throw new Error('given value not found in AccessRoles:' + aVal);
  }

  static getAccessRoleLabel(aRole: AccessRole): string {
    if (!aRole) {
      throw Error('aRole should not be undefined');
    } else {
      let result: string = 'None';
      switch (aRole) {
        case AccessRole.LTL_REWEIGH_SUPER_USER: {
          result = 'Super User';
          break;
        }
        case AccessRole.LTL_REWEIGH_REVIEW: {
          result = 'Reweigh Review User';
          break;
        }
        case AccessRole.TST_LTL_REWEIGH_REVIEW: {
          result = 'Reweigh Review Test User';
          break;
        }
        case AccessRole.LTL_REWEIGH_READ_ONLY: {
          result = 'Reweigh Readonly User';
          break;
        }
        default: {
          throw Error('Unknown Role for label:' + aRole);
        }
      }
      return result;
    }
  }

  /**
   * True if user has any of allowed roles in AccessRole enum
   */
  isAuthorizedUser(): boolean {
    let result: boolean = false;

    const accessRoleValues: AccessRole[] = Object.values(AccessRole);
    for (const accessRole of accessRoleValues) {
      if (this.hasRole(this.rawUser.roles, accessRole)) {
        result = true;
        break;
      }
    }
    if (!result) {
      console.warn('Current user is not authorized');
    }
    return result;
  }

  protected hasRole(userRoles: string[], accessRole: AccessRole): boolean {
    let result: boolean = false;
    for (const userRole of userRoles) {
      const lUppercaseRoleWithoutDomain: string = userRole
        .replace(XpoLtlLoggedInUser.REMOVE_DOMAIN_REGEX, '')
        .toUpperCase();
      if (lUppercaseRoleWithoutDomain === accessRole.toString()) {
        result = true;
        // console.debug('found: ', lUppercaseRoleWithoutDomain, accessRole.toString(), userRole)
        break;
      }
    }

    return result;
  }

  /**
   * Only strict role, not hierachical
   * @param accessRole
   */
  hasStrictRole(accessRole: AccessRole): boolean {
    return this.hasRole(this.rawUser.roles, accessRole);
  }

  protected setHighestReweighApplicationRole() {
    if (this.hasStrictRole(AccessRole.LTL_REWEIGH_SUPER_USER)) {
      this.highestReweighApplicationRole = AccessRole.LTL_REWEIGH_SUPER_USER;
    } else if (this.hasStrictRole(AccessRole.LTL_REWEIGH_REVIEW)) {
      this.highestReweighApplicationRole = AccessRole.LTL_REWEIGH_REVIEW;
    } else if (this.hasStrictRole(AccessRole.TST_LTL_REWEIGH_REVIEW)) {
      this.highestReweighApplicationRole = AccessRole.TST_LTL_REWEIGH_REVIEW;
    } else if (this.hasStrictRole(AccessRole.LTL_REWEIGH_READ_ONLY)) {
      this.highestReweighApplicationRole = AccessRole.LTL_REWEIGH_READ_ONLY;
    }
  }
}
