import { AccessRole } from '@shared/enums';
import { KeyValueLabel } from '@shared/interfaces';
import { XpoLtlLoggedInUser } from "@shared/classes/access/xpo-ltl-logged-in-user";

export class ReweighApplicationRole {

  constructor(aRole: AccessRole) {
    if (!aRole) {
      throw Error('aRole should not be undefined');
    }
    this.accessRole = aRole;
  }
  accessRole: AccessRole;



  toKeyValueLabel(): KeyValueLabel {
    const result: KeyValueLabel = {
      key: this.accessRole.toString(),
      value: this.accessRole.toString(),
      label: this.getLabel(),
    };
    return result;
  }

  getLabel() : string{
    return XpoLtlLoggedInUser.getAccessRoleLabel(this.accessRole);
  }

  getValue(): string{
    return this.accessRole.toString()
  }

  getStorageValue(): string{
    return this.accessRole.toString()
  }

}
