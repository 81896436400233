import { Injectable } from '@angular/core';
import { isEmpty as _isEmpty } from 'lodash';

@Injectable()
export class RequestValidator {
  constructor() {}

  public static validateRequestStringIsNotNullOrBlank(requestString: string, fieldName: string): void {
    if (!requestString || requestString.trim().length === 0) {
      throw new Error(`${fieldName} cannot be empty.`);
    }
  }
}
