import { Component, forwardRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConstantsService } from '@shared/services/constants';
import { XpoLtlSicSwitcherComponent } from '@xpo-ltl/ngx-ltl';

@Component({
  selector: 'app-sic-switcher-wrapper',
  templateUrl: './sic-switcher-wrapper.component.html',
  styleUrls: ['./sic-switcher-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'sic-switcher-wrapper',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SicSwitcherWrapperComponent),
      multi: true,
    },
  ],
})
export class SicSwitcherWrapperComponent implements ControlValueAccessor, OnInit {
  @ViewChild(XpoLtlSicSwitcherComponent, { static: true }) sicSwitcher: XpoLtlSicSwitcherComponent;
  @Input() title = 'SIC';

  /** Self control */
  disabled: boolean;
  touched: boolean;
  onChange: (arg0: string) => void;
  onTouched: () => void;

  /** Sic to update component only  */
  sicComponent: string;
  /** Sic model */
  sicCdModel: string;

  constructor(private constantsService: ConstantsService) {}

  ngOnInit(): void {
    // We have to clean the formControlModel, because the value can not exist as SIC
    // This value will be updated if SIC exists with handleSicChanged function
    this.change(null);
    this.loadSics();
  }

  handleSicChanged(sic: string): void {
    this.sicCdModel = sic;
    this.change(sic);
    this.markAsTouched();
  }

  removeSic() {
    this.sicSwitcher?.selectSic(null);
  }

  // #region ControlValueAccessor
  writeValue(sic: string): void {
    if (sic === null) {
      this.removeSic();
    } else {
      this.sicComponent = sic;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched;
      this.touched = true;
    }
  }

  change(value: string): void {
    // Angular does not know that the value has changed
    // from our component, so we need to update her with the new value.
    if (this.onChange) {
      this.onChange(value);
    }
  }

  private loadSics() {
    this.constantsService.loadSics(this.sicSwitcher);
  }
  // #endregion
}
