import { AccessRole } from '@shared/enums';
import { ReweighApplicationRole } from '@shared/services/user-role/reweigh-application-role';

export class HierarchicalRoles {
  protected static readonly REWEIGH_APP_ROLE_SU: ReweighApplicationRole = new ReweighApplicationRole(
    AccessRole.LTL_REWEIGH_SUPER_USER
  );

  // tslint:disable-next-line:max-line-length
  protected static readonly REWEIGH_APP_ROLE_LTL_REWEIGH_REVIEW: ReweighApplicationRole = new ReweighApplicationRole(
    AccessRole.LTL_REWEIGH_REVIEW
  );
  // tslint:disable-next-line:max-line-length
  protected static readonly REWEIGH_APP_ROLE_TST_LTL_REWEIGH_REVIEW: ReweighApplicationRole = new ReweighApplicationRole(
    AccessRole.TST_LTL_REWEIGH_REVIEW
  );
  // tslint:disable-next-line:max-line-length
  protected static readonly REWEIGH_APP_ROLE_LTL_REWEIGH_READ_ONLY: ReweighApplicationRole = new ReweighApplicationRole(
    AccessRole.LTL_REWEIGH_READ_ONLY
  );

  /**
   * first is highest, last is lowest
   * @protected
   */
  protected availableRoles: Set<ReweighApplicationRole>;

  protected loggedInUserRole: ReweighApplicationRole;
}
