import { SelectionModel } from '@angular/cdk/collections';
import { formatNumber } from '@angular/common';
import { AfterViewInit, ComponentFactory, ComponentRef, Injectable, QueryList } from '@angular/core';
import {SortDirection} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DateHelper } from '@shared/classes/date-helper';
import { UiEntities } from '@shared/classes/entities/ui-entities';
import { UiEntity } from '@shared/classes/entities/ui-entity';
import { UiEntityProperty } from '@shared/classes/entities/ui-entity-property';
import { UiEntityTableEntity } from '@shared/classes/entities/ui-entity-table-entity';
import { MiscUtil } from '@shared/classes/utils/misc-util';
import { UiEntityUtil } from '@shared/classes/utils/ui-entity-util';
import {ExpandableCollapsableComponent} from '@shared/enums/expandable-collapsable-component';
import { ReweighLogDateFormats } from '@shared/enums/reweigh-log/date-formats.enum';
import { UiEntityPropertyTypeEnum } from '@shared/enums/ui-entity-property-type-enum';
import {CollapsableExpandable} from '@shared/interfaces/collapsable-expandable';
import { YesNoOptionPipe } from '@shared/pipes';
import {ReweighLogSummaryService} from '@shared/services/reweigh-log-summary.service';
import { UiEntityTableCellDirective } from '../../../directives/ ui-entity-table-cell-directive';
import { UiEntityTableCellComponentBase } from '../../../directives/ui-entity-table-cell-component-base';

/**
 * Use with
 *
 * @Component({
 *   templateUrl: "../../../shared/components/ui-entity-mat-table/ui-entity-mat-table.component.html",
 *   styleUrls: [
 *    "../lib/css/reweigh-log-table-section.scss",
 *    "../../../shared/components/ui-entity-mat-table/ui-entity-mat-table.component.scss"],
 *   encapsulation: ViewEncapsulation.None
 * })
 *
 * if you want your component to be projected you need to call
 *
 *     this.uiEntityTableCellDirectiveViewChildren.changes.subscribe((aUiEntityTableCellDirectiveQryList: any) => {
 *       // note that we receive aUiEntityTableCellDirectiveQryList as argument,
 *       // but its the same as this.uiEntityTableCellDirectiveViewChildren so we can use that one as well
 *       this.loadRefs(this.uiEntityTableCellDirectiveViewChildren, this.getComponentFactoryToProject());
 *     });
 *
 *   once your UiEntityPropertyTypeEnum.CUSTOM component is in the DOM
 *
 */

@Injectable()
export abstract class UiEntityMatTableComponent<
  TUiEntityTableEntity extends UiEntityTableEntity,
  TtableCustomComponent extends UiEntityTableCellComponentBase<TUiEntityTableEntity>
> implements AfterViewInit, CollapsableExpandable {

  isTitleSectionDisplayed: boolean = true;
  dataIsLoaded: boolean = false;
  isTableColumnsExpanded: boolean = false;

  visibleColumns: string[];
  selection = new SelectionModel<TUiEntityTableEntity>(false, []);
  tableSort: any;
  uiEntityProperties: UiEntityProperty[];

  UiEntityPropertyTypeEnum = UiEntityPropertyTypeEnum;

  dataSource: MatTableDataSource<TUiEntityTableEntity> = new MatTableDataSource<TUiEntityTableEntity>();
  readonly SORT_DIRECTION_DESC: string = 'desc' as SortDirection;
  readonly SORT_DIRECTION_ASC: SortDirection = 'asc' as SortDirection;
  readonly DEFAULT_SORT_DIRECTION: string = this.SORT_DIRECTION_DESC;

  constructor(
    protected reweighLogSummaryService: ReweighLogSummaryService,
  ) {
  }

  abstract getUiEntity(): UiEntity;

  ngAfterViewInit(): void {
    this.dataSource.sort = this.tableSort;
    this.uiEntityProperties = this.getUiEntity().properties;
    this.visibleColumns = this.getVisibleColumns();
  }

  getVisibleColumns(): string[] {
    const result: string[] = UiEntityUtil.toVisibleColumnNames(
      this.getUiEntity(),
      null,
      !this.isTableColumnsExpanded,
      this.isTableColumnsExpanded
    );
    // console.debug('getVisibleColumns for ' + this.getUiEntity().entityTypeName, result);
    return result;
  }

  toggleCollapseExpandData(anEvent: any) {
    this.isTableColumnsExpanded = !this.isTableColumnsExpanded;
    this.visibleColumns = this.getVisibleColumns();
  }

  /**
   * Main title of your page
   */
  abstract getMainTitle(): string;

  /**
   * Wanna show a load to display section?
   */
  abstract isLoadDataButtonDisplayed(): boolean;

  /**
   * which property should we sort the table by default?
   */
  abstract getSortDefaultColumnName(): string;

  getElementTableHeaderTooltip(aUiEntityProperty: UiEntityProperty): string {
    const result: string = this.getElementTableHeaderTitle(aUiEntityProperty);
    return result;
  }

  /**
   * triggered when load data is clicked
   * @param aEvent
   */
  onLoadClicked(aEvent) {}

  getSortDefaultDirection() {
    return this.DEFAULT_SORT_DIRECTION;
  }

  getElementTableHeaderTitle(aUiEntityProperty: UiEntityProperty): string {
    return UiEntityUtil.getHeaderName(aUiEntityProperty.propertyName, this.getUiEntity().properties);
  }

  getValueFromRowData(aRowData: any, aUiEntityProperty: UiEntityProperty): string {
    let result: string = '';
    // console.debug(aRowData)
    const propertyValue: any = MiscUtil.getValueFromObjectProperty(aRowData, aUiEntityProperty.propertyName);
    switch (aUiEntityProperty.variableType) {
      case UiEntityPropertyTypeEnum.DATE: {
        result = DateHelper.getPortandTzFormattedDate(propertyValue, ReweighLogDateFormats.ANGULAR_PIPE);
        break;
      }
      case UiEntityPropertyTypeEnum.BOOLEAN: {
        result = YesNoOptionPipe.formatOrNull(propertyValue);
        break;
      }
      case UiEntityPropertyTypeEnum.NUMBER: {
        result = this.getValueFromRowDataNumberImpl(
          propertyValue,
          aUiEntityProperty,
          UiEntities.NOT_FORMATTED_NUMBER_COLUMN_NAMES
        );
        break;
      }
      case UiEntityPropertyTypeEnum.CURRENCY_USD: {
        result = MiscUtil.formattedUsdAmount(propertyValue);
        break;
      }
      default: {
        // nothing, just string
        result = propertyValue;
      }
    }
    // console.debug('prop:'+aUiEntityProperty.propertyName+' result:',result)

    return result;
  }

  /**
   * Dynamically loading component
   * https://angular.io/guide/dynamic-component-loader
   * https://iwconnect.com/creating-components-dynamically-with-component-factory-in-angular/
   */
  loadRefs(
    aUiEntityTableCellDirectiveViewChildren: QueryList<UiEntityTableCellDirective<TUiEntityTableEntity>>,
    aComponentFactoryToProject: ComponentFactory<TtableCustomComponent>
  ) {
    // with viewChildren with custom directive
    aUiEntityTableCellDirectiveViewChildren.forEach(
      (lSourceDirective: UiEntityTableCellDirective<TUiEntityTableEntity>) => {
        lSourceDirective.viewContainerRef.clear();
        // source directive, create Component from above factory
        // tslint:disable-next-line:max-line-length
        const lUiEntityCustomComponentRef: ComponentRef<TtableCustomComponent> = lSourceDirective.viewContainerRef.createComponent(
          aComponentFactoryToProject
        );
        // set new Component data
        lUiEntityCustomComponentRef.instance.uiEntityData = lSourceDirective.rowData;
        lUiEntityCustomComponentRef.instance.uiEntityProperty = lSourceDirective.uiEntityProperty;
        // console.debug('loadRefs for:', lSourceDirective.uiEntityProperty, lSourceDirective.rowData)
      }
    );
  }

  getNgClassForHeaderAndCell(uiEntityProperty: UiEntityProperty): string {
    return 'ui-entity-column-type-' + uiEntityProperty.variableType;
  }

  abstract getComponentFactoryToProject(): ComponentFactory<TtableCustomComponent>;

  getMatTableMoreLessTooltip(): string {
    let result: string = 'Click to see ';

    if (this.isTableColumnsExpanded) {
      result += 'less';
    } else {
      result += 'more';
    }
    result += ' columns';

    return result;
  }

  protected isNumberFormattedValueFromRowData(
    aUiEntityProperty: UiEntityProperty,
    aNotFormattedPropertiesNames: string[]
  ) {
    let result: boolean = true;
    if (aUiEntityProperty?.propertyName) {
      if (
        aUiEntityProperty?.uiEntityPropertyOptions?.isNumberNotFormatted ||
        aNotFormattedPropertiesNames?.includes(aUiEntityProperty.propertyName)
      ) {
        result = false;
      }
    }
    return result;
  }

  protected getValueFromRowDataNumberImpl(
    aPropertyValue: any,
    aUiEntityProperty: UiEntityProperty,
    aNotFormattedNumberColumNames: string[]
  ): string {
    let result: string = '';
    if (aPropertyValue) {
      if (this.isNumberFormattedValueFromRowData(aUiEntityProperty, aNotFormattedNumberColumNames)) {
        result = formatNumber(aPropertyValue, 'en-US');
      } else {
        result = aPropertyValue;
      }
    } else if (!isNaN(aPropertyValue)) {//otherwise 0 considered as (!aPropertyValue)
      result = aPropertyValue;
    }

    return result;
  }

  isExpanded(): boolean {
    return this.isTableColumnsExpanded;
  }

  toggleCollapseExpand() {
    this.toggleCollapseExpandData(undefined);
  }


  onCollapseExpandColumnsClicked($event: MouseEvent, componentForColumnsExpandCollapse: ExpandableCollapsableComponent) {
    this.reweighLogSummaryService.onCollapseExpandClicked(componentForColumnsExpandCollapse);
  }

  abstract getComponentForColumnsExpandCollapse(): ExpandableCollapsableComponent;

}
