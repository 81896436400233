import { IOperatorFirstnamLastname } from '@shared/classes/entities/operator-firstname-lastname';
import { UiEntityTableEntity } from '@shared/classes/entities/ui-entity-table-entity';
import { MiscUtil } from '@shared/classes/utils/misc-util';
import { LogDetailHist } from '@xpo-ltl/sdk-reweigh';
import { ScaleForkliftReport } from '../../../reweigh-calibration-headers/lib/scale-forklift-report';

export class OperatorLogDetailHist extends LogDetailHist implements IOperatorFirstnamLastname, UiEntityTableEntity {
  operatorFullName: string;
  equipmentIdSuffixNbrString: string;
  equipmentFullId: string;

  static toOperatorLogDetailHist(logHeader: LogDetailHist): OperatorLogDetailHist {
    let result: OperatorLogDetailHist = null;
    if (logHeader) {
      result = new OperatorLogDetailHist();
      Object.assign(result, logHeader); // Object.assign(target, source)
      result.setOperatorFullName();
      result.setEquipmentFullId();
      result.setEquipIdSuffixString();
    }
    return result;
  }

  setOperatorFullName() {
    if (this.operatorFirstName) {
      this.operatorFullName = this.operatorFirstName;
    }
    if (this.operatorLastName) {
      if (this.operatorFullName?.length > 0) {
        this.operatorFullName += ' ' + this.operatorLastName;
      }
    }
  }

  private setEquipIdSuffixString() {
    if (this.equipmentIdSuffixNbr) {
      this.equipmentIdSuffixNbrString = MiscUtil.leftPadWithZeros(
        this.equipmentIdSuffixNbr,
        ScaleForkliftReport.EQUIPMENT_ID_SUFFIX_STRING_CHAR_COUNT
      );
    }
  }

  setEquipmentFullId() {
    this.equipmentFullId = ScaleForkliftReport.getEquipmentFullId(this.equipmentIdPrefix, this.equipmentIdSuffixNbr);
  }

}
