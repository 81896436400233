import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { YesNoOptionPipe } from '@shared/pipes';
import { SharedModule } from '@shared/shared.module';
import { XpoLtlPipeModule } from '@xpo-ltl/ngx-ltl';
import { ReweighLogCommonModule } from 'app/reweigh-log-headers/reweigh-log-common/reweigh-log-common.module';
import { ReweighLogSummaryPageComponent } from './reweigh-log-summary.component';
import { ReweighLogSummaryRoutingModule } from './reweigh-log-summary.routing';

@NgModule({
  declarations: [ReweighLogSummaryPageComponent],
  imports: [CommonModule, SharedModule, ReweighLogCommonModule, ReweighLogSummaryRoutingModule, XpoLtlPipeModule],
  providers: [YesNoOptionPipe],
  exports: [ReweighLogSummaryPageComponent],
})
export class ReweighLogSummaryPageModule {}
