<div mat-dialog-title class="dialog-title">
  <h1 mat-dialog-title>
    {{ data?.title }}
  </h1>
  <div class="close">
    <button mat-icon-button (click)="close()" matTooltip="Click to close or Escape or Click outside the dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-content class="info-dialog_content" [innerHTML]="data?.message"></div>
