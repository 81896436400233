import { Component } from '@angular/core';
import { DmsDocument } from '@shared/classes/dms-document/dms-document';
import { ReweighAppNotificationService } from '@shared/services/reweigh-app-notification.service';
import { LogHeader } from '@xpo-ltl/sdk-reweigh';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { isEmpty as _isEmpty } from 'lodash';
import { DmsApiWrapper } from '../../../reweigh-log-summary/services/dms-api-wrapper.service';

@Component({
  selector: 'app-certificate-cell-renderer',
  templateUrl: './certificate-cell-renderer.component.html',
  styleUrls: ['./certificate-cell-renderer.component.scss'],
})
export class CertificateCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  rowData: LogHeader;
  isImageCreated: string;

  constructor(
    private dmsApiWrapper: DmsApiWrapper,
    protected reweighAppNotificationService: ReweighAppNotificationService
  ) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.isImageCreated = params.data.imageCreatedInd;
    this.rowData = this.params.node.data;
  }

  onViewCertificateClicked(): void {
    this.dmsApiWrapper
      .getDmsDocument$(this.rowData.proNbr, this.rowData.auditInfo.createdTimestamp)
      .subscribe((dmsDocument: DmsDocument) => {
        if (!_isEmpty(dmsDocument)) {
          const file = new Blob([dmsDocument.getDecodedData()], {
            type: dmsDocument.getMimeType() + ';base64',
          });
          const url = window.URL.createObjectURL(file);
          window.open(url);
        } else {
          this.reweighAppNotificationService.error('No document info available');
        }
      });
  }

  onDownloadCertificateClicked(): void {
    this.dmsApiWrapper
      .getDmsDocument$(this.rowData.proNbr, this.rowData.auditInfo.createdTimestamp)
      .subscribe((dmsDocument: DmsDocument) => {
        if (!_isEmpty(dmsDocument)) {
          const file: Blob = new Blob([dmsDocument.getDecodedData()], {
            type: dmsDocument.getMimeType() + ';base64',
          });
          const link: HTMLAnchorElement = document.createElement('a');
          link.href = window.URL.createObjectURL(file);
          link.download = dmsDocument.getFileName();
          link.click();
        } else {
          this.reweighAppNotificationService.error('No document info available');
        }
      });
  }

  refresh(): boolean {
    return false;
  }
}
