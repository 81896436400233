import {
  DocumentSearch,
  GetDocumentResp,
  SearchDmsDocumentResp
} from '@xpo-ltl/sdk-documentmanagement/lib/api-documentmanagement';
import { isEmpty as _isEmpty } from 'lodash';
import { decode } from 'typescript-base64-arraybuffer';

export class DmsDocument {
  protected proNumber: string;
  protected timestamp: string;
  protected corpCode: string;
  protected docClass: string;
  protected decodedData: Uint8Array;
  protected fileName: string;
  protected mimeType: string;
  protected base64Data: string;

  constructor(aProNumber: string) {
    this.setProNumber(aProNumber);
    this.setFileName(aProNumber);
  }

  static getMostRecentDocument(searchDmsDocumentResp: SearchDmsDocumentResp, aDocClass: string): DocumentSearch {
    const documentSearch: DocumentSearch = searchDmsDocumentResp?.documentInfo
      ?.filter((doc: DocumentSearch) => doc?.cdt?.docClass === aDocClass)
      ?.reduce((prev, current) => (prev?.cdt?.timestamp > current?.cdt?.timestamp ? prev : current));
    return documentSearch;
  }

  setFromDocumentSearchData(documentSearch: DocumentSearch): void {
    this.setTimestamp(documentSearch?.cdt?.timestamp);
    this.setCorpCode(documentSearch?.cdt?.corpCode);
    this.setDocClass(documentSearch?.cdt?.docClass);
  }

  setFromDocumentRespData(resp: GetDocumentResp): void {
    this.setBase64Data(resp?.documentData);
    this.setMimeType(resp?.contentType);
  }

  decodeBase64Data(): void {
    if (!_isEmpty(this.base64Data)) {
      this.decodedData = decode(this.base64Data);
    }
  }

  buildFileName(): void {
    let extension: string;
    if (!_isEmpty(this.mimeType)) {
      extension = this.mimeType?.substring(this.mimeType?.lastIndexOf('/') + 1, this.mimeType?.length);
      this.fileName = this.fileName?.concat('.')?.concat(extension);
    }
  }

  // getters
  getProNumber(): string {
    return this.proNumber;
  }

  getTimestamp(): string {
    return this.timestamp;
  }

  getCorpCode(): string {
    return this.corpCode;
  }

  getDocClass(): string {
    return this.docClass;
  }

  getDecodedData(): Uint8Array {
    return this.decodedData;
  }

  getFileName(): string {
    return this.fileName;
  }

  getMimeType(): string {
    return this.mimeType;
  }

  getBase64Data(): string {
    return this.base64Data;
  }

  // setters
  setProNumber(aProNumber: string): void {
    this.proNumber = aProNumber;
  }

  setTimestamp(aTimestamp: string): void {
    this.timestamp = aTimestamp;
  }

  setCorpCode(aCropCode: string): void {
    this.corpCode = aCropCode;
  }

  setDocClass(aDocClass: string): void {
    this.docClass = aDocClass;
  }

  setDecodedData(aDecodedData: Uint8Array): void {
    this.decodedData = aDecodedData;
  }

  setFileName(aProNumber: string): void {
    this.fileName = aProNumber;
  }

  setMimeType(aMimeType: string): void {
    this.mimeType = aMimeType;
  }

  setBase64Data(aBase64Data: string): void {
    this.base64Data = aBase64Data;
  }
}
