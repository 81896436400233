import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { YesNoOptionPipe } from '@shared/pipes';
import { PortlandDateOrNa } from '@shared/pipes/portland-date-or-na.pipe';
import { YesNoOptionPipeOrNa } from '@shared/pipes/yes-no-option/yes-no-option-or-na.pipe';
import { SharedModule } from '@shared/shared.module';
import { XpoLtlPipeModule } from '@xpo-ltl/ngx-ltl';
import { ReweighLogCommonModule } from 'app/reweigh-log-headers/reweigh-log-common/reweigh-log-common.module';
import { ReweighLogSummary2Component } from './reweigh-log-summary2.component';

@NgModule({
  declarations: [ReweighLogSummary2Component],
  imports: [CommonModule, SharedModule, ReweighLogCommonModule, XpoLtlPipeModule],
  providers: [YesNoOptionPipe, PortlandDateOrNa, YesNoOptionPipeOrNa],
  exports: [ReweighLogSummary2Component],
})
export class ReweighLogSummary2PageModule {}
