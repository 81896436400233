import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { LogHeader } from '@xpo-ltl/sdk-reweigh';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { InfoDialogComponent } from '@dialogs/info-dialog/info-dialog.component';

const MAX_LENGTH = 90;

@Component({
  selector: 'exception-description-cell-renderer',
  templateUrl: './exception-description-cell-renderer.component.html',
  styleUrls: ['./exception-description-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    clasS: 'exception-description-cell-renderer',
  },
})
export class ExceptionDescriptionRendererComponent implements ICellRendererAngularComp {
  value: string;
  logHeader: LogHeader;
  displayText: string;
  showLoadMoreLink: boolean;

  constructor(private dialog: MatDialog) {}

  agInit(params: ICellRendererParams): void {
    this.value = params.value || '';
    this.logHeader = params.data;
    this.shortenPropertiesIfNeeded();
  }

  refresh(): boolean {
    return false;
  }

  showMoreDialog(): void {
    this.dialog.open(InfoDialogComponent, {
      maxWidth: '800px',
      data: {
        title: `Exception Description for Log Header ID ${this.logHeader.logHeaderId}`,
        message: this.value,
      },
    });
  }

  private shortenPropertiesIfNeeded(): void {
    if (this.value.length > MAX_LENGTH) {
      this.showLoadMoreLink = true;
      this.displayText = `${this.value.substr(0, MAX_LENGTH)}... `;
    } else {
      this.showLoadMoreLink = false;
      this.displayText = this.value;
    }
  }
}
