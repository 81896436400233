import {Injectable} from '@angular/core';
import {ExpandableCollapsableComponent} from '@shared/enums/expandable-collapsable-component';
import {CollapsableExpandable} from '@shared/interfaces/collapsable-expandable';
import {
  ReweighLogHeaderHistComponent
} from 'app/reweigh-log-headers/reweigh-log-common/reweigh-log-header-hist/reweigh-log-header-hist.component';
import {
  ReweighLogDetailHistComponent
} from '../../reweigh-log-headers/reweigh-log-common/reweigh-log-detail-hist/reweigh-log-detail-hist.component';
import {
  ReweighLogDetailsComponent
} from '../../reweigh-log-headers/reweigh-log-common/reweigh-log-details/reweigh-log-details.component';
import {
  ReweighLogScaleTestsComponent
} from '../../reweigh-log-headers/reweigh-log-common/reweigh-log-scale-tests/reweigh-log-scale-tests.component';
import {
  ShipmentDimensionsComponent
} from '../../reweigh-log-headers/reweigh-log-common/shipment-dimensions/shipment-dimensions.component';

@Injectable({
  providedIn: 'root'
})
export class ReweighLogSummaryService {

  protected reweighLogDetailsComponent: ReweighLogDetailsComponent;
  protected shipmentDimensionsComponent: ShipmentDimensionsComponent;
  protected reweighLogHeaderHistComponent: ReweighLogHeaderHistComponent;
  protected reweighLogDetailHistComponent: ReweighLogDetailHistComponent;
  protected reweighLogScaleTestsComponent: ReweighLogScaleTestsComponent;

  constructor() {
  }

  onCollapseExpandClicked(aComponent: ExpandableCollapsableComponent) {
    let lExpandableComponent: CollapsableExpandable;
    switch (aComponent) {
      case ExpandableCollapsableComponent.ReweighLogDetailsComponent: {
        lExpandableComponent = this.reweighLogDetailsComponent;
        break;
      }
      case ExpandableCollapsableComponent.ShipmentDimensions: {
        lExpandableComponent = this.shipmentDimensionsComponent;
        break;
      }
      case ExpandableCollapsableComponent.HeaderHistory: {
        lExpandableComponent = this.reweighLogHeaderHistComponent;
        break;
      }
      case ExpandableCollapsableComponent.DetailsHist: {
        lExpandableComponent = this.reweighLogDetailHistComponent;
        break;
      }
      case ExpandableCollapsableComponent.ScaleTests: {
        lExpandableComponent = this.reweighLogScaleTestsComponent;
        break;
      }
      default: {
        throw Error('Untreated case here');
      }
    }
    lExpandableComponent.toggleCollapseExpand();
  }

  setReweighLogDetailsComponent(src: ReweighLogDetailsComponent) {
    this.reweighLogDetailsComponent = src;
  }

  setShipmentDimensionsComponent(src: ShipmentDimensionsComponent) {
    this.shipmentDimensionsComponent = src;
  }

  setReweighLogHeaderHistComponent(src: ReweighLogHeaderHistComponent) {
    this.reweighLogHeaderHistComponent = src;
  }

  setReweighLogDetailHistComponent(src: ReweighLogDetailHistComponent) {
    this.reweighLogDetailHistComponent = src;

  }

  setReweighLogScaleTestsComponent(src: ReweighLogScaleTestsComponent) {
    this.reweighLogScaleTestsComponent = src;
  }
}
