import { ReweighApplicationUser } from '@shared/classes/access/reweigh-application-user';
import { XpoLtlLoggedInUser } from '@shared/classes/access/xpo-ltl-logged-in-user';
import { AccessRole } from '@shared/enums';
import { LocalStorageKey } from '@shared/enums/LocalStorageKey';
import { ReweighApplicationRole } from '@shared/services/user-role/reweigh-application-role';

export class OverrideableReweighApplicationUser extends ReweighApplicationUser {
  constructor(aXpoLtlLoggedInUser: XpoLtlLoggedInUser) {
    super(aXpoLtlLoggedInUser);
    this.setOverriddenRoles();
  }

  static hasLocalStorageValue(): boolean {
    return localStorage.getItem(LocalStorageKey.OVERRIDE_CURRENT_APP_ROLE)?.length > 0;
  }
  protected setOverriddenRoles() {
    const localStorageRoleValue: string = this.getUserRoleLocalStorageValue();
    if (localStorageRoleValue?.length > 0) {
      const overriddenRolesTmp: Set<ReweighApplicationRole> = new Set<ReweighApplicationRole>();
      try {
        const accessRoleFromLocalStorage: AccessRole = XpoLtlLoggedInUser.getApplicationRoleFromValue(
          localStorageRoleValue
        );
        let localStorageRoleFoundInAvailableRoles: boolean = false;
        for (const availableRole of this.availableRoles) {
          if (!localStorageRoleFoundInAvailableRoles) {
            localStorageRoleFoundInAvailableRoles = availableRole.accessRole === accessRoleFromLocalStorage;
          }
          if (localStorageRoleFoundInAvailableRoles) {
            overriddenRolesTmp.add(availableRole);
          }
        }
        this.availableRoles = overriddenRolesTmp;
        // console.debug('user set and roles from local storage:'+localStorageRoleValue, this)
      } catch (e) {
        console.error('Error loading user role from local storage', e);
      }
    }
  }

  getUserRoleLocalStorageValue(): string {
    return localStorage.getItem(LocalStorageKey.OVERRIDE_CURRENT_APP_ROLE);
  }

  setOverridenRoleToLocalStorage(accessRole: AccessRole) {
    const val: string = accessRole.valueOf();
    const key: string = LocalStorageKey.OVERRIDE_CURRENT_APP_ROLE;
    // console.debug('setOverridenRoleToLocalStorage:', key, val)

    localStorage.setItem(key, val);
  }

  resetLocalStorage() {
    localStorage.removeItem(LocalStorageKey.OVERRIDE_CURRENT_APP_ROLE);
  }
}
