import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ExtendedLiftScaleCalibrationHeader } from '@shared/classes/entities/extended-lift-scale-calibration-header';
import { ExtendedLogHeader } from '@shared/classes/entities/extended-log-header';
import { LogHeaderSummaryGroups } from '@shared/classes/log-header-summary-group/log-header-summary-group';
import { Reweigh } from '@shared/classes/reweigh';
import {ReweighLogHeadersFilterFormNames} from '@shared/enums';
import { ReweighLogDetailsViewType } from '@shared/enums/reweigh-log-detail-view-type';
import { LogHeaderSummaryTitles } from '@shared/enums/reweigh-log/reweigh-log-header-titles';
import { ReweighAppNotificationService } from '@shared/services/reweigh-app-notification.service';
import { ReweighLogSummaryService } from '@shared/services/reweigh-log-summary.service';
import { ReweighService } from '@shared/services/reweigh-service/reweigh.service';
import { ShipmentDetailsService } from '@shared/services/shipment-details/shipment-details.service';
import {UserRoleService} from '@shared/services/user-role';
import { LogHeaderShipment } from '@xpo-ltl/sdk-reweigh';
import { Subscription } from 'rxjs';
import {ReweighLogSummaryBase} from '../reweigh-log-common/lib/reweigh-log-summary-base';
import { DmsApiWrapper } from '../reweigh-log-summary/services/dms-api-wrapper.service';

@Component({
  selector: 'app-reweigh-log-summary2',
  templateUrl: './reweigh-log-summary2.component.html',
  styleUrls: ['../reweigh-log-summary/reweigh-log-summary.component.scss', './reweigh-log-summary2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'reweigh-log-summary-page',
  },
})
export class ReweighLogSummary2Component extends ReweighLogSummaryBase {

  constructor(
    protected dmsApiWrapper: DmsApiWrapper,
    protected shipmentDetailsService: ShipmentDetailsService,
    protected router: Router,
    protected reweighAppNotificationService: ReweighAppNotificationService,
    protected dialog: MatDialog,
    protected reweighService: ReweighService,
    protected userRoleService: UserRoleService,
    protected reweighLogSummaryService: ReweighLogSummaryService,
  ) {
    super(dmsApiWrapper, shipmentDetailsService,
      router, reweighAppNotificationService, dialog, reweighService, userRoleService, reweighLogSummaryService);
  }


  protected readonly ReweighLogHeadersFilterFormNames = ReweighLogHeadersFilterFormNames;


}
