import { Component } from '@angular/core';

@Component({
  selector: 'reweigh-log-details-cell-renderer',
  templateUrl: './reweigh-log-details-cell-renderer.component.html',
  styleUrls: ['./reweigh-log-details-cell-renderer.component.scss'],
})
export class ReweighLogDetailsCellRendererComponent implements ICellRendererAngularComp {
  logDetail: OperatorLogDetail[];
  logHeaderId: number;
  proNumberTxt: string;

  agInit(params: ICellRendererParams): void {
    this.logDetail = params.data.logDetail;
    if (params.data?.logHeaderId && !isNaN(+params.data?.logHeaderId)) {
      this.logHeaderId = params.data.logHeaderId;
    }
    if (params.data?.proNumberTxt) {
      this.proNumberTxt = params.data.proNumberTxt;
    }
  }

  refresh(): boolean {
    return false;
  }
}
import { LogDetail } from '@xpo-ltl/sdk-reweigh';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { OperatorLogDetail } from '@shared/classes/entities/operator-log-detail';
import { ICellRendererParams } from 'ag-grid-community';
