import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Reweigh } from '@shared/classes/reweigh';
import { ReweighLogHeaderPathParams } from '@shared/enums';
import {ReweighLogHeaderViewsService} from '@shared/services/reweigh-log-header-views.service';
import { ReweighService } from '@shared/services/reweigh-service/reweigh.service';
import { UserRoleService } from '@shared/services/user-role';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-reweigh-log-header-views',
  templateUrl: './reweigh-log-header-views.component.html',
  styleUrls: ['./reweigh-log-header-views.component.scss'],
})
export class ReweighLogHeaderViewsComponent implements OnInit, OnDestroy {
  protected logHeaderId: number;
  proNbrTxt: string;

  isDialog: boolean;

  protected reweighServiceSubscription: Subscription;

  activeTabIndex: number = 0;
  hasCurrentUserWriteAccess: boolean = false;

  constructor(
    protected reweighServiceService: ReweighService,
    protected userRoleService: UserRoleService,
    private activatedRoute: ActivatedRoute,
    @Optional() public dialogRef: MatDialogRef<ReweighLogHeaderViewsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    protected reweighLogHeaderViewsService: ReweighLogHeaderViewsService
  ) {
    this.hasCurrentUserWriteAccess = this.userRoleService.currentUserHasWriteAccess();
    this.isDialog = !isNaN(data?.logHeaderId);
    if (this.isDialog) {
      this.logHeaderId = data?.logHeaderId;
    }
    reweighLogHeaderViewsService.reweighLogHeaderViewsComponent = this;
    // if is not a dialog, handled by ngOnInit
  }

  ngOnInit(): void {
    this.reweighServiceSubscription = this.reweighServiceService.subject.subscribe((aReweigh: Reweigh) => {
      this.proNbrTxt = aReweigh?.getLogHeader()?.proNbr;
    });

    if (!this.isDialog) {
      this.handleParams();
    } else {
      this.reweighServiceService.loadByLogHeaderIdIfNotLoaded(this.logHeaderId);
    }
  }

  handleParams(): void {
    this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
      this.logHeaderId = +params[ReweighLogHeaderPathParams.LOG_HEADER_ID];
      this.reweighServiceService.loadByLogHeaderIdIfNotLoaded(this.logHeaderId);
    });
  }

  onMatTabChanged($event: number) {}

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.reweighServiceSubscription.unsubscribe();
    this.reweighLogHeaderViewsService.reweighLogHeaderViewsComponent = undefined;
  }
}
