import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {RwExceptionCdDialogComponent} from '@dialogs/rw-exception-cd-dialog/rw-exception-cd-dialog.component';
import {UserRoleService} from '@shared/services/user-role';
import {LogHeader} from '@xpo-ltl/sdk-reweigh';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'app-rw-exception-cd-renderer',
  templateUrl: './rw-exception-cd-renderer.component.html',
  styleUrls: ['./rw-exception-cd-renderer.component.scss'],
})
export class RwExceptionCdRendererComponent implements ICellRendererAngularComp {
  value: string;
  logHeader: LogHeader;
  hasCurrentUserWriteAccess: boolean = false;

  constructor(private dialog: MatDialog, protected userRoleService: UserRoleService) {
    this.hasCurrentUserWriteAccess = this.userRoleService.currentUserHasWriteAccess();
  }

  agInit(params: ICellRendererParams): void {
    this.value = params.value || '';
    this.logHeader = params.data;
  }

  refresh(): boolean {
    return false;
  }

  onExceptionCdClicked() {
    this.dialog.open(RwExceptionCdDialogComponent, RwExceptionCdDialogComponent.getDialogConfig(this.logHeader, null));
  }
}
