import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { from, Observable, Observer, of } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SequentialGuard implements CanActivate {
  constructor(private injector: Injector) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const guards = Object.assign([], route.data['sequentialGuards']);

    return new Observable<boolean>((observer) => {
      this.processGuards(route, state, guards, observer);
    });
  }

  private processGuards(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    guards: any[],
    observer: Observer<boolean>
  ) {
    if (!guards || guards.length === 0) {
      observer.next(true);
      observer.complete();
      return;
    }

    const guardClass = guards.shift();
    const guard: CanActivate = this.injector.get<CanActivate>(guardClass);
    const canActivateReturn = guard.canActivate(route, state);
    let canActivateObservable: Observable<boolean>;

    if (canActivateReturn.constructor.name === 'Boolean') {
      canActivateObservable = of(canActivateReturn as boolean);
    } else if (canActivateReturn.constructor.name === 'ZoneAwarePromise') {
      canActivateObservable = from(canActivateReturn as Promise<boolean>);
    } else {
      canActivateObservable = canActivateReturn as Observable<boolean>;
    }

    canActivateObservable.pipe(take(1)).subscribe(
      (response: boolean) => {
        if (response) {
          this.processGuards(route, state, guards, observer);
        } else {
          observer.next(false);
          observer.complete();
        }
      },
      () => {
        observer.next(false);
        observer.complete();
      }
    );
  }
}
