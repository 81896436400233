<a xpo-link matTooltipPosition="right" (click)="onOpenDialogClicked($event)" matTooltip="Dialog">
  {{ params.value }}
</a>

<button
  mat-icon-button
  matTooltip="Open Summary in new page"
  matTooltipPosition="above"
  (click)="openInNewPageClicked($event)"
  class="summary-button"
>
  <mat-icon>open_in_new</mat-icon>
</button>
