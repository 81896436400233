import { HierarchicalRoles } from '@shared/classes/access/hierarchical-roles';
import { XpoLtlLoggedInUser } from '@shared/classes/access/xpo-ltl-logged-in-user';
import { AccessRole } from '@shared/enums';
import { ReweighApplicationRole } from '@shared/services/user-role/reweigh-application-role';

export class ReweighApplicationUser extends HierarchicalRoles {
  protected xpoLtlLoggedInUser: XpoLtlLoggedInUser;

  constructor(aXpoLtlLoggedInUser: XpoLtlLoggedInUser) {
    super();
    if (!aXpoLtlLoggedInUser || !aXpoLtlLoggedInUser.isAuthorizedUser()) {
      // dont create a user with invalid raw user
      throw Error('given user is either undefined or unauthorized');
    }
    this.xpoLtlLoggedInUser = aXpoLtlLoggedInUser;
    this.setAvailableRoles();
  }

  canWrite(): boolean {
    return this.hasTesterUserRoleOrMore();
  }

  canAccessScales(): boolean {
    return this.hasTesterUserRoleOrMore();
  }

  canAccessSuperAdminFunctions(): boolean {
    return this.hasReweighReviewRoleOrMore();
  }

  canRead(): boolean {
    return this.hasReweighRoRoleOrMore();
  }

  canAccessSettings(): boolean {
    return this.hasReweighReviewRoleOrMore();
  }

  canChangeHisRole(): boolean {
    return this.hasReweighReviewRoleOrMore();
  }

  protected hasAvailableRole(aRawRole: AccessRole): boolean {
    if (this.availableRoles?.size <= 0) {
      throw Error('this.availableRoles is not set');
    }
    for (const reweighAppRole of this.availableRoles) {
      if (reweighAppRole.accessRole === aRawRole) {
        return true;
      }
    }
    return false;
  }

  protected setAvailableRoles() {
    this.availableRoles = new Set<ReweighApplicationRole>();
    if (this.xpoLtlLoggedInUser.hasStrictRole(AccessRole.LTL_REWEIGH_SUPER_USER)) {
      this.availableRoles.add(ReweighApplicationUser.REWEIGH_APP_ROLE_SU);
      this.availableRoles.add(ReweighApplicationUser.REWEIGH_APP_ROLE_LTL_REWEIGH_REVIEW);
      this.availableRoles.add(ReweighApplicationUser.REWEIGH_APP_ROLE_TST_LTL_REWEIGH_REVIEW);
      this.availableRoles.add(ReweighApplicationUser.REWEIGH_APP_ROLE_LTL_REWEIGH_READ_ONLY);
    }
    if (this.xpoLtlLoggedInUser.hasStrictRole(AccessRole.LTL_REWEIGH_REVIEW)) {
      this.availableRoles.add(ReweighApplicationUser.REWEIGH_APP_ROLE_SU);
      this.availableRoles.add(ReweighApplicationUser.REWEIGH_APP_ROLE_LTL_REWEIGH_REVIEW);
      this.availableRoles.add(ReweighApplicationUser.REWEIGH_APP_ROLE_TST_LTL_REWEIGH_REVIEW);
      this.availableRoles.add(ReweighApplicationUser.REWEIGH_APP_ROLE_LTL_REWEIGH_READ_ONLY);
    }
    if (this.xpoLtlLoggedInUser.hasStrictRole(AccessRole.TST_LTL_REWEIGH_REVIEW)) {
      this.availableRoles.add(ReweighApplicationUser.REWEIGH_APP_ROLE_TST_LTL_REWEIGH_REVIEW);
      this.availableRoles.add(ReweighApplicationUser.REWEIGH_APP_ROLE_LTL_REWEIGH_READ_ONLY);
    }
    if (this.xpoLtlLoggedInUser.hasStrictRole(AccessRole.LTL_REWEIGH_READ_ONLY)) {
      this.availableRoles.add(ReweighApplicationUser.REWEIGH_APP_ROLE_LTL_REWEIGH_READ_ONLY);
    }
    if (this.availableRoles?.size <= 0) {
      throw Error('Something is wrong, we should have at least one role');
    }
  }

  protected hasTesterUserRole(): boolean {
    return this.hasAvailableRole(AccessRole.TST_LTL_REWEIGH_REVIEW);
  }

  protected hasReweighReviewRoleOrMore(): boolean {
    return this.hasAvailableRole(AccessRole.LTL_REWEIGH_REVIEW);
  }

  protected hasReweighRoRoleOrMore(): boolean {
    return this.hasAvailableRole(AccessRole.LTL_REWEIGH_READ_ONLY);
  }

  getAvailableRoles(): Set<ReweighApplicationRole> {
    return this.availableRoles;
  }

  getHighestRole(): ReweighApplicationRole {
    const lRoles: Set<ReweighApplicationRole> = this.getAvailableRoles();
    if (lRoles?.size <= 0) {
      throw new Error('roles are empty');
    }
    // first element of a set, ugly... :(
    return lRoles.values().next().value;
  }

  getXpoLtlLoggedInUser(): XpoLtlLoggedInUser {
    return this.xpoLtlLoggedInUser;
  }

  private hasTesterUserRoleOrMore() {
    return this.hasAvailableRole(AccessRole.TST_LTL_REWEIGH_REVIEW);
  }
}
