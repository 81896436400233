import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { MatDialog } from '@angular/material/dialog';
import { ReweighHeaderSummaryDialogComponent } from '@dialogs/reweigh-header-summary-dialog/reweigh-header-summary-dialog.component';
import { AppRoutes } from '@shared/enums';

@Component({
  selector: 'log-header-cell-renderer',
  templateUrl: './log-header-cell-renderer.component.html',
  styleUrls: ['./log-header-cell-renderer.component.scss'],
})
export class LogHeaderRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  url: string;
  logHeaderId: number;

  constructor(private dialog: MatDialog) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.logHeaderId = params?.value;
    this.url = `/${AppRoutes.REWEIGH_LOG_SUMMARY_PAGE}/${this.params.value}`;
  }

  refresh(): boolean {
    return false;
  }

  onOpenDialogClicked(aEvent: any) {
    if (this.logHeaderId) {
      const dialogRef = this.dialog.open(
        ReweighHeaderSummaryDialogComponent,
        ReweighHeaderSummaryDialogComponent.getDialogConfig(this.logHeaderId)
      );
    } else {
      throw Error('invalid this.logHeaderId');
    }
  }

  openInNewPageClicked(event) {
    window.open(this.url, '_blank');
  }
}
