import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReweighHeaderErrorDetailsDialogComponent } from '@dialogs/reweigh-header-details-dialog/reweigh-header-error-details-dialog.component';
import { RwExceptionCdDialogComponent } from '@dialogs/rw-exception-cd-dialog/rw-exception-cd-dialog.component';
import { OperatorLogHeaderHist } from '@shared/classes/entities/operator-log-header-hist';
import { LogHeaderHist } from '@xpo-ltl/sdk-reweigh';
import { UiEntityTableCellComponentBase } from '../../../../directives/ui-entity-table-cell-component-base';

@Component({
  selector: 'app-reweigh-log-hist-table-custom',
  templateUrl: './reweigh-log-hist-table-custom.component.html',
  styleUrls: ['./reweigh-log-hist-table-custom.component.scss'],
})
export class ReweighLogHistTableCustomComponent extends UiEntityTableCellComponentBase<OperatorLogHeaderHist>
  implements OnInit {
  constructor(protected dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {}

  onErrorDetailsClicked(rowData: OperatorLogHeaderHist) {
    const dialogRef = this.dialog.open(ReweighHeaderErrorDetailsDialogComponent, {
      maxWidth: '80vw',
      maxHeight: '80vh',
      data: {
        title: `Exception details for Log Header ID ${rowData.logHeaderId}`,
        logHeaderHist: rowData,
      },
    });
  }

  onExceptionCdClicked(logHeaderHist: LogHeaderHist) {
    this.dialog.open(RwExceptionCdDialogComponent, RwExceptionCdDialogComponent.getDialogConfig(null, logHeaderHist));
  }
}
