import { Directive, Input, ViewContainerRef } from '@angular/core';
import { UiEntityProperty } from '@shared/classes/entities/ui-entity-property';

@Directive({ selector: '[uiEntityTableCell]' })
export class UiEntityTableCellDirective<T> {
  @Input() rowData: T;
  @Input() uiEntityProperty: UiEntityProperty;

  constructor(public viewContainerRef: ViewContainerRef) {}
}
