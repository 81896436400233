import { Provider } from '@angular/core';
import { Router } from '@angular/router';

import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { XpoAuthenticationService, XpoAuthEventHandler } from '@xpo/ngx-auth';
import { UserManager } from 'oidc-client';

import { XpoAuthenticationServiceAlwaysLoggedIn } from './xpo-authentication.service';
import { XpoLtlAuthenticationServiceNoLogout } from './xpo-ltl-authentication.service';
import { XpoLtlLoggedInUserServiceAlwaysLoggedIn } from './xpo-ltl-logged-in-user.service';

export class CypressUtilities {
  static getProviders(): Provider[] {
    const providers = [];

    if (!!window['Cypress']) {
      /**
       * If we are running in a Cypress window, then always assume the user is Logged In, since Cypress tests
       * will set the correct tokens in the sessionStorage. This should still be secure since we still need
       * a valid token to make API calls.  All this will do is prevent the authentication system from trying
       * to automatically log in or fetch one. If we have a bad token, then the API calls will fail.
       */
      providers.push({
        provide: XpoAuthenticationService,
        useClass: XpoAuthenticationServiceAlwaysLoggedIn,
        deps: [UserManager, Router, XpoAuthEventHandler],
      });
      providers.push({ provide: XpoLtlAuthenticationService, useClass: XpoLtlAuthenticationServiceNoLogout });
      providers.push({ provide: XpoLtlLoggedInUserService, useClass: XpoLtlLoggedInUserServiceAlwaysLoggedIn });
    }

    return providers;
  }
}
