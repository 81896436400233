export enum ReweighLogHeadersFilterFormNames {
  SIC_CODE = 'sicCodes',
  DAYS_COUNT = 'daysCount',
  FROM_DATE = 'fromDate',
  TO_DATE = 'toDate',
  STATUS_CDS = 'statusCds',
  DISPLAY_IND = 'displayInd',
  PRO_NBR = 'proNbr',
  RE_SUBMITTABLE_IND = 'reSubmittableInd',
  CERTIFICATE_TO_BE_CREATED_IND = 'certificateReadyToBeCreatedInd',
  WEIGHT = 'weight',
  FINAL_DELIVERED_IND = 'finalDeliveredInd',
  LOGHEADER_ID = 'logHeaderId',
  MORE_INFO_CD_IND = 'moreInformationCds',
}
