import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RoleCheckGuard } from '@shared/guards/role-check/role-check.guard';
import { SequentialGuard } from '@shared/guards/sequential/sequiential.guard';
import { XpoLtlEnvironmentAndRoleGuard } from '@xpo-ltl/ngx-ltl';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { ReweighLogHeaderViewsComponent } from '../reweigh-log-header-views/reweigh-log-header-views.component';

const xpoAuthenticationGuard = !!window['Cypress'] ? [] : [XpoAuthenticationGuard];

const routerDefinitions: Routes = [
  {
    path: 'reweigh-log-summary/:logHeaderId',
    component: ReweighLogHeaderViewsComponent,
    canActivate: [SequentialGuard, XpoLtlEnvironmentAndRoleGuard],
    data: {
      sequentialGuards: [...xpoAuthenticationGuard, RoleCheckGuard],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routerDefinitions)],
})
export class ReweighLogSummaryRoutingModule {}
