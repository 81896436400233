import { Component, Inject, OnInit } from '@angular/core';

import { XPO_SNACKBAR_DETAILED_MESSAGE_DATA } from '@xpo-ltl/ngx-ltl-core';

@Component({
  selector: 'app-snackbar-detail',
  templateUrl: './snackbar-detail.component.html',
  styleUrls: ['./snackbar-detail.component.scss'],
})
export class SnackbarDetailComponent implements OnInit {
  constructor(@Inject(XPO_SNACKBAR_DETAILED_MESSAGE_DATA) public items: string[]) {}

  ngOnInit(): void {}
}
