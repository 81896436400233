export class UiEntityPropertyOptions {
  label?: string;
  columnTooltip?: string = null;
  matTableColumnWidth?: number;
  isVisibleColumn?: boolean = true;
  isVisibleInCollapsed?: boolean = false;
  isVisibleInExpanded?: boolean = true;

  // 1000 so it goes at the end if not defined
  expandedColumnIndex?: number = 1000;
  collapsedColumnIndex?: number = 1000;
  isNumberNotFormatted?: boolean = false;
  formattingFunction?: Function;

  static setDefaults(uiEntityPropertyOptions: UiEntityPropertyOptions) {
    if (uiEntityPropertyOptions.isVisibleColumn === undefined) {
      uiEntityPropertyOptions.isVisibleColumn = true;
    }
    if (uiEntityPropertyOptions.isVisibleInExpanded === undefined) {
      uiEntityPropertyOptions.isVisibleInExpanded = true;
    }
    if (uiEntityPropertyOptions.isVisibleInCollapsed === undefined) {
      uiEntityPropertyOptions.isVisibleInCollapsed = false;
    }
    if (uiEntityPropertyOptions.expandedColumnIndex === undefined) {
      uiEntityPropertyOptions.expandedColumnIndex = 0;
    }
    if (uiEntityPropertyOptions.collapsedColumnIndex === undefined) {
      uiEntityPropertyOptions.collapsedColumnIndex = 0;
    }
  }
}
