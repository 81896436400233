import {animate, state, style, transition, trigger} from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {MatSort, SortDirection} from '@angular/material/sort';
import {OperatorLogDetail} from '@shared/classes/entities/operator-log-detail';
import {UiEntities} from '@shared/classes/entities/ui-entities';
import {UiEntity} from '@shared/classes/entities/ui-entity';
import {Reweigh} from '@shared/classes/reweigh';
import {
  UiEntityExpandableMatTableComponent
} from '@shared/components/ui-entity-expandable-mat-table/ui-entity-expandable-mat-table.component';
import {ReweighLogDetailsViewType} from '@shared/enums/reweigh-log-detail-view-type';
import {ExpandableCollapsableComponent} from '@shared/enums/expandable-collapsable-component';
import {ConstantsService} from '@shared/services/constants';
import {ReweighLogSummaryService} from '@shared/services/reweigh-log-summary.service';
import {ReweighService} from '@shared/services/reweigh-service/reweigh.service';
import {ReweighApiServiceWrapper} from '@shared/services/sdk/reweigh-api-service-wrapper.service';
import {UserRoleService} from '@shared/services/user-role';
import {Subscription} from 'rxjs';
import {UiEntityTableCellDirective} from '../../../directives/ ui-entity-table-cell-directive';
import {
  ReweighLogDetailTableCustomComponent
} from '../mat-table-cell-projections/reweigh-log-detail-table-custom/reweigh-log-detail-table-custom.component';

@Component({
  selector: 'reweigh-log-details',
  templateUrl:
    '../../../shared/components/ui-entity-expandable-mat-table/ui-entity-expandable-mat-table.component.html',
  styleUrls: [
    '../lib/css/reweigh-log-table-section.scss',
    '../../../shared/components/ui-entity-expandable-mat-table/ui-entity-expandable-mat-table.component.scss',
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('425ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
  encapsulation: ViewEncapsulation.None,
  // for @viewChild and inputs to work with inheritence
  providers: [{provide: ReweighLogDetailsComponent, useExisting: UiEntityExpandableMatTableComponent}],
})
export class ReweighLogDetailsComponent extends UiEntityExpandableMatTableComponent<
  OperatorLogDetail,
  ReweighLogDetailTableCustomComponent
> {
  /**
   * Cant be pushed on the parent
   * @param content
   */
  @ViewChild(MatSort, {static: false}) set content(content: ElementRef) {
    this.tableSort = content;
    if (this.tableSort) {
      this.dataSource.sort = this.tableSort;
    }
  }

  @ViewChildren(UiEntityTableCellDirective) uiEntityTableCellDirectiveViewChildren: QueryList<
    UiEntityTableCellDirective<OperatorLogDetail>
  >;

  @Input()
  tableData: OperatorLogDetail[];

  @Input() viewType: ReweighLogDetailsViewType = ReweighLogDetailsViewType.DEFAULT;

  @Input() proNbrTxtInput: string;

  @Input() isHeaderTitleDisplayedInput: boolean = true;

  protected reweighServiceSubscription: Subscription;

  constructor(
    protected constantsService: ConstantsService,
    protected reweighApiServiceWrapper: ReweighApiServiceWrapper,
    protected changeDetectorRefs: ChangeDetectorRef,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected userRoleService: UserRoleService,
    protected reweighService: ReweighService,
    protected reweighLogSummaryService: ReweighLogSummaryService,
  ) {
    super(reweighLogSummaryService);
    this.reweighServiceSubscription = this.reweighService.subject.subscribe((reweigh: Reweigh) => {
      for (let i = 0; i < this.tableData.length; i++) {
        const operatorLogDetail: OperatorLogDetail = this.tableData[i];
        operatorLogDetail.setFromLiftScaleCalibrationHeaders(reweigh.liftScaleCalibrationHeaders);
      }
      // to be executed after component loading
    });
    reweighLogSummaryService.setReweighLogDetailsComponent(this);
  }

  getComponentFactoryToProject(): ComponentFactory<ReweighLogDetailTableCustomComponent> {
    // tslint:disable-next-line:max-line-length
    const lComponentFactoryToProject: ComponentFactory<ReweighLogDetailTableCustomComponent> = this.componentFactoryResolver.resolveComponentFactory<
      ReweighLogDetailTableCustomComponent
    >(ReweighLogDetailTableCustomComponent);
    return lComponentFactoryToProject;
  }

  /* tslint:disable use-lifecycle-interface */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.dataSource.data = this.tableData;
    this.dataIsLoaded = true;
    this.changeDetectorRefs.detectChanges();
    setTimeout(() => {
      this.isTitleSectionDisplayed = this.isHeaderTitleDisplayedInput;
      this.loadRefs(this.uiEntityTableCellDirectiveViewChildren, this.getComponentFactoryToProject());
    });
    // each time one element with that directive has been inserted into the DOM, then do...
    this.uiEntityTableCellDirectiveViewChildren.changes.subscribe((aUiEntityTableCellDirectiveQryList: any) => {
      // note that we receive aUiEntityTableCellDirectiveQryList as argument,
      // but its the same as this.uiEntityTableCellDirectiveViewChildren so we can use that one as well
      setTimeout(() => {
        this.loadRefs(this.uiEntityTableCellDirectiveViewChildren, this.getComponentFactoryToProject());
      });
    });
  }

  getUiEntity(): UiEntity {
    let result: UiEntity;
    if (this.viewType === ReweighLogDetailsViewType.DISPUTES) {
      result = UiEntities.LOG_DETAIL_FOR_DISPUTES_SUMMARY;
    } else {
      result = UiEntities.LOG_DETAIL;
    }
    return result;
  }

  getMainTitle(): string {
    return 'Log Details';
  }

  isLoadDataButtonDisplayed(): boolean {
    return false;
  }

  // override
  getSortDefaultColumnName(): string {
    return 'reweighDateTimeLcl';
  }

  // override
  getSortDefaultDirection(): SortDirection {
    return this.SORT_DIRECTION_ASC;
  }

  /**
   * Override
   */
  getVisibleColumns(): string[] {
    const result: string[] = super.getVisibleColumns();
    if (!this.userRoleService.currentUserHasWriteAccess()) {
      const lRemovedFields: string[] = [
        'operatorId',
        'operatorFullName',
        'auditInfo.createdById',
        'auditInfo.createByPgmId',
        'auditInfo.updateById',
        'auditInfo.updateByPgmId',
        'correlationId',
      ];
      for (let i = 0; i < result.length; i++) {
        const colName: string = result[i];
        if (lRemovedFields.includes(colName)) {
          result.splice(i, 1);
          i--;
        }
      }
    }
    return result;
  }

  getExpandedUiEntity(): UiEntity {
    return UiEntities.LIFT_SCALE_CALIBRATION_HEADER_FOR_LOG_DETAILS;
  }

  isExpandDetailRowsButtonVisible(): boolean {
    return !this.isHeaderTitleDisplayedInput;
  }

  getComponentForColumnsExpandCollapse(): ExpandableCollapsableComponent {
    return ExpandableCollapsableComponent.ReweighLogDetailsComponent;
  }

}
