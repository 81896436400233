import {IOperatorFirstnamLastname} from '@shared/classes/entities/operator-firstname-lastname';
import {UiEntityTableEntity} from '@shared/classes/entities/ui-entity-table-entity';
import {LiftScaleCalibrationHeader} from '@xpo-ltl/sdk-reweigh';
import {ScaleForkliftReport} from '../../../reweigh-calibration-headers/lib/scale-forklift-report';

export class ExtendedLiftScaleCalibrationHeader extends LiftScaleCalibrationHeader
  implements IOperatorFirstnamLastname, UiEntityTableEntity {
  operatorFullName: string;
  equipmentFullId: string;
  firstColumnId: string = '1';

  static toExtendedLiftScaleCalibrationHeader(src: LiftScaleCalibrationHeader): ExtendedLiftScaleCalibrationHeader {
    let result: ExtendedLiftScaleCalibrationHeader = null;
    if (src) {
      result = new ExtendedLiftScaleCalibrationHeader();
      Object.assign(result, src); // Object.assign(target, source)
      result.setOperatorFullName();
      result.setFullEquipmentId();
    }
    return result;
  }

  setOperatorFullName() {
    if (this.operatorFirstName) {
      this.operatorFullName = this.operatorFirstName;
    }
    if (this.operatorLastName) {
      if (this.operatorFullName?.length > 0) {
        this.operatorFullName += ' ' + this.operatorLastName;
      }
    }
  }

  protected setFullEquipmentId() {
    this.equipmentFullId = ScaleForkliftReport.getEquipmentFullId(this.equipmentIdPrefix, this.equipmentIdSuffixNbr);
  }
}
