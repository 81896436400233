import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { XpoLtlLoggedInUser } from '@shared/classes/access/xpo-ltl-logged-in-user';
import { AccessRole } from '@shared/enums';
import { KeyValueLabel } from '@shared/interfaces';
import { ReweighAppNotificationService } from '@shared/services/reweigh-app-notification.service';
import { UserRoleService } from '@shared/services/user-role';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-app-settings-dialog-component',
  templateUrl: './app-settings-dialog.component.html',
  styleUrls: ['./app-settings-dialog.component.scss'],
})
export class AppSettingsDialogComponent implements OnInit, OnDestroy {
  applicationRoles: KeyValueLabel[];

  applicationRoleFormControl: FormControl = new FormControl();

  currentUserHighestRoleString: string;
  private userRoleServiceCurrentUserSetSubscription: Subscription;

  constructor(
    protected reweighAppNotificationService: ReweighAppNotificationService,
    public dialogRef: MatDialogRef<AppSettingsDialogComponent>,
    protected userRoleService: UserRoleService
  ) {
    this.userRoleServiceCurrentUserSetSubscription = this.userRoleService.currentUserSetSubject.subscribe(() => {
      this.setSelectRoleValue();
    });
  }

  static getDialogConfig(): any {
    const HW: string = '80vw';
    const result: any = {
      maxWidth: HW,
      maxHeight: HW,
      data: {
        title: `Reweigh Application Settings`,
      },
    };
    return result;
  }

  ngOnInit(): void {
    if (!this.userRoleService.currentUser) {
      this.reweighAppNotificationService.error('Current user not yet loaded');
    } else {
      this.setApplicationRolesValues();
    }
  }

  close() {
    this.dialogRef.close();
  }

  onApplicationRoleSelectChanged($event: MatSelectChange) {
    const lAccessRole: AccessRole = XpoLtlLoggedInUser.getApplicationRoleFromValue($event.value);
    this.userRoleService.setApplicationRoleTo(lAccessRole);
    const lAccessRoleLabel: string = XpoLtlLoggedInUser.getAccessRoleLabel(lAccessRole);
    // console.debug("onApplicationRoleSelectChanged, role:", $event, lAccessRole);
    this.reweighAppNotificationService.info(
      'You\'ve changed your role to \'' + lAccessRoleLabel + '\', please reload the page to see the changes',
      10_000
    );
  }

  onResetSettingsClicked($event: MouseEvent) {
    this.userRoleService.resetSettings();
    this.reweighAppNotificationService.info('Settings cleared, please reload the page to see the changes');
  }

  protected setApplicationRolesValues() {
    this.applicationRoles = this.userRoleService.getApplicationRolesAsKeyValueLabels();
    this.currentUserHighestRoleString = this.userRoleService.currentUser.getHighestRole().getLabel();
    this.setSelectRoleValue();
  }

  protected setSelectRoleValue() {
    let lRoleValue: string = this.userRoleService.currentUser.getHighestRole().getValue();
    if (this.userRoleService.hasOverrideableUser()) {
      lRoleValue = this.userRoleService.overrideableUser.getHighestRole().getValue();
    }
    // console.debug('settings->value loaded:', lRoleValue)
    this.applicationRoleFormControl.setValue(lRoleValue);
  }

  ngOnDestroy(): void {
    this.userRoleServiceCurrentUserSetSubscription?.unsubscribe();
  }
}
