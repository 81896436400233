<div mat-dialog-title class="dialog-title">
  <h1 mat-dialog-title *ngIf="logHeader">Exception details for Log Header ID #{{ logHeader.logHeaderId }}</h1>
  <h1 mat-dialog-title *ngIf="logHeaderHist">
    Exception details for Log Header History ID #{{ logHeaderHist.logHeaderId }} Timestamp:
    {{ logHeaderHist.histCreatedDateTime | portlandDate }}
  </h1>
  <div class="close">
    <button mat-icon-button (click)="close()" matTooltip="Click to close or Escape or Click outside the dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<pre *ngIf="logHeader">{{ logHeader.errorDetail | json }}</pre>
<pre *ngIf="logHeaderHist">{{ logHeaderHist.errorDetail | json }}</pre>
