import {Injectable} from '@angular/core';

import {YesNoOptionPipe} from '@shared/pipes';
import {
  ReweighLogHeaderGridServiceColdefs
} from '@shared/services/reweigh-log-headers-grid/reweigh-log-header-grid-service-coldefs';
import {ReweighApiServiceWrapper} from '@shared/services/sdk/reweigh-api-service-wrapper.service';
import {UserRoleService} from '@shared/services/user-role';
import {XpoNumberFilterComponent, XpoStringFilterComponent} from '@xpo-ltl/ngx-ltl-board';
import {XpoAgGridBoardColumn, XpoAgGridBoardViewTemplate} from '@xpo-ltl/ngx-ltl-board-grid';
import {LogHeader} from '@xpo-ltl/sdk-reweigh';
import {GridOptions} from 'ag-grid-community';
import {
  CertificateCellRendererComponent
} from 'app/reweigh-log-headers/reweigh-log-headers-home/cell-renderers/certificate-cell-renderer/certificate-cell-renderer.component';
import {
  LogHeaderRendererComponent
} from 'app/reweigh-log-headers/reweigh-log-headers-home/cell-renderers/log-header-cell-renderer/log-header-cell-renderer.component';
import {
  ProNumberRendererComponent
} from 'app/reweigh-log-headers/reweigh-log-headers-home/cell-renderers/pro-number-cell-renderer/pro-number-cell-renderer.component';
import {
  ReweighLogDetailsCellRendererComponent
} from 'app/reweigh-log-headers/reweigh-log-headers-home/cell-renderers/reweigh-log-details/reweigh-log-details-cell-renderer.component';
import {
  RwYesNoRendererComponent
} from '../../../reweigh-log-headers/reweigh-log-headers-home/cell-renderers/rw-yes-no-renderer-component/rw-yes-no-renderer.component';
import {LogHeadersDataSourceService} from '../../../reweigh-log-headers/reweigh-log-headers-home/services';
import {Highlightable} from '../../classes/highlightable-interface';

@Injectable({
  providedIn: 'root',
})
export class ReweighLogHeadersGridService extends ReweighLogHeaderGridServiceColdefs {
  static readonly DETAIL_ROW_HEIGHT_FOR_MULTIPLE: number = 200;
  // todo pg: see if there is a way to change the height dynamically,
  // seems not https://www.ag-grid.com/javascript-data-grid/master-detail-height/
  static readonly DETAIL_ROW_HEIGHT_FOR_SINGLE: number = 800;

  constructor(
    private reweighLogHeadersService: ReweighApiServiceWrapper,
    private yesNoOptionPipe: YesNoOptionPipe,
    protected userRoleService: UserRoleService,
    private logHeadersDataSourceService: LogHeadersDataSourceService
  ) {
    super(userRoleService);
  }

  static readonly XPO_AG_GRID_BOARD_VIEW_TEMPLATE_ID: string = 'reweighLogHeaderBoardViewTemplateId';

  protected getColumnDefinitions(): XpoAgGridBoardColumn[] {
    // TODO: we are not showing secondary columns until we can hidden for the first time
    return ReweighLogHeaderGridServiceColdefs.getPrimaryColumns(this.userRoleService);
  }

  getGridOptions(aDetailGridOptionsRowHeight: number): GridOptions {
    const cellRendererComponents = {
      reweighLogDetailsCellRendererComponent: ReweighLogDetailsCellRendererComponent,
      logHeaderRendererComponent: LogHeaderRendererComponent,
      proNumberRendererComponent: ProNumberRendererComponent,
      certificateCellRendererComponent: CertificateCellRendererComponent,
      xpoNumberFilterComponent: XpoNumberFilterComponent,
      xpoStringFilterComponent: XpoStringFilterComponent,
      rwYesNoRendererComponent: RwYesNoRendererComponent,
    };

    return {
      frameworkComponents: cellRendererComponents,
      rowSelection: 'multiple',
      suppressAutoSize: true,
      suppressCsvExport: false,
      suppressExcelExport: true,
      masterDetail: true,
      headerHeight: 50, // if this value changes, change style on board too.
      // Selectable for copy-paste https://www.ag-grid.com/angular-data-grid/selection-overview/
      suppressCellSelection: false,
      suppressNoRowsOverlay: false,
      suppressRowClickSelection: false,
      enableCellTextSelection: true,
      ensureDomOrder: true,

      defaultColDef: {
        resizable: true,
        width: 120,
        sortable: true,
        filter: 'agSetColumnFilter',
        filterParams: {newRowsAction: 'keep'},
        menuTabs: ['filterMenuTab'],
        wrapText: true,
        autoHeight: true,
      },
      icons: {
        groupExpanded: `<mat-icon class="material-icons">remove</mat-icon>`,
        groupContracted: `<mat-icon class="material-icons">add</mat-icon>`,
        filter: `<mat-icon class="material-icons applied-filter">filter_alt</mat-icon>`,
      },
      keepDetailRowsCount: 1,
      detailRowAutoHeight: true,
      detailCellRendererParams: this.getLogDetailCellRendererParms(cellRendererComponents, aDetailGridOptionsRowHeight),
      rowClassRules: {
        highlightRow: (params) => {
          const logHeader: Highlightable = params.data;
          return logHeader.highlight;
        },
      },
    };
  }

  getViewTemplates(): XpoAgGridBoardViewTemplate[] {
    return [
      new XpoAgGridBoardViewTemplate({
        id: ReweighLogHeadersGridService.XPO_AG_GRID_BOARD_VIEW_TEMPLATE_ID,
        name: 'Reweigh Logs View Template',
        keyField: 'logHeaderId',
        allowAdditional: true,
        availableColumns: this.getColumnDefinitions(),
      }),
    ];
  }

  private getLogDetailCellRendererParms(cellRendererComponents: any, aDetailGridOptionsRowHeight: number): any {
    return {
      getDetailRowData: (params) => {
        params.successCallback([
          {logDetail: null, logHeaderId: params.data.logHeaderId, proNumberTxt: params.data.proNbr},
        ]);
        this.reweighLogHeadersService.getReweighLog(params.data.logHeaderId, false).subscribe(
          (getReweighLogResponseLogHeader: LogHeader) => {
            params.successCallback([
              {
                logDetail: getReweighLogResponseLogHeader.logDetail || [],
                logHeaderId: params.data.logHeaderId,
                proNumberTxt: params.data.proNbr,
              },
            ]);
          },
          () => {
            params.successCallback([
              {logDetail: [], logHeaderId: params.data.logHeaderId, proNumberTxt: params.data.proNbr},
            ]);
          }
        );
      },
      detailGridOptions: {
        suppressCsvExport: false,
        suppressExcelExport: true,
        frameworkComponents: cellRendererComponents,
        // Selectable for copy-paste https://www.ag-grid.com/angular-data-grid/selection-overview/
        suppressCellSelection: false,
        suppressNoRowsOverlay: false,
        suppressRowClickSelection: false,
        enableCellTextSelection: true,
        ensureDomOrder: true,

        isFullWidthCell: () => true,
        fullWidthCellRenderer: 'reweighLogDetailsCellRendererComponent',
        // fullWidthCellRendererParams: (params) => {
        //   logHeaderId: params.data?.logHeaderId;
        // },
        // detailRowAutoHeight: true,//seems not working
        getRowHeight: (params) => {
          let result: number = aDetailGridOptionsRowHeight;
          if (this.logHeadersDataSourceService.recordsCount === 1) {
            result = ReweighLogHeadersGridService.DETAIL_ROW_HEIGHT_FOR_SINGLE;
          }
          return result;
        }, // end detailGridOptions
      },
    };
  }
} // end class
