import { ExtendedLiftScaleCalibrationHeader } from '@shared/classes/entities/extended-lift-scale-calibration-header';
import { OperatorLogDetail } from '@shared/classes/entities/operator-log-detail';
import { OperatorLogDetailHist } from '@shared/classes/entities/operator-log-detail-hist';

export class ScaleTestRouting {
  vendorScaleId: String;
  equipmentIdPrefix: String;
  equipmentIdSuffixNbr: number;

  constructor(vendorScaleId: String, equipmentIdPrefix: String, equipmentIdSuffixNbr: number) {
    this.vendorScaleId = vendorScaleId;
    this.equipmentIdPrefix = equipmentIdPrefix;
    this.equipmentIdSuffixNbr = equipmentIdSuffixNbr;
  }

  static buildFromOperatorLogDetail(src: OperatorLogDetail): ScaleTestRouting {
    const result: ScaleTestRouting = new ScaleTestRouting(
      src.vendorScaleId,
      src.equipmentIdPrefix,
      src.equipmentIdSuffixNbr
    );
    return result;
  }

  static buildFromOperatorLogDetailHist(src: OperatorLogDetailHist): ScaleTestRouting {
    const result: ScaleTestRouting = new ScaleTestRouting(
      src.vendorScaleId,
      src.equipmentIdPrefix,
      src.equipmentIdSuffixNbr
    );
    return result;
  }

  static buildFromExtendedLiftScaleCalibrationHeader(src: ExtendedLiftScaleCalibrationHeader): ScaleTestRouting {
    const result: ScaleTestRouting = new ScaleTestRouting(
      src.vendorScaleId,
      src.equipmentIdPrefix,
      src.equipmentIdSuffixNbr
    );
    return result;
  }
}
