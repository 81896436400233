/**
 * first is highest, last is lowest
 */
export enum AccessRole {
  LTL_REWEIGH_SUPER_USER = 'LTL_REWEIGH_SU',
  LTL_REWEIGH_REVIEW = 'LTL_REWEIGH_REVIEW',
  TST_LTL_REWEIGH_REVIEW = 'TST_LTL_REWEIGH_REVIEW',
  LTL_REWEIGH_READ_ONLY = 'LTL_REWEIGH_READ_ONLY'


}
