<!--exCode:{{ uiEntityData?.exceptionCd }}-->
<!--propName:{{uiEntityProperty.propertyName}}-->
<a
  xpo-link
  class="field_value"
  (click)="onErrorDetailsClicked(uiEntityData)"
  matTooltip="Click to see error details"
  *ngIf="uiEntityData.hasErrorDetail && uiEntityProperty.propertyName === 'errorDetail'"
  >See error details</a
>
<a
  xpo-link
  *ngIf="uiEntityProperty.propertyName === 'exceptionCd'"
  matTooltip="Click for more details on exception"
  (click)="onExceptionCdClicked(uiEntityData)"
  >{{ uiEntityData[uiEntityProperty.propertyName] }}</a
>
