import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { YesNoOptionPipe } from '@shared/pipes';
import { SharedModule } from '@shared/shared.module';
import { XpoLtlPipeModule } from '@xpo-ltl/ngx-ltl';
import { ReweighLogCommonModule } from '../reweigh-log-common/reweigh-log-common.module';
import { ReweighLogSummaryPageComponent } from '../reweigh-log-summary/reweigh-log-summary.component';
import { ReweighLogSummaryPageModule } from '../reweigh-log-summary/reweigh-log-summary.module';
import { ReweighLogSummaryRoutingModule } from '../reweigh-log-summary/reweigh-log-summary.routing';
import { ReweighLogSummary2PageModule } from '../reweigh-log-summary2/reweigh-log-summary2.module';
import { ReweighLogHeaderViewsComponent } from './reweigh-log-header-views.component';

@NgModule({
  declarations: [ReweighLogHeaderViewsComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReweighLogCommonModule,
    ReweighLogSummaryRoutingModule,
    XpoLtlPipeModule,
    ReweighLogSummaryPageModule,
    ReweighLogSummary2PageModule,
  ],
  providers: [YesNoOptionPipe],
  exports: [ReweighLogHeaderViewsComponent],
})
export class ReweighLogHeaderViewsModule {}
