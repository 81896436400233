import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MatDialogModule} from '@angular/material/dialog';
import {PortlandDateOrNa} from '@shared/pipes/portland-date-or-na.pipe';
import {YesNoOptionPipeOrNa} from '@shared/pipes/yes-no-option/yes-no-option-or-na.pipe';
import {ReweighLogHeaderViewsService} from '@shared/services/reweigh-log-header-views.service';
import {ReweighLogSummaryService} from '@shared/services/reweigh-log-summary.service';
import {InspectionsApiServiceWrapper} from '@shared/services/sdk/inspections-api-service-wrapper.service';
import {ReweighApiServiceWrapper} from '@shared/services/sdk/reweigh-api-service-wrapper.service';
import {InspectionsApiService} from '@xpo-ltl/sdk-inspections';
import {MaterialModule} from 'app/material/material.module';
import {DirectivesModule} from '../directives/directives.module';
import {LogHeadersDataSourceService} from '../reweigh-log-headers/reweigh-log-headers-home/services';
import {
  ActionBarCenterContent,
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarRightContent,
} from './components/action-bar/action-bar.component';
import {NotAuthorizedComponent} from './components/not-authorized/not-authorized.component';
import {SicSwitcherWrapperComponent} from './components/sic-switcher-wrapper/sic-switcher-wrapper.component';
import {SnackbarDetailComponent} from './components/snackbar-detail/snackbar-detail.component';
import {PortlandDatePipe} from './pipes/portland-date.pipe';
import {YesNoOptionPipe} from './pipes/yes-no-option/yes-no-option.pipe';

const shared = [
  // Components
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarCenterContent,
  ActionBarRightContent,
  NotAuthorizedComponent,
  SicSwitcherWrapperComponent,
  // Pipes/Directives
  YesNoOptionPipe,
  YesNoOptionPipeOrNa,
  PortlandDatePipe,
  PortlandDateOrNa,
];

@NgModule({
  declarations: [...shared, SnackbarDetailComponent, PortlandDatePipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    // Pipes/Directives
    FormsModule,
    MatDialogModule,
    DirectivesModule,
  ],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, ...shared],
  providers: [YesNoOptionPipe,
    LogHeadersDataSourceService,
    ReweighLogHeaderViewsService,
    ReweighLogSummaryService,
  ],
  // providers:  MAKE providedIn: 'root' on your services!!!
})
export class SharedModule {
}
