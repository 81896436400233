import { Injectable } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { XpoNotificationTemplate, XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { XpoSnackBarConfig } from '@xpo-ltl/ngx-ltl-core/lib/snack-bar/models/xpo-snack-bar-config.interface';

@Injectable({
  providedIn: 'root',
})
export class ReweighAppNotificationService {
  protected static readonly DEFAULT_DURATION: number = 7_000;

  constructor(protected xpoSnackBar: XpoSnackBar) {}

  error(e: any, snackBarOpenDurationMillis?: number): void {
    this.xpoSnackBar.open({
      message: e.error?.error?.message ?? e.error?.message ?? e.message ?? e,
      status: 'error',
      matConfig: {
        duration: this.getSnackBarDuration(snackBarOpenDurationMillis),
      },
    });
  }

  warning(e: any, snackBarOpenDurationMillis?: number): void {
    this.xpoSnackBar.open({
      message: e.error?.message ?? e.message ?? e,
      status: 'warn',
      matConfig: {
        duration: this.getSnackBarDuration(snackBarOpenDurationMillis),
      },
    });
  }

  success(e: any, snackBarOpenDurationMillis?: number): void {
    this.xpoSnackBar.open({
      message: e.error?.message ?? e.message ?? e,
      status: 'success',
      matConfig: {
        duration: this.getSnackBarDuration(snackBarOpenDurationMillis),
      },
    });
  }

  /**
   * milliseconds
   *
   * @param snackBarOpenDuration
   * @private
   */
  private getSnackBarDuration(snackBarOpenDuration: number): number {
    let result: number = ReweighAppNotificationService.DEFAULT_DURATION;
    if (!isNaN(snackBarOpenDuration)) {
      result = snackBarOpenDuration;
    }
    return result;
  }

  info(e: any, snackBarOpenDurationMillis?: number): void {
    this.xpoSnackBar.open({
      message: e.error?.message ?? e.message ?? e,
      status: 'success',
      matConfig: {
        duration: this.getSnackBarDuration(snackBarOpenDurationMillis),
      },
    });
  }

  open(param: XpoSnackBarConfig): MatSnackBarRef<XpoNotificationTemplate> {
    return this.xpoSnackBar.open(param);
  }
}
