import { RwExceptionCdDialogComponent } from '@dialogs/rw-exception-cd-dialog/rw-exception-cd-dialog.component';
import { LogHeader } from '@xpo-ltl/sdk-reweigh';

export class ReweighLogHeaderUtil {
  static getDialogConfig(aLogHeader: LogHeader): any {
    const result: any = {
      maxWidth: '80vw',
      maxHeight: '80vh',
      data: {
        title: `Exception details for Log Header ID ${aLogHeader.logHeaderId}`,
        logHeader: aLogHeader,
      },
    };
    return result;
  }
}
