import {IOperatorFirstnamLastname} from '@shared/classes/entities/operator-firstname-lastname';
import {UiEntityTableEntity} from '@shared/classes/entities/ui-entity-table-entity';
import {LogHeaderHist} from '@xpo-ltl/sdk-reweigh';

export class OperatorLogHeaderHist extends LogHeaderHist implements IOperatorFirstnamLastname, UiEntityTableEntity {
  operatorFullName: string;
  hasErrorDetail: boolean = false;
  weightChange: number;
  exceedsToleranceInd: boolean;

  static toOperatorLogHeaderHist(logHeader: LogHeaderHist): OperatorLogHeaderHist {
    let result: OperatorLogHeaderHist = null;
    if (logHeader) {
      result = new OperatorLogHeaderHist();
      Object.assign(result, logHeader); // Object.assign(target, source)
      result.setOperatorFullName();
      result.setWeightChange();
      result.setExceedsTolInd();
      result.hasErrorDetail = logHeader.errorDetail && logHeader.errorDetail.length > 0;
      if (result.hasErrorDetail) {
        try {
          result.errorDetail = JSON.parse(logHeader.errorDetail);
        } catch (e) {
          // ignoring, we can have 'trimmed: {invalidJsonString'
          // console.info('toOperatorLogHeaderHist json parsing issue', logHeader.errorDetail);
        }
      }
    }
    return result;
  }

  setOperatorFullName() {
    if (this.operatorFirstName) {
      this.operatorFullName = this.operatorFirstName;
    }
    if (this.operatorLastName) {
      if (this.operatorFullName?.length > 0) {
        this.operatorFullName += ' ' + this.operatorLastName;
      }
    }
  }

  setWeightChange() {
    if (!isNaN(this.reweighWeight) && !isNaN(this.shipmentWeight)) {
      this.weightChange = this.reweighWeight - this.shipmentWeight;
    }
  }

  setExceedsTolInd() {
    this.exceedsToleranceInd = !isNaN(this.weightChange) &&
      !isNaN(this.appliedTolWeight) && this.weightChange > this.appliedTolWeight;
  }
}
