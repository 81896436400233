import { NgModule } from '@angular/core';

import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core';

import { CommonModule } from '@angular/common';
import { ReweighHeaderSummaryDialogComponent } from '@dialogs/reweigh-header-summary-dialog/reweigh-header-summary-dialog.component';
import { SharedModule } from '@shared/shared.module';
import { ReweighLogHeaderViewsModule } from '../reweigh-log-headers/reweigh-log-header-views/reweigh-log-header-views.module';
import { ReweighLogSummaryPageModule } from '../reweigh-log-headers/reweigh-log-summary/reweigh-log-summary.module';
import { FailedLogHeaderUpdateDialogComponent } from './failed-log-header-update-dialog/failed-log-header-update-dialog.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { ReweighHeaderErrorDetailsDialogComponent } from './reweigh-header-details-dialog/reweigh-header-error-details-dialog.component';
import { RwExceptionCdDialogComponent } from './rw-exception-cd-dialog/rw-exception-cd-dialog.component';

const dialogs = [
  // YOUR DIALOGS
  InfoDialogComponent,
  FailedLogHeaderUpdateDialogComponent,
  RwExceptionCdDialogComponent,
  ReweighHeaderErrorDetailsDialogComponent,
  ReweighHeaderSummaryDialogComponent,
];

@NgModule({
  declarations: [...dialogs],
  imports: [
    // MATERIAL OR NGX CORE COMPONENTS
    CommonModule,
    XpoDialogModule,
    SharedModule,
    ReweighLogSummaryPageModule, // todo pg: remove if possible
    ReweighLogHeaderViewsModule,
  ],
  exports: [...dialogs],
})
export class DialogModule {}
