<ng-container>
  <ng-container *ngIf="!isImageCreated" matTooltip="No existing certificate">&nbsp;</ng-container>
  <button
    *ngIf="isImageCreated"
    mat-icon-button
    matTooltip="Download certificate"
    matTooltipPosition="above"
    (click)="onDownloadCertificateClicked()"
  >
    <mat-icon class="mat-icon-download">download</mat-icon>
  </button>
  <!-- hide this until we can use PDF -->
  <!-- <button
    *ngIf="isImageCreated"
    mat-icon-button
    matTooltip="Open certificate"
    matTooltipPosition="above"
    (click)="onViewCertificateClicked()"
  >
    <mat-icon>open_in_new</mat-icon>
  </button> -->
</ng-container>
