import { RwgLogHeaderMoreInfoCd } from '@xpo-ltl/sdk-common';

import { KeyValueLabel } from '@shared/interfaces';

export class ReweighMoreInfoCdHelper {
  protected static keysValuesArray: KeyValueLabel[] = null;

  /** Return the keys of RwgLogHeaderMoreInfoCd */
  protected static getKeys(): string[] {
    const keys = Object.keys(RwgLogHeaderMoreInfoCd);
    return keys;
  }

  /** Return the key base on the enum */
  protected static getKey(status: RwgLogHeaderMoreInfoCd): string {
    const keys = this.getKeys();
    const key = keys.find((k) => status === RwgLogHeaderMoreInfoCd[k]);
    return key;
  }

  /**
   * Return the array of { key, value } from RwgLogHeaderMoreInfoCd
   * built only once
   * */
  static getKeyValuesArray(): KeyValueLabel[] {
    if (ReweighMoreInfoCdHelper.keysValuesArray == null) {
      const keys = this.getKeys();
      const keyValues: KeyValueLabel[] = keys.map((k) => {
        return {
          key: k,
          value: RwgLogHeaderMoreInfoCd[k],
          label: RwgLogHeaderMoreInfoCd[k],
          tooltip: ReweighMoreInfoCdHelper.getCorrespondingLabel(RwgLogHeaderMoreInfoCd[k]),
        };
      });
      ReweighMoreInfoCdHelper.keysValuesArray = keyValues;
    }

    return ReweighMoreInfoCdHelper.keysValuesArray;
  }

  static getCorrespondingLabel(rwgLogHeaderMoreInfoCd: string | RwgLogHeaderMoreInfoCd): string {
    let result: string = rwgLogHeaderMoreInfoCd;
    if (rwgLogHeaderMoreInfoCd && rwgLogHeaderMoreInfoCd.trim().length > 0) {
      switch (rwgLogHeaderMoreInfoCd) {
        case RwgLogHeaderMoreInfoCd.UP_COR_ST_FR_ERR_SUCC:
          result = 'Updated correction status from Error succeed';
          break;
        case RwgLogHeaderMoreInfoCd.UP_COR_ST_FR_PEND_SUCC:
          result = 'Updated correction status from Pending succeed';
          break;
        case RwgLogHeaderMoreInfoCd.PSR_BC_RECOVERY:
          result = 'Recovered during processShipmentReweigh';
          break;
        case RwgLogHeaderMoreInfoCd.UP_COR_ST_FR_ERR_NO_SHM:
          result = 'Updated correction status no shipment history found';
          break;
        case RwgLogHeaderMoreInfoCd.UP_COR_ST_NO_BC_FOUND:
          result = 'Updated correction status no billCorrection history found';
          break;
        default:
          throw new Error('Missing enum label for:' + rwgLogHeaderMoreInfoCd);
      }

      return result;
    } else {
      throw new Error('Reweigh MoreInfoCd cannot be empty.');
    }
  }

  static getAllMoreInfoCdsArray(): RwgLogHeaderMoreInfoCd[] {
    let result: RwgLogHeaderMoreInfoCd[] = null;
    result = Object.values(RwgLogHeaderMoreInfoCd);
    return result;
  }
}
