<xpo-shell class="app-container" [drawerClosedByDefault]="false" [versionNumber]="build">
  <xpo-app-switcher-popover *ngIf="(apps$ | async)?.length" [applications]="apps$ | async"></xpo-app-switcher-popover>
  <xpo-header-app-name data-test="xpo-header-app-name">Edge</xpo-header-app-name>
  <xpo-header-sub-app-name data-test="xpo-header-sub-app-name">{{ title }}</xpo-header-sub-app-name>
  <xpo-header-navigation>
    <button *ngFor="let route of routes" [routerLink]="route.path" routerLinkActive="xpo-selected">
      {{ route.label }}
    </button>
  </xpo-header-navigation>
  <xpo-header-utilities-actions>
    <!-- <mat-icon>search</mat-icon> -->
    <!-- <xpo-notification-popover [notifications]="notifications"></xpo-notification-popover> -->
    <xpo-auth-user-profile>
      <xpo-auth-user-profile-links>
        <a [href]="AccountUrls.myAccount" target="_blank">My Account</a>
        <a (click)="openSettingsDialogClicked()">Settings <mat-icon>settings_applications</mat-icon></a>
      </xpo-auth-user-profile-links>
    </xpo-auth-user-profile>
  </xpo-header-utilities-actions>
  <router-outlet></router-outlet>
  <xpo-footer [version]="build">
    <span *ngIf="feedbackService.feedbackConfig$ | async">
      <a xpo-regular-link color="accent" (click)="handleFeedbackClick()">Feedback</a>
    </span>
    <a [href]="releaseNotesLink" target="_blank">Release Notes</a>
  </xpo-footer>
</xpo-shell>
<app-notification></app-notification>
