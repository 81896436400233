import { UiEntityPropertyOptions } from '@shared/classes/entities/ui-entity-property-options';

export class UiEntityProperty {
  propertyName: string;
  variableType: string;
  uiEntityPropertyOptions: UiEntityPropertyOptions;

  constructor(variableName: string, variableType: string, aUiEntityPropertyOptions?: UiEntityPropertyOptions) {
    this.propertyName = variableName;
    this.variableType = variableType;
    this.uiEntityPropertyOptions = aUiEntityPropertyOptions;
    this.init();
  }

  getLabel(): string {
    let result: string = null;
    if (this.hasUiEntityPropertyOptions()) {
      result = this.uiEntityPropertyOptions.label;
    }
    return result;
  }

  hasLabel() {
    return this.uiEntityPropertyOptions && this.uiEntityPropertyOptions.label?.trim()?.length > 0;
  }

  private hasUiEntityPropertyOptions(): boolean {
    return !!this.uiEntityPropertyOptions;
  }

  isVisibleColumn(): boolean {
    let result: boolean = true;
    if (this.hasUiEntityPropertyOptions()) {
      result = this.uiEntityPropertyOptions.isVisibleColumn !== false; // default is undefined if not set
    }
    return result;
  }

  getColumnTooltip(): string {
    let result: string = null;
    if (this.hasUiEntityPropertyOptions()) {
      result = this.uiEntityPropertyOptions.columnTooltip;
    }
    return '';
  }

  init() {
    const lPropertyOptionsInstance: UiEntityPropertyOptions = new UiEntityPropertyOptions();
    if (this.hasUiEntityPropertyOptions()) {
      Object.assign(lPropertyOptionsInstance, this.uiEntityPropertyOptions); // Object.assign(target, source)
    }
    this.uiEntityPropertyOptions = lPropertyOptionsInstance;
    UiEntityPropertyOptions.setDefaults(this.uiEntityPropertyOptions);
  }
}
