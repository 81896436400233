export class ProNumberHelper {
  static getNineDigitString(proNbr: string): string {
    if (proNbr.length > 9) {
      const prefix = proNbr.substring(0, 4).slice(1);
      const suffix = proNbr.substring(4).slice(1);
      proNbr = `${prefix}${suffix}`;
    }
    return proNbr;
  }
}
