import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ExtendedInspectionDimension} from '@shared/classes/entities/extended-inspection-dimension';
import {ReweighLogHeaderViewsService} from '@shared/services/reweigh-log-header-views.service';
import {UserRoleService} from '@shared/services/user-role';
import {UiEntityTableCellComponentBase} from '../../../../directives/ui-entity-table-cell-component-base';

@Component({
  selector: 'app-shipment-dimensions-custom',
  templateUrl: './shipment-dimensions-custom.component.html',
  styleUrls: ['./shipment-dimensions-custom.component.scss']
})
export class ShipmentDimensionsCustomComponent
  extends UiEntityTableCellComponentBase<ExtendedInspectionDimension>
  implements OnInit {

  hasCurrentUserWriteAccess: boolean = false;

  constructor(protected router: Router,
              protected reweighLogHeaderViewsService: ReweighLogHeaderViewsService,
              protected userRoleService: UserRoleService) {
    super();
    this.hasCurrentUserWriteAccess = this.userRoleService.currentUserHasWriteAccess();
  }

  ngOnInit(): void {
  }

}
