export enum AppRoutes {
  REWEIGH_LOG_SUMMARY_PAGE = 'reweigh-log-summary',
  NOT_AUTHORIZED_PAGE = 'not-authorized',
  REWEIGH_LOG_HEADERS = 'reweigh-logs-headers',
  REWEIGH_CALIBRATION_HEADERS = 'reweigh-calibration-headers',
}

export enum ReweighLogHeaderPathParams {
  LOG_HEADER_ID = 'logHeaderId',
  LOG_HEADER_FILTER_TYPE = 'logHeaderFilterType',
  PRO_NBR = 'proNbr',
}

export enum ScaleTestsPathParams {
  VENDOR_SCALE_ID = 'vendorScaleId',
  EQUIPMENT_PREFIX = 'equipmentPrefix',
  EQUIPMENT_SUFFIX = 'equipmentSuffix',
}

export enum AppRoutesLabels {
  REWEIGH_LOG_HEADERS = 'Reweigh Logs',
  REWEIGH_CALIBRATION_HEADERS = 'Reweigh Calibration Headers',
}
