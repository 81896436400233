<span class="exception-description__text">{{ displayText }}</span>
<button
  class="show-more-button"
  color="primary"
  mat-button
  xpoSmallButton
  (click)="showMoreDialog()"
  *ngIf="showLoadMoreLink"
>
  Show more
</button>
