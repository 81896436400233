import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ExtendedLiftScaleCalibrationHeader} from '@shared/classes/entities/extended-lift-scale-calibration-header';
import {ScaleTestUtil} from '@shared/classes/utils/scale-test-util';
import {ScaleTestRouting} from '@shared/interfaces/scale-test-routing';
import {ReweighLogHeaderViewsService} from '@shared/services/reweigh-log-header-views.service';
import {UserRoleService} from '@shared/services/user-role';
import {UiEntityTableCellComponentBase} from '../../../../directives/ui-entity-table-cell-component-base';

@Component({
  selector: 'app-reweigh-log-scale-test-custom',
  templateUrl: './reweigh-log-scale-test-custom.component.html',
  styleUrls: ['./reweigh-log-scale-test-custom.component.scss'],
})
export class ReweighLogScaleTestCustomComponent
  extends UiEntityTableCellComponentBase<ExtendedLiftScaleCalibrationHeader>
  implements OnInit {

  hasCurrentUserWriteAccess: boolean = false;

  constructor(protected router: Router,
              protected reweighLogHeaderViewsService: ReweighLogHeaderViewsService,
              protected userRoleService: UserRoleService) {
    super();
    this.hasCurrentUserWriteAccess = this.userRoleService.currentUserHasWriteAccess();
  }

  ngOnInit(): void {
  }

  onScaleTestSearchClicked(aUiEntityData: ExtendedLiftScaleCalibrationHeader) {
    ScaleTestUtil.navigateToScaleTestsFor(
      ScaleTestRouting.buildFromExtendedLiftScaleCalibrationHeader(aUiEntityData),
      this.router
    );
    this.reweighLogHeaderViewsService.reweighLogHeaderViewsComponent.close();
  }



}
