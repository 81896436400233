import { Moment } from 'moment';
import moment from 'moment-timezone';

export class DateHelper {
  static readonly PORTLAND_TIMEZONE_STRING: string = 'America/Los_Angeles'; // PST PDT
  static readonly DEFAULT_MDY_FORMAT: string = 'MM-DD-YYYY';
  static readonly DEFAULT_BACKEND_DATE_STRING_FORMAT: string = 'YYYY-MM-DD';
  static readonly DEFAULT_UI_DATE_OUTPUT_FORMAT: string = 'MM/DD/YYYY';

  /**
   * Format date, based on default application PORTLAND_TIMEZONE_STRING
   * @default format = 'MM-DD-YYYY'
   */
  static getPortandTzFormattedDate(date: Date | Moment, format: string = DateHelper.DEFAULT_MDY_FORMAT): string {
    let result: string = '';
    if (date) {
      result = moment(date)
        .tz(DateHelper.PORTLAND_TIMEZONE_STRING)
        .format(format);
    }
    return result;
  }

  /**
   * Adds one second and returns the date with given format
   *
   * @param date
   * @param format
   */
  static getDatePickerFormattedDate(date: Date | Moment, format: string = DateHelper.DEFAULT_MDY_FORMAT): string {
    // adding one second because if 00:00:00 then gives us the day before
    const result: string = moment(date)
      .add(1, 'second')
      .format(format);

    return result;
  }

  // Getting 'No overload matches this call.' error so commenting this out for now since there is no usage.
  // static getFormatDateFromSting(date: String, format: string = 'MM-DD-YYYY'): Moment {
  //   return moment(date, format);
  // }

  static getDayBefore(date: Date): Date {
    const dayBefore = new Date(date);
    dayBefore.setDate(dayBefore.getDate() - 1);
    return dayBefore;
  }

  /**
   * Return today date, but without time
   */
  static getToday(): Date {
    const result: Date = new Date();
    return result;
  }

  static addDays(date: Date, daysToAdd): Date {
    const dateToReturn = new Date(date);
    dateToReturn.setDate(dateToReturn.getDate() + daysToAdd);
    return dateToReturn;
  }

  static removeDays(date: Date, daysToRemove): Date {
    const dateToReturn = new Date(date);
    dateToReturn.setDate(dateToReturn.getDate() - daysToRemove);
    return dateToReturn;
  }

  static addMinutes(date: Date, minutesToAdd: number): Date {
    const dateMs = date.getTime();
    const msToAdd = minutesToAdd * 6000;
    const newDateMs = dateMs + msToAdd;
    return new Date(newDateMs);
  }

  static addMilliseconds(date: Date, msToAdd: number): Date {
    const dateMs = date.getTime();
    const newDateMs = dateMs + msToAdd;
    return new Date(newDateMs);
  }

  static getDifferenceInMinutes(dateFrom: Date, dateTo: Date): number {
    const dateFromMs = dateFrom.getTime();
    const dateToMs = dateTo.getTime();
    const differenceMs = dateToMs - dateFromMs;
    if (!differenceMs || differenceMs <= 0) {
      return 0;
    }
    return Math.round(differenceMs / 6000);
  }

  /**
   * with given date, we should have returned
   * given 2023-03-09T10:00:01 => 2023-03-09T08:00:01Z
   * @param formValue
   */
  static toPortlandUtcBeginningOfDay(formValue: any): string {
    let result: string = null;
    if (formValue) {
      const fromDateAsMoment: moment.Moment = moment(formValue);
      const resultMoment: Moment = this.getMomentAtMiddayForTimezone(fromDateAsMoment, 'UTC');
      // when changing dates from picker, we receive 00:00:00 from today which is yesterday
      const portlandOffsetToUtc: number = DateHelper.getPortlandToUtcOffsetForGivenDay(formValue);
      resultMoment.startOf('day');
      if (this.isBeginningOfDayMoment(resultMoment)) {
        resultMoment.add(1, 'second');
      }
      resultMoment.add(-portlandOffsetToUtc, 'minutes');
      result = this.toDateIsoStringWithoutMilliseconds(resultMoment);
    }

    return result;
  }

  /**
   * returns null if formValue is null, otherwise returns the corresponding isoString
   * ex: 2023-05-09T06:59:59Z
   * @param formValue
   */
  static toPortlandUtcEndOfDay(formValue: any): string {
    let result: string = null;
    if (formValue) {
      const fromDateAsMoment: moment.Moment = moment(formValue);
      const resultMoment: Moment = this.getMomentAtMiddayForTimezone(fromDateAsMoment, 'UTC');
      // when changing dates from picker, we receive 00:00:00 from today which is yesterday
      const portlandOffsetToUtc: number = DateHelper.getPortlandToUtcOffsetForGivenDay(formValue);
      if (this.isBeginningOfDayMoment(resultMoment)) {
        resultMoment.add(1, 'second');
      }
      resultMoment.endOf('day');
      resultMoment.add(-portlandOffsetToUtc, 'minutes');
      result = this.toDateIsoStringWithoutMilliseconds(resultMoment);
    }

    return result;
  }

  /**
   * return ex: 1962-07-18T13:10:44.031Z
   */
  static toDateIsoStringWithoutMilliseconds(aMoment: moment.Moment): string {
    let result: string = null;
    if (aMoment) {
      const isoString: string = aMoment.toISOString(false);
      result = isoString.replace(/.\d+Z$/g, 'Z');
    }
    return result;
  }

  static isBeginningOfDayMoment(aMoment: moment.Moment): boolean {
    const result: boolean = aMoment.hours() === 0 && aMoment.minutes() === 0 && aMoment.seconds() === 0;

    return result;
  }

  static getPortlandToUtcOffsetForGivenDay(aMoment: moment.Moment): number {
    let result: number = 0;
    const momentToPlayWith: moment.Moment = this.getMomentAtMiddayForTimezone(aMoment, this.PORTLAND_TIMEZONE_STRING);
    // create moment without altering the day, cause datepicker gives timezone and 00:00:00
    result = momentToPlayWith.utcOffset();
    return result;
  }

  static getMomentAtMiddayForTimezone(aMoment: moment.Moment, timezoneString: string): moment.Moment {
    const todayS: string = aMoment.year() + ' ' + (aMoment.month() + 1) + ' ' + aMoment.date() + ' 12:00:00';
    const result: moment.Moment = moment.tz(todayS, 'YYYY M D HH:mm:ss', timezoneString);

    return result;
  }

  static fromApiDateStringToFormattedDateString(pickupDate: string | undefined): string|undefined {
    let result: string;
    if (pickupDate?.length > 0) {
      result = moment(pickupDate, DateHelper.DEFAULT_BACKEND_DATE_STRING_FORMAT)
        .format(DateHelper.DEFAULT_UI_DATE_OUTPUT_FORMAT);
    }
    return result;
  }
}
