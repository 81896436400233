import {Component} from '@angular/core';
import {ReweighLogHeaderPathParams} from '@shared/enums';
import {ReweighLogHeaderFilterTypes} from '@shared/enums/reweigh-log';
import {QueryParamsService} from '@shared/services/query-params/query-params.service';
import {ShipmentDetailsService} from '@shared/services/shipment-details/shipment-details.service';
import {UserRoleService} from '@shared/services/user-role';
import {LogHeader} from '@xpo-ltl/sdk-reweigh';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'pro-number-cell-renderer',
  templateUrl: './pro-number-cell-renderer.component.html',
  styleUrls: ['./pro-number-cell-renderer.component.scss'],
})
export class ProNumberRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  rowData: LogHeader;
  hasCurrentUserWriteAccess: boolean = false;

  constructor(private queryParamsService: QueryParamsService,
              private shipmentDetailsService: ShipmentDetailsService,
              protected userRoleService: UserRoleService) {
    this.hasCurrentUserWriteAccess = this.userRoleService.currentUserHasWriteAccess();
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.rowData = this.params.node.data;
  }

  onLinkClicked(): void {
    const queryParams = {
      [ReweighLogHeaderPathParams.LOG_HEADER_FILTER_TYPE]: ReweighLogHeaderFilterTypes.PRO_SEARCH,
      [ReweighLogHeaderPathParams.PRO_NBR]: this.params.value,
      [ReweighLogHeaderPathParams.LOG_HEADER_ID]: this.params.node.data.logHeaderId,
    };
    this.queryParamsService.handleQueryParamsChanged(queryParams);
  }

  openShipmentDetails(): void {
    this.shipmentDetailsService.openShipmentDetails(this.rowData.shipmentInstanceId, this.rowData.proNbr);
  }

  refresh(): boolean {
    return false;
  }
}
