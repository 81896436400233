import {Injectable} from '@angular/core';
import {ExtendedInspectionDimension} from '@shared/classes/entities/extended-inspection-dimension';
import {ExtendedLiftScaleCalibrationHeader} from '@shared/classes/entities/extended-lift-scale-calibration-header';
import {ExtendedLogHeader} from '@shared/classes/entities/extended-log-header';
import {Reweigh} from '@shared/classes/reweigh';
import {ReweighAppNotificationService} from '@shared/services/reweigh-app-notification.service';
import {InspectionsApiServiceWrapper} from '@shared/services/sdk/inspections-api-service-wrapper.service';

import {LogHeaderShipment} from '@xpo-ltl/sdk-reweigh';
import {Subject} from 'rxjs';
import {ReweighApiServiceWrapper} from '../sdk/reweigh-api-service-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class ReweighService {
  subject: Subject<Reweigh> = new Subject<Reweigh>();

  reweigh: Reweigh;

  constructor(
    protected reweighLogHeadersService: ReweighApiServiceWrapper,
    protected inspectionsApiServiceWrapper: InspectionsApiServiceWrapper,
    protected reweighAppNotificationService: ReweighAppNotificationService
  ) {
  }

  loadByLogHeaderIdIfNotLoaded(logHeaderId: number) {
    if (logHeaderId !== this.reweigh?.getLogHeaderId()) {
      if (logHeaderId && !isNaN(logHeaderId as any)) {
        this.reweigh = new Reweigh();
        // logheader
        this.reweighLogHeadersService.getReweighLog(logHeaderId)
          .subscribe((logHeader: ExtendedLogHeader) => {
            if (!logHeader) {
              throw Error('no logHeader went back from getReweighLog'); // its a popup no way to navigate back...
            }
            this.reweigh.logHeader = logHeader;
            if (!this.hasInspectionDimensions() && ! isNaN(this.reweigh.logHeader.shipmentInstanceId)) {
              this.setInspectionDimensions(this.reweigh.logHeader.shipmentInstanceId);
            }
            this.subject.next(this.reweigh);
          });
        // shipment
        this.reweighLogHeadersService.getShipmentForLogHeaderByLogHeaderId(logHeaderId)
          .subscribe((aLogHeaderShipment: LogHeaderShipment) => {
            this.reweigh.logHeaderShipment = aLogHeaderShipment;
            this.subject.next(this.reweigh);
          });
        // scaleTests
        this.reweighLogHeadersService.listLatestScaleTestsByLogHeaderId(logHeaderId)
          .subscribe((aLiftScaleCalibrationHeaders: ExtendedLiftScaleCalibrationHeader[]) => {
            this.reweigh.liftScaleCalibrationHeaders = aLiftScaleCalibrationHeaders;
            this.subject.next(this.reweigh);
          });


      } else {
        const msg: string = 'given logHeaderId is not a number:' + logHeaderId;
        console.error(msg);
        throw Error(msg);
      }
    } else {
      this.subject.next(this.reweigh);
    }
  }

  getProNumber(): string {
    const result: string = this.getLogHeader()?.proNbr;
    if (result?.length === 0) {
      const msg: string = 'ProNumber is not available';
      this.reweighAppNotificationService.error(msg);
      throw Error(msg);
    } else {
      return result;
    }
  }

  getLogHeader(): ExtendedLogHeader {
    return this.reweigh?.logHeader;
  }

  getLogHeaderCreateTimestamp(): Date {
    const result: Date = this.getLogHeader()?.auditInfo?.createdTimestamp;
    if (!result) {
      const msg: string = 'createdTimestamp is not available';
      this.reweighAppNotificationService.error(msg);
      throw Error(msg);
    } else {
      return result;
    }
  }

  getShipmentInstanceId(): number {
    const result: number = this.getLogHeader()?.shipmentInstanceId;
    if (isNaN(result)) {
      const msg: string = 'shipmentInstanceId is not available';
      this.reweighAppNotificationService.error(msg);
      throw Error(msg);
    } else {
      return result;
    }
  }

  hasInspectionDimensions(): boolean {
    return this.reweigh.inspectionDimensions?.length > 0;
  }

  protected setInspectionDimensions(aShipmentInstId: number) {
    this.inspectionsApiServiceWrapper.getInspectionDimensionsForShipment(aShipmentInstId).subscribe(
      (aInspectionDimensions: ExtendedInspectionDimension[]) => {
        this.reweigh.inspectionDimensions = aInspectionDimensions;
        this.subject.next(this.reweigh);
      },
      (error) => {
        const msg: string = 'Issue setting inspection dimensions';
        this.reweighAppNotificationService.error(msg);
        throw Error(msg);
      });
  }
}
