import { Injectable } from '@angular/core';
import { ServiceCenter } from '@xpo-ltl-2.0/sdk-location';
import { XpoLtlSicSwitcherComponent } from '@xpo-ltl/ngx-ltl';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ConstantsService {
  sics: ServiceCenter[] = [];

  loadSics(sicSwitcher: XpoLtlSicSwitcherComponent) {
    sicSwitcher.filteredSicList$
      .pipe(filter((resp: ServiceCenter[]) => resp.length > 0))
      .subscribe((response: ServiceCenter[]) => {
        this.sics = response;
      });
  }
}
