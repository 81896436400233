import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '@shared/classes/date-helper';
import { ReweighLogDateFormats } from '@shared/enums/reweigh-log/date-formats.enum';

@Pipe({
  name: 'portlandDate',
})
export class PortlandDatePipe extends DatePipe implements PipeTransform {
  transform(value: any): any {
    const result = DateHelper.getPortandTzFormattedDate(value, ReweighLogDateFormats.ANGULAR_PIPE);
    return result;
  }
}
