import {Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatAccordion} from '@angular/material/expansion';
import {Router} from '@angular/router';
import {ReweighAppNotificationService} from '@shared/services/reweigh-app-notification.service';
import {ReweighLogSummaryService} from '@shared/services/reweigh-log-summary.service';
import {ReweighService} from '@shared/services/reweigh-service/reweigh.service';
import {ShipmentDetailsService} from '@shared/services/shipment-details/shipment-details.service';
import {UserRoleService} from '@shared/services/user-role';
import {ReweighLogSummaryBase} from '../reweigh-log-common/lib/reweigh-log-summary-base';
import {DmsApiWrapper} from './services/dms-api-wrapper.service';

@Component({
  selector: 'app-reweigh-log-summary-page',
  templateUrl: './reweigh-log-summary.component.html',
  styleUrls: ['./reweigh-log-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'reweigh-log-summary-page',
  },
})
export class ReweighLogSummaryPageComponent extends ReweighLogSummaryBase {
  @ViewChild(MatAccordion) accordion: MatAccordion;


  constructor(
    protected dmsApiWrapper: DmsApiWrapper,
    protected shipmentDetailsService: ShipmentDetailsService,
    protected router: Router,
    protected reweighAppNotificationService: ReweighAppNotificationService,
    protected dialog: MatDialog,
    protected reweighService: ReweighService,
    protected userRoleService: UserRoleService,
    protected reweighLogSummaryService: ReweighLogSummaryService,
  ) {
    super(dmsApiWrapper, shipmentDetailsService,
      router, reweighAppNotificationService,
      dialog, reweighService,
      userRoleService, reweighLogSummaryService);
  }

  ngOnInit() {
    super.ngOnInit();
  }


}
