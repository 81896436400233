import {ExtendedLiftScaleCalibrationHeader} from '@shared/classes/entities/extended-lift-scale-calibration-header';
import {ExtendedLogHeader} from '@shared/classes/entities/extended-log-header';
import {LogHeaderShipment} from '@xpo-ltl/sdk-reweigh';
import {ExtendedInspectionDimension} from './entities/extended-inspection-dimension';

export class Reweigh {

  static OPERATOR_FULL_NAME_COL_WIDTH_PX: number = 150;
  logHeader: ExtendedLogHeader | undefined;
  logHeaderShipment: LogHeaderShipment | undefined;
  liftScaleCalibrationHeaders: ExtendedLiftScaleCalibrationHeader[] | undefined;
  inspectionDimensions: ExtendedInspectionDimension[] | undefined;

  constructor() {
  }

  getLogHeaderId(): number {
    return this.logHeader?.logHeaderId;
  }

  getLogHeader() {
    return this.logHeader;
  }

  hasInspectionDimensions(): boolean {
    return this.inspectionDimensions?.length > 0;
  }
}
