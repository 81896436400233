import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {ExtendedLiftScaleCalibrationHeader} from '@shared/classes/entities/extended-lift-scale-calibration-header';
import {UiEntities} from '@shared/classes/entities/ui-entities';
import {UiEntity} from '@shared/classes/entities/ui-entity';
import {UiEntityProperty} from '@shared/classes/entities/ui-entity-property';
import {UiEntityMatTableComponent} from '@shared/components/ui-entity-mat-table/ui-entity-mat-table-component';
import {ExpandableCollapsableComponent} from '@shared/enums/expandable-collapsable-component';
import {ConstantsService} from '@shared/services/constants';
import { ReweighLogSummaryService } from '@shared/services/reweigh-log-summary.service';
import {ReweighApiServiceWrapper} from '@shared/services/sdk/reweigh-api-service-wrapper.service';
import {UserRoleService} from '@shared/services/user-role';
import {UiEntityTableCellDirective} from '../../../directives/ ui-entity-table-cell-directive';
import {ReweighLogSummaryBase} from '../lib/reweigh-log-summary-base';
import {
  ReweighLogScaleTestCustomComponent
} from '../mat-table-cell-projections/reweigh-log-scale-test-custom/reweigh-log-scale-test-custom.component';

@Component({
  selector: 'app-reweigh-log-scale-tests',
  templateUrl: '../../../shared/components/ui-entity-mat-table/ui-entity-mat-table.component.html',
  styleUrls: [
    '../lib/css/reweigh-log-table-section.scss',
    '../../../shared/components/ui-entity-mat-table/ui-entity-mat-table.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  // for @viewChild and inputs to work with inheritence
  providers: [{provide: ReweighLogScaleTestsComponent, useExisting: UiEntityMatTableComponent}],
})
export class ReweighLogScaleTestsComponent
  extends UiEntityMatTableComponent<ExtendedLiftScaleCalibrationHeader, ReweighLogScaleTestCustomComponent>
  implements AfterViewInit {

  protected CHILD_PRO_NUMBER_EMPTY_DEFAULT_VALUE: string = 'N/A';
  COLLAPSE_EXPAND_LABEL_CONTENT: string = ReweighLogSummaryBase.COLLAPSE_EXPAND_LABEL_CONTENT;

  /**
   * Cant be pushed on the parent
   * @param content
   */
  @ViewChild(MatSort, {static: false}) set content(content: ElementRef) {
    this.tableSort = content;
    if (this.tableSort) {
      this.dataSource.sort = this.tableSort;
    }
  }

  @ViewChildren(UiEntityTableCellDirective) uiEntityTableCellDirectiveViewChildren: QueryList<
    UiEntityTableCellDirective<ExtendedLiftScaleCalibrationHeader>
  >;

  @Input() logHeaderId: number;

  @Input()
  tableData: ExtendedLiftScaleCalibrationHeader[];

  @Input() isHeaderTitleDisplayedInput: boolean = true;
  @Input() isFullTableDisplayed: boolean = true;

  constructor(
    protected constantsService: ConstantsService,
    protected reweighApiServiceWrapper: ReweighApiServiceWrapper,
    private changeDetectorRefs: ChangeDetectorRef,
    private dialog: MatDialog,
    private userRoleService: UserRoleService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected reweighLogSummaryService: ReweighLogSummaryService,
    ) {
    super(reweighLogSummaryService);
    reweighLogSummaryService.setReweighLogScaleTestsComponent(this);
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.dataSource.data = this.tableData;
    this.dataIsLoaded = true;
    this.changeDetectorRefs.detectChanges();
    setTimeout(() => {
      this.isTitleSectionDisplayed = this.isHeaderTitleDisplayedInput;
      // load component projection
      this.loadRefs(this.uiEntityTableCellDirectiveViewChildren, this.getComponentFactoryToProject());
    });
    // each time one element with that directive has been inserted into the DOM, then do...
    this.uiEntityTableCellDirectiveViewChildren.changes.subscribe((aUiEntityTableCellDirectiveQryList: any) => {
      // note that we receive aUiEntityTableCellDirectiveQryList as argument,
      // but its the same as this.uiEntityTableCellDirectiveViewChildren so we can use that one as well
      setTimeout(() => {
        this.loadRefs(this.uiEntityTableCellDirectiveViewChildren, this.getComponentFactoryToProject());
      });
    });
  }

  getComponentFactoryToProject(): ComponentFactory<ReweighLogScaleTestCustomComponent> {
    // tslint:disable-next-line:max-line-length
    const lComponentFactoryToProject: ComponentFactory<ReweighLogScaleTestCustomComponent> = this.componentFactoryResolver.resolveComponentFactory<
      ReweighLogScaleTestCustomComponent
    >(ReweighLogScaleTestCustomComponent);
    return lComponentFactoryToProject;
  }

  getUiEntity(): UiEntity {
    let result: UiEntity;
    if (this.isFullTableDisplayed) {
      result = UiEntities.LIFT_SCALE_CALIBRATION_HEADER;
    } else {
      result = UiEntities.LIFT_SCALE_CALIBRATION_HEADER_FOR_DISPUTES;
    }
    return result;
  }

  getMainTitle(): string {
    return 'Lift Scale Tests';
  }

  isLoadDataButtonDisplayed(): boolean {
    return false;
  }

  getSortDefaultColumnName(): string {
    return 'vendorScaleId';
  }

  /**
   * Override
   */
  getVisibleColumns(): string[] {
    const result: string[] = super.getVisibleColumns();
    if (!this.userRoleService.currentUserHasWriteAccess()) {
      const lRemovedFields: string[] = ['operatorId', 'operatorFullName'];
      for (let i = 0; i < result.length; i++) {
        const colName: string = result[i];
        if (lRemovedFields.includes(colName)) {
          result.splice(i, 1);
          i--;
        }
      }
    }
    return result;
  }

  /**
   * Override
   */
  getValueFromRowData(aRowData: any, aUiEntityProperty: UiEntityProperty): string {
    let result: string = '' + aRowData;
    let lShowDefaultData: boolean = true;
    if (aUiEntityProperty.propertyName === 'childProNbr') {
      if (!aRowData || ('' + aRowData).length === 0) {
        result = this.CHILD_PRO_NUMBER_EMPTY_DEFAULT_VALUE;
        lShowDefaultData = false;
      }
    }
    if (lShowDefaultData) {
      result = super.getValueFromRowData(aRowData, aUiEntityProperty);
    }

    return result;
  }

  getComponentForColumnsExpandCollapse(): ExpandableCollapsableComponent {
    return ExpandableCollapsableComponent.ScaleTests;
  }


}
