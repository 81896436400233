import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { XpoAuthenticationService, XpoAuthEventHandler } from '@xpo/ngx-auth';
import { UserManager } from 'oidc-client';

@Injectable()
export class XpoAuthenticationServiceAlwaysLoggedIn extends XpoAuthenticationService {
  constructor(manager: UserManager, router: Router, authEventListener: XpoAuthEventHandler) {
    super(manager, router, authEventListener);

    // ensure we are "logged in"
    manager.getUser().then((user) => {
      this.setUser(user);
    });
  }

  isLoggedIn() {
    // ALWAYS logged in for Cypress tests since Cypress did the login for us
    return true;
  }
}
