<div>
  <h1 mat-dialog-title>
    <span>Update Status</span>
    <span>Succeeded: {{ data.updated.length }}/ Failed: {{ data.errors.length }}</span>
  </h1>
  <div mat-dialog-content>
    <ng-container *ngIf="data.errors.length">
      <div class="table-title">Error Details</div>

      <mat-table [dataSource]="data.errors" class="table-details">
        <ng-container [matColumnDef]="failedLogHeaderTableColmunNames.LOG_HEADER_ID">
          <mat-header-cell *matHeaderCellDef> Reweigh ID </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.logHeader?.logHeaderId }} </mat-cell>
        </ng-container>
        <ng-container [matColumnDef]="failedLogHeaderTableColmunNames.PRO_NBR">
          <mat-header-cell *matHeaderCellDef> PRO </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.logHeader?.proNbr }} </mat-cell>
        </ng-container>
        <ng-container [matColumnDef]="failedLogHeaderTableColmunNames.ERROR_MESSAGE">
          <mat-header-cell *matHeaderCellDef class="error-column"> Error Message </mat-header-cell>
          <mat-cell *matCellDef="let element" class="error-column"> {{ element.error }} </mat-cell>
        </ng-container>
        <ng-container [matColumnDef]="failedLogHeaderTableColmunNames.RETRY">
          <mat-header-cell *matHeaderCellDef class="icon-column"> Can Retry? </mat-header-cell>
          <mat-cell *matCellDef="let element" class="icon-column">{{ element.canRetry | yesNoOption }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <xpo-button-group>
      <button *ngIf="showRetryButton" mat-flat-button (click)="close(true)">
        Retry
      </button>
      <button mat-stroked-button (click)="close()">
        Close
      </button>
    </xpo-button-group>
  </div>
</div>
