import { FaultResponse } from '@xpo-ltl/sdk-common';

export class ErrorHelper {
  private static readonly defaultMessage = 'Error processing the request.';

  static getErrorFromReponse(error: FaultResponse): string {
    let message: string;

    if (error) {
      if (error.moreInfo?.length) {
        message = `${error.errorCode}: ${error.moreInfo[0].message || error.moreInfo[0].location}`;
      } else if (error.message) {
        message = `${error.errorCode}: ${error.message}`;
      }
    }

    return message || this.defaultMessage;
  }
}
