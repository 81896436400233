<div class="sic-switcher-wrapper_field">
  <xpo-ltl-sic-switcher
    [ngClass]="{ disabled: disabled }"
    [sicCd]="sicComponent"
    [sicLabel]="{
      title: title,
      floatLabel: 'always'
    }"
    (sicChange)="handleSicChanged($event?.sicCd)"
  >
  </xpo-ltl-sic-switcher>
  <mat-icon class="remove-icon" title="Remove SIC" *ngIf="sicCdModel && !disabled" (click)="removeSic()">
    close
  </mat-icon>
</div>
