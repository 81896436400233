export class MiscUtil {
  /**
   * returns -1|0|1
   *
   * @param a
   * @param b
   */
  static compareNotNull(a, b: number): number {
    let result: number = 0;
    if (a && b) {
      if (a > b) {
        result = 1;
      } else if (a < b) {
        result = -1;
      }
    }
    return result;
  }

  static leftPadWithZeros(aNumber: number, size: number): string {
    let result: string = aNumber + '';
    while (result.length < size) {
      result = '0' + result;
    }
    return result;
  }

  /**
   * https://dev.to/pffigueiredo/typescript-utility-keyof-nested-object-2pa3
   * @param object
   * @param path
   */
  static getValueFromObjectProperty<ObjectType>(object: ObjectType, path: string): ObjectType {
    const keys = path.split('.');
    let result = object;
    for (const key of keys) {
      result = result[key];
    }
    return result;
  }

  static formattedUsdAmount(src: number): string {
    let result: string = '';
    if (!isNaN(src)) {
      // note that there is no way of getting that into one function
      // https://stackoverflow.com/questions/49724537/
      //  intl-numberformat-either-0-or-two-fraction-digits/49724586#49724586
      // is a fraction
      if (src % 1 === 0) {
        result = Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
        }).format(src);
      } else {
        // is not a fraction
        result = Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
        }).format(src);
      }
    }
    return result;
  }

  static upperCaseUnderscoredToWordsString(aSrc: any): string {
    let result: string = '';
    if (aSrc) {
      result = '' + aSrc;
      result = result.replace('_', ' ');
      result = result
        .replace(/(\B)[^ ]*/g, match => (match.toLowerCase()))
        .replace(/^[^ ]/g, match => (match.toUpperCase()));
    }
    return result;
  }
}
