import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { User } from '@xpo-ltl/sdk-common';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { XpoLtlLoggedInUser } from '@shared/classes/access/xpo-ltl-logged-in-user';
import { NotAuthorizedComponent } from '@shared/components/not-authorized/not-authorized.component';
import { AppRoutes } from '@shared/enums';
import { ConfigManagerProperties } from '@shared/enums/config-manager-properties.enum';
import { ReweighAppNotificationService } from '@shared/services/reweigh-app-notification.service';
import { UserRoleService } from '@shared/services/user-role';

@Injectable({
  providedIn: 'root',
})
export class RoleCheckGuard implements CanActivate {
  constructor(
    private loggedInUserService: XpoLtlLoggedInUserService,
    private dialog: MatDialog,
    private userRoleService: UserRoleService,
    private configManagerService: ConfigManagerService,
    private router: Router,
    protected reweighAppNotificationService: ReweighAppNotificationService
  ) {}

  canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    routerStateSnapshot: RouterStateSnapshot
  ): Observable<boolean> {
    return this.loggedInUserService
      .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .pipe(
        map((user: User) => {
          let result: boolean = false;
          const xpoLtlLoggedInUser: XpoLtlLoggedInUser = new XpoLtlLoggedInUser(user);
          if (xpoLtlLoggedInUser.isAuthorizedUser()) {
            result = this.checkRoleCanAccessRoute(activatedRouteSnapshot, routerStateSnapshot);
          }
          return result;
        }),
        catchError((error) => {
          this.showNotAuthorizedDialog(error);
          return of(false);
        })
      );
  }

  private showNotAuthorizedDialog(error: any) {
    console.error('Error on authentication', error);
    this.dialog.open(NotAuthorizedComponent, { disableClose: true });
  }

  private checkRoleCanAccessRoute(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    routerStateSnapshot: RouterStateSnapshot
  ): boolean {
    let result: boolean = false;
    // console.debug("canActivate:", activatedRouteSnapshot, routerStateSnapshot);
    if (routerStateSnapshot.url.endsWith(AppRoutes.REWEIGH_CALIBRATION_HEADERS)) {
      if (this.userRoleService.currentUserHasScalesAccess()) {
        result = true;
      } else {
        this.reweighAppNotificationService.error('You dont have access to this route and will be redirected');
        this.router.navigateByUrl('/' + AppRoutes.REWEIGH_LOG_HEADERS);
      }
    } else {
      result = true;
    }
    return result;
  }
}
