import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { SharedModule } from '@shared/shared.module';
import { NotificationComponent } from './app-notification/app-notification.component';

@NgModule({
  declarations: [NotificationComponent],
  imports: [SharedModule, MatProgressSpinnerModule],
  exports: [NotificationComponent],
  entryComponents: [],
})
export class CoreModule {}
