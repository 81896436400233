<div class="overall">
  <h1>Reweigh Application Settings</h1>
  <div class="close">
    <button
      mat-icon-button
      (click)="close()"
      class="close"
      matTooltip="Click to close or Escape or Click outside the dialog"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="content">
    <mat-accordion multi>
      <mat-expansion-panel class="main-card" [expanded]="true" xpoExpansionPanelDark>
        <mat-expansion-panel-header>
          <mat-panel-title>Roles</mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <xpo-card class="sub-card">
            <label class="field_label"
            >See application with role
              <mat-icon [matTooltip]="'highest current role: ' +
                      currentUserHighestRoleString +'. By setting your role you ' +
                       'can see other versions of this application'">info
              </mat-icon>
            </label>
            <xpo-card-content>
              <mat-select
                [formControl]="applicationRoleFormControl"
                xpoSelect
                aria-label="Select an application role"
                (selectionChange)="onApplicationRoleSelectChanged($event)"
              >
                <mat-option *ngFor="let applicationRole of applicationRoles" [value]="applicationRole.value">
                  {{ applicationRole.label }}</mat-option
                >
              </mat-select>
            </xpo-card-content>
          </xpo-card>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="actions">
      <button
        mat-button
        xpoSmallButton
        (click)="onResetSettingsClicked($event)"
      >
        <mat-icon>clear_all</mat-icon>
        Reset Settings
      </button>

    </div>
  </div>
</div>
