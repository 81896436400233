import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LogHeaderUpdateInfo } from '@shared/interfaces';
import { FailedLogHeaderTableColmunNames } from './enums/failed-log-header-table-columns-names.enum';

@Component({
  selector: 'app-failed-log-header-update-dialog',
  templateUrl: './failed-log-header-update-dialog.component.html',
  styleUrls: ['./failed-log-header-update-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'failed-log-header-update-dialog',
  },
})
export class FailedLogHeaderUpdateDialogComponent implements OnInit {
  readonly failedLogHeaderTableColmunNames = FailedLogHeaderTableColmunNames;
  showRetryButton = false;
  displayedColumns: string[] = [
    this.failedLogHeaderTableColmunNames.LOG_HEADER_ID,
    this.failedLogHeaderTableColmunNames.PRO_NBR,
    this.failedLogHeaderTableColmunNames.ERROR_MESSAGE,
    this.failedLogHeaderTableColmunNames.RETRY,
  ];

  constructor(
    public dialogRef: MatDialogRef<FailedLogHeaderUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LogHeaderUpdateInfo
  ) {}

  ngOnInit(): void {
    this.showRetryButton = this.data.errors.some((logHeaderUpdateError) => logHeaderUpdateError.canRetry);
  }

  close(response = false): void {
    this.dialogRef.close(response);
  }
}
