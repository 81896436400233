import {UiEntityProperty} from '@shared/classes/entities/ui-entity-property';

export class UiEntity {
  protected _properties: UiEntityProperty[];
  protected _entityTypeName: string;

  protected propertiesSortedByExpandedColumnIndex: UiEntityProperty[] = null;
  protected propertiesSortedByCollapsedColumnIndex: UiEntityProperty[] = null;

  constructor(entityTypeName: string, properties: UiEntityProperty[]) {
    this._entityTypeName = entityTypeName;
    this._properties = properties;
    this.initProperties();
  }

  protected initProperties() {
    for (const property of this._properties) {
      property.init();
    }
  }

  getPropertiesSortedByExpandedColumnIndex(): UiEntityProperty[] {
    if (!this.propertiesSortedByExpandedColumnIndex) {
      this.propertiesSortedByExpandedColumnIndex = [];
      for (const property of this.properties) {
        this.propertiesSortedByExpandedColumnIndex.push(property);
      }
      this.propertiesSortedByExpandedColumnIndex = this.propertiesSortedByExpandedColumnIndex.sort((a, b) => {
        let result: number = 0;

        result = a.uiEntityPropertyOptions?.expandedColumnIndex - b.uiEntityPropertyOptions?.expandedColumnIndex;
        return result;
      });
    }

    return this.propertiesSortedByExpandedColumnIndex;
  }

  get properties(): UiEntityProperty[] {
    return this._properties;
  }

  get entityTypeName(): string {
    return this._entityTypeName;
  }

  getPropertiesSortedByCollapsedColumnIndex(): UiEntityProperty[] {
    if (!this.propertiesSortedByCollapsedColumnIndex) {
      this.propertiesSortedByCollapsedColumnIndex = [];
      for (const property of this.properties) {
        this.propertiesSortedByCollapsedColumnIndex.push(property);
      }
      this.propertiesSortedByCollapsedColumnIndex = this.propertiesSortedByCollapsedColumnIndex.sort((a, b) => {
        let result: number = 0;

        result = a.uiEntityPropertyOptions?.collapsedColumnIndex - b.uiEntityPropertyOptions?.collapsedColumnIndex;
        return result;
      });
    }
    // console.debug('getPropertiesSortedByCollapsedColumnIndex for:' + this.entityTypeName, this.propertiesSortedByCollapsedColumnIndex);

    return this.propertiesSortedByCollapsedColumnIndex;
  }
}
