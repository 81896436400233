<div class="reweigh-header-record-details-cell">
  <xpo-busy-loader
    class="reweigh-header-record-details-cell_loader"
    *ngIf="!logDetail; else logHeaderDataTpl"
  ></xpo-busy-loader>
  <ng-template #logHeaderDataTpl>
    <div class="reweigh-header-record-details-cell_no-records" *ngIf="!logDetail.length; else loaderTpl">
      <mat-icon>insert_drive_file</mat-icon>
      No records to show
    </div>
    <ng-template #loaderTpl>
      <reweigh-log-details [tableData]="logDetail" [proNbrTxtInput]="proNumberTxt"></reweigh-log-details>
    </ng-template>
  </ng-template>
</div>
