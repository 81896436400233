<div *ngIf="show" class="app-Notification">
  <div class="app-Notification-content">
    <mat-progress-spinner
      *ngIf="showProgressSpinner"
      mode="indeterminate"
      [strokeWidth]="5"
      [diameter]="60"
      class="app-Notification-spinner"
    >
    </mat-progress-spinner>
    <div class="app-Notification-message">{{ message }}</div>
  </div>
</div>
