<!--<ng-template *ngIf="logHeaderSummaryGroups;else noLogHeaderSummaryGroups">-->
<div class="overall">
  <div class="expansion-controls">
    <a xpo-regular-link (click)="accordion.openAll()"
    >Expand All
      <mat-icon>expand_more</mat-icon>
    </a>
    <span class="separator">/</span>
    <a xpo-regular-link (click)="accordion.closeAll()"
    >Collapse All
      <mat-icon>expand_less</mat-icon>
    </a>
  </div>
  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel
      class="main-card"
      [expanded]="true"
      *ngFor="let group of logHeaderSummaryGroups?.groups; index as groupIndex"
      data-test="reweig-log-summary-expansion-panel"
      xpoExpansionPanelDark
    >
      <mat-expansion-panel-header>
        <mat-panel-title> {{ group.title }} </mat-panel-title>
        <ng-container *ngIf="groupIndex === 0">
          <a
            xpo-link
            class="header-link"
            (click)="navigateToShipmentDetails($event)"
            matTooltip="Open shipment details"
          >
            SHIPMENT DETAILS
          </a>
        </ng-container>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container *ngFor="let firstGroup of group.fields">
          <ng-container *ngIf="firstGroup">
            <xpo-card class="sub-card" *ngIf="firstGroup.fields; else singleInfoTpl">
              <xpo-card-header class="sub-card-header">
                <xpo-card-title>{{ firstGroup.title }}</xpo-card-title>
              </xpo-card-header>
              <xpo-card-content>
                <ng-container *ngFor="let secondGroup of firstGroup.fields">
                  <div class="field" *ngIf="isFieldVisible(secondGroup)">
                    <label class="field_label">{{ secondGroup.title }}</label>
                    <a
                      xpo-link
                      class="field_value"
                      (click)="onSummaryGroupItemLinkClicked(secondGroup)"
                      [matTooltip]="secondGroup.linkToolTipText || null"
                      matTooltipPosition="right"
                      *ngIf="secondGroup.isLink; else secondGroupStandardInput"
                    >{{ secondGroup.value }}</a
                    >
                  </div>
                  <ng-template #secondGroupStandardInput>
                    <span class="field_value"
                          *ngIf="secondGroup.value && isFieldVisible(secondGroup)">{{ secondGroup.value }}</span>
                  </ng-template>
                </ng-container>
              </xpo-card-content>
            </xpo-card>
            <ng-template #singleInfoTpl>
              <div class="field" *ngIf="isFieldVisible(firstGroup)">
                <label class="field_label">{{ firstGroup.title }}</label>
                <a
                  xpo-link
                  class="field_value"
                  (click)="onSummaryGroupItemLinkClicked(firstGroup)"
                  [matTooltip]="firstGroup.linkToolTipText || null"
                  matTooltipPosition="right"
                  *ngIf="firstGroup.isLink; else firstGroupStandardInput"
                >{{ firstGroup.value }}</a
                >
              </div>
              <ng-template #firstGroupStandardInput>
                <span class="field_value">
                  {{ firstGroup.value }}
                  <button
                    *ngIf="firstGroup?.hasCustomTemplate && imageCreatedInd"
                    mat-icon-button
                    matTooltip="Click to download certificate"
                    matTooltipPosition="after"
                    (click)="onDownloadCertificateClicked()"
                  >
                    <mat-icon class="mat-icon-download">download</mat-icon>
                  </button>
                  <!-- hide this until we can use PDF -->
                  <!-- <button *ngIf="firstGroup?.hasCustomTemplate && imageCreatedInd" mat-icon-button matTooltip="Click to open certificate" matTooltipPosition="after" (click)="onViewCertificateClicked()" >
                <mat-icon class="mat-icon-download">description</mat-icon>
              </button> -->
                </span>
              </ng-template>
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
    <!--    Detail records-->
    <mat-expansion-panel class="main-card detail-card" [expanded]="true" xpoExpansionPanelDark>
      <mat-expansion-panel-header>
        <mat-panel-title>Detail Records</mat-panel-title>
        <mat-slide-toggle
          (click)="onCollapseExpandColumnsClicked($event, ReweighLogSummaryComponent.ReweighLogDetailsComponent)"
        >{{ReweighLogSummaryBase.COLLAPSE_EXPAND_LABEL_CONTENT}}</mat-slide-toggle>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="reweigh-header-record-details_no-records" *ngIf="!logDetails?.length; else showLogDetailsTpl">
          <mat-icon>insert_drive_file</mat-icon>
          No records to show
        </div>
        <ng-template #showLogDetailsTpl>
          <div class="scrollable-auto">
            <reweigh-log-details
              class="reweigh-header-record-details"
              [tableData]="logDetails"
              [isHeaderTitleDisplayedInput]="false"
            ></reweigh-log-details>
          </div>
        </ng-template>
      </ng-template>
    </mat-expansion-panel>
    <!--    Dimensions-->
    <mat-expansion-panel class="main-card detail-card" [expanded]="true" xpoExpansionPanelDark
                         *ngIf="false && hasCurrentUserWriteAccess">
      <mat-expansion-panel-header>
        <mat-panel-title>Shipment Dimensions</mat-panel-title>
        <mat-slide-toggle
          (click)="onCollapseExpandColumnsClicked($event, ReweighLogSummaryComponent.ShipmentDimensions)"
        >{{ReweighLogSummaryBase.COLLAPSE_EXPAND_LABEL_CONTENT}}</mat-slide-toggle>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div
          class="reweigh-header-record-details_no-records"
          *ngIf="!hasShipmentDimensions; else showShmDimsTpl"
        >
          <mat-icon>insert_drive_file</mat-icon>
          No records to show
        </div>
        <ng-template #showShmDimsTpl>
          <div class="scrollable-auto">
            <app-shipment-dimensions></app-shipment-dimensions>
          </div>
        </ng-template>
      </ng-template>
    </mat-expansion-panel>

    <!--History-->
    <mat-expansion-panel
      class="main-card detail-card"
      [expanded]="true"
      xpoExpansionPanelDark
      *ngIf="hasCurrentUserWriteAccess"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>Header History Records</mat-panel-title>
        <mat-slide-toggle
          (click)="onCollapseExpandColumnsClicked($event, ReweighLogSummaryComponent.HeaderHistory)"
        >{{ReweighLogSummaryBase.COLLAPSE_EXPAND_LABEL_CONTENT}}</mat-slide-toggle>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="scrollable-auto">
          <app-reweigh-log-header-hist [logHeaderId]="logHeaderId"></app-reweigh-log-header-hist>
        </div>
      </ng-template>
    </mat-expansion-panel>
    <mat-expansion-panel
      class="main-card detail-card"
      [expanded]="true"
      xpoExpansionPanelDark
      *ngIf="hasCurrentUserWriteAccess"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>Detail History Records</mat-panel-title>
        <mat-slide-toggle
          (click)="onCollapseExpandColumnsClicked($event, ReweighLogSummaryComponent.DetailsHist)"
        >{{ReweighLogSummaryBase.COLLAPSE_EXPAND_LABEL_CONTENT}}</mat-slide-toggle>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="scrollable-auto">
          <app-reweigh-log-detail-hist [logHeaderId]="logHeaderId"></app-reweigh-log-detail-hist>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
  <!--</ng-template>-->
  <!--<ng-template #noLogHeaderSummaryGroups>-->
  <!--  Loading...-->
  <!--</ng-template>-->
</div>
