import { Component } from '@angular/core';
import { YesNoEnum } from '@xpo-ltl/ngx-ltl';
import { LogHeader } from '@xpo-ltl/sdk-reweigh';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-rw-yes-no-renderer-component',
  templateUrl: './rw-yes-no-renderer.component.html',
  styleUrls: ['./rw-yes-no-renderer.component.scss'],
})
export class RwYesNoRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  rowData: LogHeader;

  isTrue: boolean = false;
  value: string;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const lValue: string = this.params?.value;
    if (lValue === YesNoEnum.Yes) {
      this.isTrue = true;
    }
    this.value = lValue;
    this.rowData = this.params.node.data;
  }

  refresh(): boolean {
    return false;
  }
}
