import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {ReweighApiServiceWrapper} from '@shared/services/sdk/reweigh-api-service-wrapper.service';
import { LogHeader, LogHeaderHist, ReweighException } from '@xpo-ltl/sdk-reweigh';

@Component({
  selector: 'app-rw-exception-cd-dialog',
  templateUrl: './rw-exception-cd-dialog.component.html',
  styleUrls: [
    './rw-exception-cd-dialog.component.scss',
    '../../reweigh-log-headers/reweigh-log-headers-home/cell-renderers/rw-yes-no-renderer-component/rw-yes-no-renderer.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class RwExceptionCdDialogComponent implements AfterViewInit {
  @ViewChild(MatSort, { static: true }) dataSourceSort: MatSort;

  isDatasourceSetted: boolean = false;

  logHeader: LogHeader;
  logHeaderHist: LogHeaderHist;

  currentReweighException: ReweighException;
  datasource: MatTableDataSource<ReweighException>;
  selection = new SelectionModel<ReweighException>(false, []);

  visibleColumns: string[] = [
    'exceptionCd',
    'exceptionMessage',
    'exceptionStatus',
    'promptPermittedInd',
    'resubmittableInd',
    'retryCount',
    'expectedInd',
    'bigQueryCategory',
    'detailsInHeaderInd',
    'className',
    'processedCorrectionInd',
  ];

  constructor(
    public dialogRef: MatDialogRef<RwExceptionCdDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reweighService: ReweighApiServiceWrapper
  ) {
    this.logHeader = data.logHeader;
    this.logHeaderHist = data.logHeaderHist;
    if (reweighService.isReweighExceptionsLoaded) {
      this.datasource = new MatTableDataSource(reweighService.reweighExceptions);
      this.isDatasourceSetted = true;
    } else {
      this.datasource = new MatTableDataSource();
    }
  }

  static getDialogConfig(logHeader: LogHeader | undefined, logHeaderHist: LogHeaderHist | undefined) {
    let lId: number;
    if (logHeader) {
      lId = logHeader.logHeaderId;
    } else if (logHeaderHist) {
      lId = logHeaderHist.logHeaderId;
    }
    return {
      maxWidth: '90vw',
      maxHeight: '90vh',
      height: '90%',
      width: '90%',
      data: {
        title: `Exception Code for Log Header ID ${lId}`,
        logHeader: logHeader,
        logHeaderHist: logHeaderHist,
      },
    };
  }

  private _setCurrentReweighException() {
    if (this.logHeader && this.logHeader.exceptionCd) {
      this.currentReweighException = this.reweighService.getExceptionByExceptionCd(this.logHeader.exceptionCd);
    } else if (this.logHeaderHist && this.logHeaderHist.exceptionCd) {
      this.currentReweighException = this.reweighService.getExceptionByExceptionCd(this.logHeaderHist.exceptionCd);
    }
  }

  close() {
    this.dialogRef.close();
  }

  ngAfterViewInit(): void {
    this.datasource.sort = this.dataSourceSort;
    setTimeout(() => {
      // avoid
      if (!this.isDatasourceSetted) {
        this.reweighService.loadReweighExceptionsIfEmpty().subscribe((aReweighExceptions: ReweighException[]) => {
          this.isDatasourceSetted = true;
          this.datasource.data = aReweighExceptions;
          this._setCurrentReweighException();
        });
      } else {
        this._setCurrentReweighException();
      }
    });
  }
}
