import { Injectable } from '@angular/core';

import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { ListReweighLogsResp, LogHeader } from '@xpo-ltl/sdk-reweigh';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ExtendedLogHeader } from '@shared/classes/entities/extended-log-header';
import { LogHeaderBoardMetadata } from '@shared/interfaces';
import { ReweighLogHeaderFilter } from '@shared/models/reweigh-log';
import {ReweighApiServiceWrapper} from '@shared/services/sdk/reweigh-api-service-wrapper.service';
import { LogHeaderBoardStateSources } from 'app/reweigh-log-headers/reweigh-log-headers-home/enums/reweigh-log-header-board-state-source.enum';

@Injectable({
  providedIn: 'root',
})
export class LogHeadersDataSourceService extends XpoBoardDataSource<XpoBoardData<LogHeader[]>> {
  private _reweighLogHeaderFilter: ReweighLogHeaderFilter;

  private _metadataSubject = new BehaviorSubject<LogHeaderBoardMetadata>(null);
  metadata$: Observable<LogHeaderBoardMetadata> = this._metadataSubject.asObservable();
  visibleRows$ = this.state$.pipe(map((state: XpoBoardState) => state.visibleRows));
  recordsCount: number = 0;

  constructor(private reweighService: ReweighApiServiceWrapper) {
    super();
  }

  setFilter(filter: ReweighLogHeaderFilter): void {
    this._reweighLogHeaderFilter = filter;
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData<ExtendedLogHeader[]>> {
    if (!this._reweighLogHeaderFilter) {
      return of(new XpoBoardData(state, [], 0, this.pageSize)).pipe(tap(() => this.emitSearchMetadata()));
    }
    return this.reweighService.listReweighLogs(this._reweighLogHeaderFilter).pipe(
      tap(
        (response: ListReweighLogsResp) => {
          this.emitSearchMetadata(
            this._reweighLogHeaderFilter.reSubmittableInd,
            this._reweighLogHeaderFilter.certificateReadyToBeCreatedInd,
            response.lastRefreshDateTime,
            response.listInfo?.numberOfRows
          );
        },
        (error: any) => {
          console.error('fetchData error', error);
        }
      ),
      map((response: ListReweighLogsResp) => {
        const result: ExtendedLogHeader[] = [];
        for (let i = 0; i < response?.reweighLogHeaders?.length; i++) {
          const logHeader: LogHeader = response.reweighLogHeaders[i];
          result.push(ExtendedLogHeader.toExtendedLogHeader(logHeader));
        }
        return result;
      }),
      map((extendedLogHeaders: ExtendedLogHeader[]) => {
        this.recordsCount = extendedLogHeaders?.length;
        return new XpoBoardData(state, extendedLogHeaders, extendedLogHeaders.length, this.pageSize);
      })
    );
  }

  emitSearchMetadata(
    isResubmittable: boolean = false,
    notHavingCertificate: boolean = false,
    lastUpdated: Date = null,
    numberOfRows: number = 0
  ): void {
    this._metadataSubject.next({
      isResubmittable,
      notHavingCertificate,
      lastUpdated,
      numberOfRows,
    });
  }

  removeSelection(): void {
    this.setState({
      selection: [],
      source: LogHeaderBoardStateSources.CUSTOM_SELECTION_CHANGE,
    });
  }
}
