import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OperatorLogHeaderHist } from '@shared/classes/entities/operator-log-header-hist';
import { LogHeader } from '@xpo-ltl/sdk-reweigh';

// todo pg: might be even prettyier:
// https://stackoverflow.com/questions/4810841/pretty-print-json-using-javascript/7220510#7220510
@Component({
  selector: 'app-reweigh-header-details-dialog',
  templateUrl: './reweigh-header-error-details-dialog.component.html',
  styleUrls: ['./reweigh-header-error-details-dialog.component.scss', '../info-dialog/info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReweighHeaderErrorDetailsDialogComponent implements OnInit {
  logHeader: LogHeader;
  logHeaderHist: OperatorLogHeaderHist;

  constructor(
    public dialogRef: MatDialogRef<ReweighHeaderErrorDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.logHeader = data.logHeader;
    this.logHeaderHist = data.logHeaderHist;
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
