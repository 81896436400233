import {Injectable} from '@angular/core';
import {ExtendedInspectionDimension} from '@shared/classes/entities/extended-inspection-dimension';
import {ReweighAppNotificationService} from '@shared/services/reweigh-app-notification.service';
import {ApiServiceWrapper} from '@shared/services/sdk/api-service-wrapper';
import {UserRoleService} from '@shared/services/user-role';
import {
  GetInspectionDimensionsForShipmentPath,
  GetInspectionDimensionsForShipmentResp,
  InspectionDimension,
  InspectionsApiService
} from '@xpo-ltl/sdk-inspections';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InspectionsApiServiceWrapper extends ApiServiceWrapper<InspectionsApiService> {

  constructor(
    protected baseService: InspectionsApiService,
    protected reweighAppNotificationService: ReweighAppNotificationService,
    protected userRoleService: UserRoleService
  ) {
    super(baseService, reweighAppNotificationService, userRoleService);
  }

  /**
   * https://xpo.atlassian.net/browse/LEI-1887
   *
   * @param aShipmentInstId
   */
  getInspectionDimensionsForShipment(aShipmentInstId: number): Observable<ExtendedInspectionDimension[]> {
    if (isNaN(+aShipmentInstId) || aShipmentInstId <= 0) {
      const msg: string = 'given parameter aShipmentInstId is not a number or <=0';
      console.error(msg + ':', aShipmentInstId);
      throw Error(msg);
    } else {
      const pathParams: GetInspectionDimensionsForShipmentPath = new GetInspectionDimensionsForShipmentPath();
      pathParams.shipmentInstanceId = aShipmentInstId;
      const inspectionDimensionsForShipment: Observable<GetInspectionDimensionsForShipmentResp> = this.baseService.getInspectionDimensionsForShipment(pathParams,
        this.getSimpleDataOptions());
      return inspectionDimensionsForShipment.pipe(
        map((response: GetInspectionDimensionsForShipmentResp) => {
          const result: ExtendedInspectionDimension[] = [];
          if (response.inspectionDimensions?.length > 0) {
            for (let i = 0; i < response.inspectionDimensions.length; i++) {
              const inspectionDimension: InspectionDimension = response.inspectionDimensions[i];
              const extDim: ExtendedInspectionDimension = ExtendedInspectionDimension.toExtendedInspectionDimension(inspectionDimension);
              result.push(extDim);
            }
          }
          return result;
        }),
        catchError((error) =>
          this.handleError(error)
        )
      );
    }
  }

}
