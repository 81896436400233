import { Injectable } from '@angular/core';
import {
  ReweighLogHeaderViewsComponent
} from '../../reweigh-log-headers/reweigh-log-header-views/reweigh-log-header-views.component';

@Injectable({
  providedIn: 'root'
})
export class ReweighLogHeaderViewsService {

  reweighLogHeaderViewsComponent: ReweighLogHeaderViewsComponent;

  constructor() { }

}
