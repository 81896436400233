import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '@shared/classes/date-helper';
import { LogHeaderSummaryGroups } from '@shared/classes/log-header-summary-group/log-header-summary-group';
import { ReweighLogDateFormats } from '@shared/enums/reweigh-log/date-formats.enum';

@Pipe({
  name: 'portlandDateOrNa',
})
export class PortlandDateOrNa extends DatePipe implements PipeTransform {
  transform(value: any): any {
    const result =
      DateHelper.getPortandTzFormattedDate(value, ReweighLogDateFormats.ANGULAR_PIPE) ||
      LogHeaderSummaryGroups.DEFAULT_NULL_VALUE;
    return result;
  }
}
