import { Injectable } from '@angular/core';
import { ProNumberHelper } from '@shared/classes/pro-number-helper/pro-number-helper';
import { ConfigManagerProperties } from '@shared/enums/config-manager-properties.enum';
import { ReweighAppNotificationService } from '@shared/services/reweigh-app-notification.service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

@Injectable({
  providedIn: 'root',
})
export class ShipmentDetailsService {
  constructor(
    private configManagerService: ConfigManagerService,
    protected reweighAppNotificationService: ReweighAppNotificationService
  ) {}

  openShipmentDetails(shipmentInstanceId: number, proNbr: string) {
    if (!shipmentInstanceId && !proNbr) {
      this.reweighAppNotificationService.error('Shipment details cannot be found');
    } else {
      let csShipmentListRegionUrl = this.configManagerService.getSetting<string>(
        ConfigManagerProperties.csShipmentListRegionUrl
      );

      if (csShipmentListRegionUrl && csShipmentListRegionUrl[csShipmentListRegionUrl.length - 1] !== '/') {
        csShipmentListRegionUrl += '/';
      }

      // shipmentInstanceId is undefined for shipments coming from Legacy handhelds.
      // we will use proNbr and open shipment-list for them.
      let shipmentManagementAppBaseUrl: string;
      proNbr = ProNumberHelper.getNineDigitString(proNbr);
      shipmentInstanceId > 0
        ? (shipmentManagementAppBaseUrl = `${csShipmentListRegionUrl}shipment-details/${shipmentInstanceId}`)
        : (shipmentManagementAppBaseUrl = `${csShipmentListRegionUrl}shipment-list?proNbrs=${proNbr}`);

      let shipmentManagementAppLocation: string;
      shipmentInstanceId > 0
        ? (shipmentManagementAppLocation = 'ShipmentDetail')
        : (shipmentManagementAppLocation = 'ShipmentList');

      window.open(shipmentManagementAppBaseUrl);
    }
  }
}
