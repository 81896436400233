import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { OperatorLogHeaderHist } from '@shared/classes/entities/operator-log-header-hist';
import { UiEntities } from '@shared/classes/entities/ui-entities';
import { UiEntity } from '@shared/classes/entities/ui-entity';
import { UiEntityProperty } from '@shared/classes/entities/ui-entity-property';
import { AgGridUtil } from '@shared/classes/utils/ag-grid-util';
import { MiscUtil } from '@shared/classes/utils/misc-util';
import { UiEntityMatTableComponent } from '@shared/components/ui-entity-mat-table/ui-entity-mat-table-component';
import { ConstantsService } from '@shared/services/constants';
import {ReweighLogSummaryService} from '@shared/services/reweigh-log-summary.service';
import {ReweighApiServiceWrapper} from '@shared/services/sdk/reweigh-api-service-wrapper.service';
import { ReweighLogHeadersGridService } from '@shared/services/reweigh-log-headers-grid';
import { UserRoleService } from '@shared/services/user-role';
import { XpoAgGridBoardColumn } from '@xpo-ltl/ngx-ltl-board-grid';
import { UiEntityTableCellDirective } from '../../../directives/ ui-entity-table-cell-directive';
import { ReweighLogHistTableCustomComponent } from '../mat-table-cell-projections/reweigh-log-hist-table-custom/reweigh-log-hist-table-custom.component';
import { ExpandableCollapsableComponent } from '@shared/enums/expandable-collapsable-component';

@Component({
  selector: 'app-reweigh-log-header-hist',
  templateUrl: '../../../shared/components/ui-entity-mat-table/ui-entity-mat-table.component.html',
  styleUrls: [
    '../lib/css/reweigh-log-table-section.scss',
    '../../../shared/components/ui-entity-mat-table/ui-entity-mat-table.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  // for @viewChild and inputs to work with inheritence
  providers: [{ provide: ReweighLogHeaderHistComponent, useExisting: UiEntityMatTableComponent }],
})
export class ReweighLogHeaderHistComponent
  extends UiEntityMatTableComponent<OperatorLogHeaderHist, ReweighLogHistTableCustomComponent>
  implements OnInit, AfterViewInit {
  /**
   * Cant be pushed on the parent
   * @param content
   */
  @ViewChild(MatSort, { static: false }) set content(content: ElementRef) {
    this.tableSort = content;
    if (this.tableSort) {
      this.dataSource.sort = this.tableSort;
    }
  }

  @ViewChildren(UiEntityTableCellDirective) uiEntityTableCellDirectiveViewChildren: QueryList<
    UiEntityTableCellDirective<OperatorLogHeaderHist>
  >;

  @Input() logHeaderId: number;

  constructor(
    protected constantsService: ConstantsService,
    protected reweighApiServiceWrapper: ReweighApiServiceWrapper,
    private changeDetectorRefs: ChangeDetectorRef,
    private dialog: MatDialog,
    private userRoleService: UserRoleService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected reweighLogSummaryService: ReweighLogSummaryService,
  ) {
    super(reweighLogSummaryService);
    this.isTitleSectionDisplayed = true;
    reweighLogSummaryService.setReweighLogHeaderHistComponent(this);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    // each time one element with that directive has been inserted into the DOM, then do...
    this.uiEntityTableCellDirectiveViewChildren.changes.subscribe((aUiEntityTableCellDirectiveQryList: any) => {
      // note that we receive aUiEntityTableCellDirectiveQryList as argument,
      // but its the same as this.uiEntityTableCellDirectiveViewChildren so we can use that one as well
      setTimeout(() => {
        this.loadRefs(this.uiEntityTableCellDirectiveViewChildren, this.getComponentFactoryToProject());
      });
    });
  }

  getComponentFactoryToProject(): ComponentFactory<ReweighLogHistTableCustomComponent> {
    // tslint:disable-next-line:max-line-length
    const lComponentFactoryToProject: ComponentFactory<ReweighLogHistTableCustomComponent> = this.componentFactoryResolver.resolveComponentFactory<
      ReweighLogHistTableCustomComponent
    >(ReweighLogHistTableCustomComponent);
    return lComponentFactoryToProject;
  }

  ngOnInit() {
    // this.loadRefs()
  }

  onLoadClicked(anEvent) {
    this.reweighApiServiceWrapper.listHistoryForLogHeader(this.logHeaderId).subscribe(
      (response: OperatorLogHeaderHist[]) => {
        this.dataSource.data = response;
        this.dataIsLoaded = true;
        this.changeDetectorRefs.detectChanges();
        this.isTitleSectionDisplayed = false;
      },
      (error) => {
        // should already be caught by service wrapper
        console.error('onLoadClicked error loading');
      }
    );
  }

  getElementTableHeaderTitle(aUiEntityProperty: UiEntityProperty): string {
    const agGridColumnDefs: XpoAgGridBoardColumn[] = [
      ...ReweighLogHeadersGridService.getPrimaryColumns(this.userRoleService),
      ...ReweighLogHeadersGridService.secondaryColumns,
    ];
    const result: string = AgGridUtil.getHeaderNameFromColumnDefOrUiEntityDef(
      aUiEntityProperty,
      agGridColumnDefs,
      [...UiEntities.COMMON_ENTITIES_FOR_LABELS.properties, ...this.getUiEntity().properties],
      false
    );
    return result;
  }

  getElementTableHeaderTooltip(uiEntityProperty: UiEntityProperty): string {
    const result: string = AgGridUtil.getHeaderToolTipFromUiEntityProperties(uiEntityProperty, [
      ...UiEntities.COMMON_ENTITIES_FOR_LABELS.properties,
      ...this.getUiEntity().properties,
    ]);
    return result;
  }

  getUiEntity(): UiEntity {
    return UiEntities.LOG_HEADER_HIST;
  }

  getMainTitle(): string {
    return 'Log Header History for header: ' + this.logHeaderId;
  }

  isLoadDataButtonDisplayed(): boolean {
    return true;
  }

  getSortDefaultColumnName(): string {
    return 'histCreatedDateTime';
  }

  getValueFromRowData(aRowData: any, aUiEntityProperty: UiEntityProperty): string {
    let result: string;
    if (aUiEntityProperty.propertyName === 'shipmentInstId') {
      result = MiscUtil.getValueFromObjectProperty(aRowData, aUiEntityProperty.propertyName);
    } else {
      result = super.getValueFromRowData(aRowData, aUiEntityProperty);
    }
    return result;
  }

  getComponentForColumnsExpandCollapse(): ExpandableCollapsableComponent {
    return ExpandableCollapsableComponent.HeaderHistory;
  }


}
