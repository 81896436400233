import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ElementRef,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {ExtendedInspectionDimension} from '@shared/classes/entities/extended-inspection-dimension';
import {OperatorLogDetailHist} from '@shared/classes/entities/operator-log-detail-hist';
import {UiEntities} from '@shared/classes/entities/ui-entities';
import {UiEntity} from '@shared/classes/entities/ui-entity';
import {UiEntityProperty} from '@shared/classes/entities/ui-entity-property';
import {Reweigh} from '@shared/classes/reweigh';
import {AgGridUtil} from '@shared/classes/utils/ag-grid-util';
import {UiEntityMatTableComponent} from '@shared/components/ui-entity-mat-table/ui-entity-mat-table-component';
import {ExpandableCollapsableComponent} from '@shared/enums/expandable-collapsable-component';
import {ConstantsService} from '@shared/services/constants';
import {ReweighLogHeadersGridService} from '@shared/services/reweigh-log-headers-grid';
import {
  ReweighLogHeaderGridServiceColdefs
} from '@shared/services/reweigh-log-headers-grid/reweigh-log-header-grid-service-coldefs';
import { ReweighLogSummaryService } from '@shared/services/reweigh-log-summary.service';
import {ReweighService} from '@shared/services/reweigh-service/reweigh.service';
import {ReweighApiServiceWrapper} from '@shared/services/sdk/reweigh-api-service-wrapper.service';
import {UserRoleService} from '@shared/services/user-role';
import {XpoAgGridBoardColumn} from '@xpo-ltl/ngx-ltl-board-grid';
import {Subscription} from 'rxjs';
import {UiEntityTableCellDirective} from '../../../directives/ ui-entity-table-cell-directive';
import {
  ShipmentDimensionsCustomComponent
} from '../mat-table-cell-projections/shipment-dimensions/shipment-dimensions-custom.component';

@Component({
  selector: 'app-shipment-dimensions',
  templateUrl: '../../../shared/components/ui-entity-mat-table/ui-entity-mat-table.component.html',
  styleUrls: [
    '../lib/css/reweigh-log-table-section.scss',
    '../../../shared/components/ui-entity-mat-table/ui-entity-mat-table.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [{provide: ShipmentDimensionsComponent, useExisting: UiEntityMatTableComponent}],
})
export class ShipmentDimensionsComponent extends UiEntityMatTableComponent<
  ExtendedInspectionDimension,
  ShipmentDimensionsCustomComponent
> implements OnDestroy {
  /**
   * Cant be pushed on the parent
   * @param content
   */
  @ViewChild(MatSort, {static: false}) set content(content: ElementRef) {
    this.tableSort = content;
    if (this.tableSort) {
      this.dataSource.sort = this.tableSort;
    }
  }

  @ViewChildren(UiEntityTableCellDirective) uiEntityTableCellDirectiveViewChildren: QueryList<
    UiEntityTableCellDirective<OperatorLogDetailHist>
  >;

  protected reweighServiceSubscription: Subscription;

  protected currentReweigh: Reweigh;

  constructor(
    protected constantsService: ConstantsService,
    protected reweighApiServiceWrapper: ReweighApiServiceWrapper,
    protected changeDetectorRefs: ChangeDetectorRef,
    protected userRoleService: UserRoleService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected reweighService: ReweighService,
    protected reweighLogSummaryService: ReweighLogSummaryService,
  ) {
    super(reweighLogSummaryService);
    setTimeout(() => {
      this.isTitleSectionDisplayed = false;
    });
    this.reweighServiceSubscription = this.reweighService.subject.subscribe((aReweigh: Reweigh) => {
      this.setFromReweigh(aReweigh);
      // to be executed after component loading
    });
    reweighLogSummaryService.setShipmentDimensionsComponent(this);
  }

  /* tslint:disable use-lifecycle-interface */
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.setFromReweigh(this.reweighService.reweigh);
  }

  getComponentFactoryToProject(): ComponentFactory<ShipmentDimensionsCustomComponent> {
    // tslint:disable-next-line:max-line-length
    const lComponentFactoryToProject: ComponentFactory<ShipmentDimensionsCustomComponent> = this.componentFactoryResolver.resolveComponentFactory<
      ShipmentDimensionsCustomComponent
    >(ShipmentDimensionsCustomComponent);
    return lComponentFactoryToProject;
  }

  getElementTableHeaderTitle(aUiEntityProperty: UiEntityProperty): string {
    const agGridColumnDefs: XpoAgGridBoardColumn[] = [
      ...ReweighLogHeaderGridServiceColdefs.getPrimaryColumns(this.userRoleService),
      ...ReweighLogHeadersGridService.secondaryColumns,
    ];
    const result: string = AgGridUtil.getHeaderNameFromColumnDefOrUiEntityDef(
      aUiEntityProperty,
      agGridColumnDefs,
      [...UiEntities.COMMON_ENTITIES_FOR_LABELS.properties, ...this.getUiEntity().properties],
      true
    );
    return result;
  }

  getUiEntity(): UiEntity {
    return UiEntities.EXTENDED_INSPECTION_DIMENSION;
  }

  getMainTitle(): string {
    return 'Dimensions for header: ' + this.currentReweigh?.getLogHeaderId();
  }

  isLoadDataButtonDisplayed(): boolean {
    return false;
  }

  getSortDefaultColumnName(): string {
    return 'proNbr';
  }

  ngOnDestroy(): void {
    this.reweighServiceSubscription.unsubscribe();
  }

  setFromReweigh(aReweigh: Reweigh) {
    setTimeout(() => {
      this.currentReweigh = aReweigh;
      console.debug('new RW received:', aReweigh);
      if (aReweigh.inspectionDimensions?.length > 0) {
        this.dataSource.data = aReweigh.inspectionDimensions;
      }
    });
  }

  getComponentForColumnsExpandCollapse(): ExpandableCollapsableComponent {
    return ExpandableCollapsableComponent.ShipmentDimensions;
  }


}
