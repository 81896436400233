import {Component, OnInit, ViewEncapsulation} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {XpoLtlLoggedInUser} from '@shared/classes/access/xpo-ltl-logged-in-user';
import {AccountUrls} from '@shared/enums/account-urls.enum';
import {AppCustomIcon} from '@shared/enums/app-custom-icon';
import {AppRoutes} from '@shared/enums/app-routes.enum';
import {ConfigManagerProperties} from '@shared/enums/config-manager-properties.enum';
import {EmailService} from '@shared/services/email/email.service';
import {UserRoleService} from '@shared/services/user-role';

import {ConfigManagerService} from '@xpo-ltl/config-manager';
import {RegionInfo, XpoLtlAuthenticationService} from '@xpo-ltl/ngx-auth';
import {
  XpoLtlAppSwitcherService,
  XpoLtlFeedbackService,
  XpoLtlLoggedInUserService,
  XpoLtlReleaseNotesService,
} from '@xpo-ltl/ngx-ltl';
import {XpoAccountPopoverConfig, XpoAppSwitcherApplication, XpoShellRoute} from '@xpo-ltl/ngx-ltl-core';
import {User} from '@xpo-ltl/sdk-common';
import {invoke as _invoke, isEmpty as _isEmpty} from 'lodash';
import {interval, Observable} from 'rxjs';
import {delay, retryWhen, skipWhile, take, tap} from 'rxjs/operators';
import {
  AppSettingsDialogComponent
} from './components/root/app-settings-dialog-component/app-settings-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  releaseNotesLink: string;
  title: string;
  build: string;
  apps$: Observable<XpoAppSwitcherApplication[]>;
  routes: XpoShellRoute[];
  accountPopoverConfig: XpoAccountPopoverConfig;
  userLoggedIn: boolean;
  isProduction: boolean;
  userFullName: string;
  userEmail: string;
  teamEmail: string;
  emailSubject: string;

  readonly AccountUrls = AccountUrls;

  constructor(
    private configManagerService: ConfigManagerService,
    private emailService: EmailService,
    public feedbackService: XpoLtlFeedbackService,
    private releaseNotesService: XpoLtlReleaseNotesService,
    private appSwitcherService: XpoLtlAppSwitcherService,
    private authService: XpoLtlAuthenticationService,
    private loggedInUserService: XpoLtlLoggedInUserService,
    private userRoleService: UserRoleService,
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer,
  ) {
    this.routes = [
      {
        label: 'Reweigh Logs',
        path: `/${AppRoutes.REWEIGH_LOG_HEADERS}`,
      },
    ];

    /** Shell setup */
    this.title = 'Reweigh';
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    this.isProduction = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
    this.teamEmail = this.configManagerService.getSetting<string>(ConfigManagerProperties.feedbackToAddress);
    this.emailSubject = this.configManagerService.getSetting<string>(ConfigManagerProperties.feedbackSubject);

    this.loggedInUserService.userLoggedIn$.subscribe(() => {
      this.handleLoggedInUser();
    });
    this.releaseNotesLink = this.configManagerService.getSetting<string>(ConfigManagerProperties.releaseNotesLink);

    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe((info: RegionInfo) => {
      // Extra config or API calls here if needed
    });
    this.registerIcons();
  }

  private static getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  handleFeedbackClick(): void {
    this.emailService.openEmailWindow(this.userFullName, this.userEmail);
  }

  private handleLoggedInUser(): void {
    this.loggedInUserService
      .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(5))))
      .subscribe(
        (user: User) => {
          if (user) {
            console.log(`You're logged in as user `, user);
            this.setDynatraceUserIdentity(user);
            this.populateAccountPopover(user);
            this.populateAppSwitcher();
            this.afterUserLogin(user);
            this.userLoggedIn = true;
          }
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }

  private populateAccountPopover(user: User): void {
    this.userFullName = `${user.givenName} ${user.lastName}`;
    this.userEmail = user.emailAddress;

    this.accountPopoverConfig = {
      imageUri: AppComponent.getProfilePictureUrl(user.emailAddress),
      name: this.userFullName,
      onSignOutCallback: (): void => {
        this.signOut();
      },
      links: [
        {title: 'My Account', url: AccountUrls.myAccount},
        {title: 'Help', url: AccountUrls.help},
        {title: 'Privacy Policy', url: AccountUrls.privacyPolicy},
      ],
    };
  }

  private signOut(): void {
    // Removing the local storage keys
    localStorage.clear();
  }

  private populateAppSwitcher(): void {
    this.apps$ = this.appSwitcherService.getAppList();
  }

  private setDynatraceUserIdentity(user: User): void {
    const setUser = (): void =>
      _invoke(window['dtrum'], 'identifyUser', !_isEmpty(user.emailAddress) ? user.emailAddress : user.userId);
    if ((window['dtrum'] || {}).identifyUser) {
      setUser();
    } else {
      let retryCount: number = 0;
      interval(1000)
        .pipe(
          tap(() => retryCount++),
          skipWhile(() => !(window['dtrum'] || {}).identifyUser && retryCount <= 60),
          take(1)
        )
        .subscribe(() => {
          setUser();
        });
    }
  }

  ngOnInit() {
  }

  openSettingsDialogClicked() {
    this.dialog.open(AppSettingsDialogComponent, AppSettingsDialogComponent.getDialogConfig());
  }

  protected afterUserLogin(user: User) {
    const xpoLtlLoggedInUser: XpoLtlLoggedInUser = new XpoLtlLoggedInUser(user);

    this.userRoleService.setCurrentUserFromLoggedInUser(xpoLtlLoggedInUser);
    this.userRoleService.setOverrideableUserFromLocalStorage();

    // console.debug('users:', this.userRoleService.currentUser, this.userRoleService.overrideableUser)

    if (this.userRoleService.currentUserHasScalesAccess()) {
      const lToAddRoute: XpoShellRoute = {
        label: 'Scales',
        path: `/${AppRoutes.REWEIGH_CALIBRATION_HEADERS}`,
      };
      this.addRouteIfNotExists(lToAddRoute);
    }
  }

  protected addRouteIfNotExists(lToAddRoute: XpoShellRoute) {
    let lFound: boolean = false;
    for (const route of this.routes) {
      if (route.path === lToAddRoute.path) {
        lFound = true;
        break;
      }
    }
    if (!lFound) {
      this.routes.push(lToAddRoute);
    }
  }

  private registerIcons() {
    // const ICONS_BASE_DIR: string = 'assets/images/icons/';
    // this.registerIcon(AppCustomIcon.FORKLIFT_TRUCK_WITH_SCALE, ICONS_BASE_DIR + 'forklift-truck-with-scale-only.svg');
  }

}
