import { Component, OnInit } from '@angular/core';
import { YesNoEnum } from '@xpo-ltl/ngx-ltl';
import { LogHeader } from '@xpo-ltl/sdk-reweigh';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-display-indicator-renderer',
  templateUrl: './display-indicator-renderer.component.html',
  styleUrls: ['./display-indicator-renderer.component.scss'],
})
export class DisplayIndicatorRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  rowData: LogHeader;
  trueFalseString: string;

  isTrue: boolean = false;

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    const lValue: string = this.params?.value;
    if (lValue === YesNoEnum.Yes) {
      this.isTrue = true;
    }
    this.trueFalseString = lValue;
    this.rowData = this.params.node.data;
  }

  refresh(): boolean {
    return false;
  }
}
