import { Injectable } from '@angular/core';
import { DmsDocument } from '@shared/classes/dms-document/dms-document';
import { ProNumberHelper } from '@shared/classes/pro-number-helper/pro-number-helper';
import { ConfigManagerProperties } from '@shared/enums/config-manager-properties.enum';
import { ReweighAppNotificationService } from '@shared/services/reweigh-app-notification.service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ApiRequest, HttpOptions } from '@xpo-ltl/data-api';
import {
  DocumentManagementApiService,
  DocumentSearch,
  GetDocumentPath,
  GetDocumentResp,
  RetrieveDmsAuthTokenResp,
  RetrieveDmsAuthTokenRqst,
  SearchDmsDocumentResp,
  SearchDmsDocumentRqst,
} from '@xpo-ltl/sdk-documentmanagement';
import { isEmpty as _isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DmsApiWrapper {
  readonly docClass: string = 'WI';
  protected dmsCorpCode: string;

  constructor(
    private dmsService: DocumentManagementApiService,
    protected reweighAppNotificationService: ReweighAppNotificationService,
    private configManagerService: ConfigManagerService
  ) {
    this.dmsCorpCode = this.configManagerService.getSetting<string>(ConfigManagerProperties.dmsCorpCode);
  }

  // method to initiate ReweighLogSummaryService requests
  getDmsDocument$(proNbr: string, logCreationDate: Date): Observable<DmsDocument> {
    const proNbrNineDigit: string = ProNumberHelper.getNineDigitString(proNbr);
    let currentDmsToken: string;
    return this.getDmsAuthToken().pipe(
      take(1),
      switchMap((token: string) => {
        if (token) {
          currentDmsToken = token;
          return this.searchDmsWIDocument(proNbrNineDigit, logCreationDate, currentDmsToken);
        } else {
          this.reweighAppNotificationService.error('Unable to get token from Document Management System');
          return of(null);
        }
      }),
      switchMap((dmsDocument: DmsDocument) => {
        if (!_isEmpty(dmsDocument)) {
          return this.getDocumentResp(dmsDocument, currentDmsToken).pipe(
            map((documentResp) => {
              dmsDocument.setFromDocumentRespData(documentResp);
              dmsDocument.decodeBase64Data();
              dmsDocument.buildFileName();

              return dmsDocument;
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  // Step 1: get dmsToken
  protected getDmsAuthToken(): Observable<string> {
    const req = new RetrieveDmsAuthTokenRqst();

    return this.dmsService.retrieveDmsAuthToken(req).pipe(
      map((resp: RetrieveDmsAuthTokenResp) => {
        return resp.access_token;
      })
    );
  }

  // Step 2: request documentInfo for a particular PRO number
  protected searchDmsWIDocument(proString: string, minDateTime: Date, dmsToken: string): Observable<DmsDocument> {
    const req = new SearchDmsDocumentRqst();
    const httpOptions: HttpOptions = { headers: { DMSAuth: dmsToken } };
    req.corpCode = this.dmsCorpCode;
    req.searchString = proString;
    req.minDateTime = new Date(minDateTime).toISOString();
    req.dmsAuth = dmsToken;

    return this.dmsService.searchDmsDocument(req, ApiRequest.concealedCall, httpOptions).pipe(
      map((resp: SearchDmsDocumentResp) => {
        if (!_isEmpty(resp?.documentInfo)) {
          const dmsDocument: DmsDocument = new DmsDocument(proString);
          const mostRecentDocument: DocumentSearch = DmsDocument.getMostRecentDocument(resp, this.docClass);
          dmsDocument.setFromDocumentSearchData(mostRecentDocument);
          return dmsDocument;
        } else {
          return null;
        }
      })
    );
  }

  // Step 3: request documentData
  protected getDocumentResp(document: DmsDocument, dmsToken: string): Observable<GetDocumentResp> {
    const docPath: GetDocumentPath = new GetDocumentPath();
    const httpOptions: HttpOptions = { headers: { DMSAuth: dmsToken } };

    docPath.docClass = this.docClass;
    docPath.corpCode = this.dmsCorpCode;
    docPath.docArchiveTimestamp = document.getTimestamp();

    return this.dmsService.getDocument(docPath, { multiPartResp: 'false' }, null, httpOptions).pipe(
      map((resp: GetDocumentResp) => {
        let returnValue = null;
        if (_isEmpty(resp?.documentData)) {
          this.reweighAppNotificationService.error('No document data');
        } else {
          try {
            returnValue = resp;
          } catch {
            this.reweighAppNotificationService.error('Document data is not available');
          }
        }
        return returnValue;
      })
    );
  }
}
