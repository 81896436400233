import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OperatorLogDetailHist } from '@shared/classes/entities/operator-log-detail-hist';
import { ScaleTestUtil } from '@shared/classes/utils/scale-test-util';
import { ScaleTestRouting } from '@shared/interfaces/scale-test-routing';
import {ReweighLogHeaderViewsService} from '@shared/services/reweigh-log-header-views.service';
import { UiEntityTableCellComponentBase } from '../../../../directives/ui-entity-table-cell-component-base';

@Component({
  selector: 'app-reweigh-log-detail-hist-table-custom',
  templateUrl: './reweigh-log-detail-hist-table-custom.component.html',
  styleUrls: ['./reweigh-log-detail-hist-table-custom.component.scss'],
})
export class ReweighLogDetailHistTableCustomComponent extends UiEntityTableCellComponentBase<OperatorLogDetailHist>
  implements OnInit {
  constructor(protected router: Router, protected reweighLogHeaderViewsService: ReweighLogHeaderViewsService) {
    super();
  }

  ngOnInit(): void {}

  onScaleTestSearchClicked(aUiEntityData: OperatorLogDetailHist) {
    ScaleTestUtil.navigateToScaleTestsFor(ScaleTestRouting.buildFromOperatorLogDetailHist(aUiEntityData), this.router);
    this.reweighLogHeaderViewsService.reweighLogHeaderViewsComponent.close();
  }
}
