import { MatTableDataSource } from '@angular/material/table';
import { ExtendedLiftScaleCalibrationHeader } from '@shared/classes/entities/extended-lift-scale-calibration-header';
import { IOperatorFirstnamLastname } from '@shared/classes/entities/operator-firstname-lastname';
import { UiEntityExpandableTableEntity } from '@shared/classes/entities/ui-entity-expandable-table-entity';
import { UiEntityTableEntity } from '@shared/classes/entities/ui-entity-table-entity';
import { LogDetail } from '@xpo-ltl/sdk-reweigh';
import { ScaleForkliftReport } from '../../../reweigh-calibration-headers/lib/scale-forklift-report';

export class OperatorLogDetail extends LogDetail
  implements
    IOperatorFirstnamLastname,
    UiEntityTableEntity,
    UiEntityExpandableTableEntity<ExtendedLiftScaleCalibrationHeader> {
  dataSource: MatTableDataSource<ExtendedLiftScaleCalibrationHeader>;

  operatorFullName: string;
  equipmentFullId: string;
  equipmentIdSuffixNbrString: string;
  private _extendedLiftScaleCalibrationHeader: ExtendedLiftScaleCalibrationHeader;

  /**
   * Cant put into EntitiesTransformer cause of circular dependencies
   * @param simpleLogDetail
   */
  static toOperatorLogDetail(simpleLogDetail: LogDetail): OperatorLogDetail {
    let result: OperatorLogDetail = null;
    if (simpleLogDetail) {
      result = new OperatorLogDetail();
      Object.assign(result, simpleLogDetail); // Object.assign(target, source)
      result.setOperatorFullName();
      result.setEquipmentFullId();
      result.setEquipmentIdSuffixNbrString();
    }
    return result;
  }

  setOperatorFullName() {
    if (this.operatorFirstName) {
      this.operatorFullName = this.operatorFirstName;
    }
    if (this.operatorLastName) {
      if (this.operatorFullName?.length > 0) {
        this.operatorFullName += ' ' + this.operatorLastName;
      }
    }
  }

  setEquipmentFullId() {
    this.equipmentFullId = ScaleForkliftReport.getEquipmentFullId(this.equipmentIdPrefix, this.equipmentIdSuffixNbr);
  }

  private setEquipmentIdSuffixNbrString() {
    this.equipmentIdSuffixNbrString = ScaleForkliftReport.leftPaddedEquipmentIdSuffix(this.equipmentIdSuffixNbr);
  }

  setFromLiftScaleCalibrationHeaders(liftScaleCalibrationHeaders: ExtendedLiftScaleCalibrationHeader[]) {
    if (liftScaleCalibrationHeaders?.length > 0) {
      for (let i = 0; i < liftScaleCalibrationHeaders.length; i++) {
        const lExtendedLiftScaleCalibrationHeader: ExtendedLiftScaleCalibrationHeader = liftScaleCalibrationHeaders[i];
        if (
          lExtendedLiftScaleCalibrationHeader.equipmentIdPrefix === this.equipmentIdPrefix &&
          lExtendedLiftScaleCalibrationHeader.equipmentIdSuffixNbr === this.equipmentIdSuffixNbr &&
          lExtendedLiftScaleCalibrationHeader.vendorScaleId === this.vendorScaleId &&
          lExtendedLiftScaleCalibrationHeader.childProNbr === this.childProNbr
        ) {
          this.extendedLiftScaleCalibrationHeader = lExtendedLiftScaleCalibrationHeader;
        }
      }
    }
    // console.debug('setFromLiftScaleCalibrationHeaders:', this.extendedLiftScaleCalibrationHeader)
  }

  set extendedLiftScaleCalibrationHeader(value: ExtendedLiftScaleCalibrationHeader) {
    this._extendedLiftScaleCalibrationHeader = value;
    this.dataSource = new MatTableDataSource<ExtendedLiftScaleCalibrationHeader>();
    const lData: ExtendedLiftScaleCalibrationHeader[] = [];
    lData.push(value);
    this.dataSource.data = lData;
  }
}
