import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class QueryParamsService {
  constructor(private route: ActivatedRoute, private router: Router) {}

  handleQueryParamsChanged(newQueryParams): void {
    const queryParams = {
      ...this.route.snapshot.queryParams,
      ...newQueryParams,
    };

    this.updateQueryParams(queryParams);
  }

  updateQueryParams(queryParams): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
