import {MiscUtil} from '@shared/classes/utils/misc-util';
import {InspectionDimension} from '@xpo-ltl/sdk-inspections';

export class ExtendedInspectionDimension extends InspectionDimension {

  dimensionsSourceTypeHumanReadable: string;

  static toExtendedInspectionDimension(src: InspectionDimension): ExtendedInspectionDimension {
    let result: ExtendedInspectionDimension = null;
    if (src) {
      result = new ExtendedInspectionDimension();
      Object.assign(result, src); // Object.assign(target, source)
      result.setExtendedData();
    }
    return result;
  }

  protected setExtendedData() {
    this.dimensionsSourceTypeHumanReadable = MiscUtil.upperCaseUnderscoredToWordsString(this.dimensionSourceTypeCd);
  }

}
