import { Injectable } from "@angular/core";
import { ConfigManagerProperties } from "@shared/enums/config-manager-properties.enum";
import { RequestValidator } from "@shared/validators/request.validator";
import { ConfigManagerService } from "@xpo-ltl/config-manager";

@Injectable({
  providedIn: 'root'
} )
export class EmailService {
  isProduction: boolean;
  teamEmail: string;
  emailSubject: string;
  userFullName: string;
  userEmail: string;
  
  constructor( private configManagerService: ConfigManagerService, ) {
    this.isProduction = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
    this.teamEmail = this.configManagerService.getSetting<string>( ConfigManagerProperties.feedbackToAddress );
    this.emailSubject = this.configManagerService.getSetting<string>(ConfigManagerProperties.feedbackSubject);
  }

  openEmailWindow( aUserFullName: string, aUserEmail: string ): void {
    RequestValidator.validateRequestStringIsNotNullOrBlank( aUserFullName, 'User Full Name' );
    RequestValidator.validateRequestStringIsNotNullOrBlank( aUserEmail, 'User Email' );

    let href: string;
    this.userFullName = aUserFullName;
    this.userEmail = aUserEmail;

    if (this.isProduction) {
      // send email to the W&I team for PROD
      href = this.buildBaseHref(this.teamEmail);
    } else {
      // send email only to the person who is testing
      href = this.buildBaseHref(this.userEmail);
    }
    window.location.href = href;
  }

  private buildBaseHref( email: string ): string {
    RequestValidator.validateRequestStringIsNotNullOrBlank( email, 'User Email' );

    return `mailto:${email}?subject=${this.emailSubject} - ${this.userFullName}`;
  }
}
