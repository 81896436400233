import { Injectable } from '@angular/core';

import { ConfigManagerService } from '@xpo-ltl/config-manager';

import { OverrideableReweighApplicationUser } from '@shared/classes/access/overrideable-reweigh-application-user';
import { ReweighApplicationUser } from '@shared/classes/access/reweigh-application-user';
import { XpoLtlLoggedInUser } from '@shared/classes/access/xpo-ltl-logged-in-user';
import { AccessRole } from '@shared/enums';
import { ConfigManagerProperties } from '@shared/enums/config-manager-properties.enum';
import { KeyValueLabel } from '@shared/interfaces';
import { ReweighApplicationRole } from '@shared/services/user-role/reweigh-application-role';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserRoleService {
  constructor(private configManagerService: ConfigManagerService) {
    UserRoleService.isProduction = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);
  }

  protected static isProduction = false;
  currentUserSetSubject: Subject<ReweighApplicationUser> = new Subject<ReweighApplicationUser>();
  overrideableUserSetSubject: Subject<OverrideableReweighApplicationUser | undefined> = new Subject<
    OverrideableReweighApplicationUser | undefined
  >();

  currentUser: ReweighApplicationUser;
  // null if not overridden
  overrideableUser: OverrideableReweighApplicationUser;

  getApplicationRolesAsKeyValueLabels(): KeyValueLabel[] {
    const result: KeyValueLabel[] = [];
    if (this.currentUser) {
      this.currentUser.getAvailableRoles().forEach((lRole: ReweighApplicationRole) => {
        result.push(lRole.toKeyValueLabel());
      });
    }
    return result;
  }

  setApplicationRoleTo(accessRole: AccessRole) {
    this.overrideableUser = new OverrideableReweighApplicationUser(this.currentUser.getXpoLtlLoggedInUser());
    this.overrideableUser.setOverridenRoleToLocalStorage(accessRole);
    this.overrideableUserSetSubject.next(this.overrideableUser);
  }

  setCurrentUserFromLoggedInUser(aXpoLtlLoggedInUser: XpoLtlLoggedInUser) {
    // console.debug("Reweigh current user set to:", aXpoLtlLoggedInUser);
    this.currentUser = new ReweighApplicationUser(aXpoLtlLoggedInUser);
    this.currentUserSetSubject.next(this.currentUser);
  }

  currentUserHasWriteAccess(): boolean {
    const result: boolean =
      this.currentUser?.canWrite() && (this.notHasOverrideableUser() || this.overrideableUser.canWrite());
    // console.debug("currentUserHasWriteAccess:" + result, this.currentUser,
    //    this.overrideableUser, this.overrideableUser?.canWrite());
    return result;
  }

  currentUserHasScalesAccess(): boolean {
    // console.debug("canAccessScales:", this.currentUser);
    const result: boolean =
      this.currentUser?.canAccessScales() && (this.notHasOverrideableUser() || this.overrideableUser.canAccessScales());
    return result;
  }

  notHasOverrideableUser(): boolean {
    return !this.overrideableUser;
  }

  setOverrideableUserFromLocalStorage() {
    if (OverrideableReweighApplicationUser.hasLocalStorageValue()) {
      try {
        this.overrideableUser = new OverrideableReweighApplicationUser(this.currentUser.getXpoLtlLoggedInUser());
        this.overrideableUserSetSubject.next(this.overrideableUser);
      } catch (e) {
        console.error('Error loading overrideable user from local storage', e);
        this.overrideableUser = undefined;
        this.overrideableUserSetSubject.next(this.overrideableUser);
      }
    }
  }

  hasOverrideableUser(): boolean {
    return !this.notHasOverrideableUser();
  }

  resetSettings() {
    if (this.overrideableUser) {
      this.overrideableUser.resetLocalStorage();
      this.overrideableUser = undefined;
      this.overrideableUserSetSubject.next(this.overrideableUser); // undefined
    }
  }
} // end class
