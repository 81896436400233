<ng-template [ngIf]="hasCurrentUserWriteAccess" [ngIfElse]="valueOnly">
  <a
  xpo-link
  *ngIf="logHeader.exceptionCd"
  matTooltip="Click for more details on exception"
  (click)="onExceptionCdClicked()"
  >{{ logHeader.exceptionCd }}</a
>
<a xpo-link *ngIf="!logHeader.exceptionCd" matTooltip="Click to see exceptions list" (click)="onExceptionCdClicked()"
  >None</a
>
</ng-template>
<ng-template #valueOnly>
  {{ logHeader.exceptionCd }}
</ng-template>
