import { Pipe, PipeTransform } from '@angular/core';
import { YesNoOptionPipe } from '@shared/pipes';

@Pipe({ name: 'yesNoOptionOrNa' })
export class YesNoOptionPipeOrNa implements PipeTransform {
  static readonly DEFAULT_NULL_VALUE: string = 'N/A';

  transform(option: boolean): string {
    let result: string;
    if (!option) {
      result = YesNoOptionPipeOrNa.DEFAULT_NULL_VALUE;
    } else {
      result = YesNoOptionPipe.format(option);
    }
    return result;
  }
}
