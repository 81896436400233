import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'info-dialog',
  },
})
export class InfoDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<InfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.data.buttonText = this.data.buttonText || 'Close';
  }

  close(): void {
    this.dialogRef.close();
  }
}
