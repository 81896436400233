import { UiEntityProperty } from '@shared/classes/entities/ui-entity-property';
import { XpoAgGridBoardColumn } from '@xpo-ltl/ngx-ltl-board-grid';
import { ColDef } from 'ag-grid-community';

export class AgGridUtil {
  static getHeaderNameFromColumnDefOrUiEntityDef(
    aUiEntityProperty: UiEntityProperty,
    agGridColumnDefs: XpoAgGridBoardColumn[],
    uiEntityProperties: UiEntityProperty[],
    logWarnIfNotFound: boolean
  ): string {
    let result: string;
    if (aUiEntityProperty.hasLabel()) {
      result = aUiEntityProperty.getLabel();
    } else {
      result = this.getLabelFromUiEntitiesArray(aUiEntityProperty.propertyName, uiEntityProperties, false);
      if (!result) {
        // find in existing columns
        if (agGridColumnDefs && agGridColumnDefs.length > 0) {
          for (let i = 0; i < agGridColumnDefs.length; i++) {
            const agGridColumnDef: ColDef = agGridColumnDefs[i];
            if (agGridColumnDef.field === aUiEntityProperty.propertyName) {
              result = agGridColumnDef.headerName;
              break;
            }
          }
        }
      }
    }
    if (result === undefined) {
      result = aUiEntityProperty.propertyName;
      if (logWarnIfNotFound) {
        console.warn('no column name found for:' + aUiEntityProperty.propertyName, aUiEntityProperty, agGridColumnDefs);
      }
    }
    return result;
  }

  /**
   * if not found returns aUiEntityVariableName with console warning
   * @param aUiEntityVariableName
   * @param uiEntityProperties
   */
  static getLabelFromUiEntitiesArray(
    aUiEntityVariableName: string,
    uiEntityProperties: UiEntityProperty[],
    logWarnIfNotFound: boolean
  ): string {
    let result: string;
    if (uiEntityProperties && uiEntityProperties.length > 0) {
      for (let i = 0; i < uiEntityProperties.length && !result; i++) {
        const uiEntity: UiEntityProperty = uiEntityProperties[i];
        if (uiEntity.propertyName === aUiEntityVariableName && uiEntity.hasLabel()) {
          result = uiEntity.getLabel();
        }
      }
    }
    if (result === undefined) {
      result = aUiEntityVariableName;
      if (logWarnIfNotFound) {
        console.warn('no column name found for:' + aUiEntityVariableName, uiEntityProperties);
      }
    }
    return result;
  }

  static getHeaderToolTipFromUiEntityProperties(
    uiEntityProperty: UiEntityProperty,
    uiEntityProperties: UiEntityProperty[]
  ): string {
    let result: string = null;
    for (let i = 0; i < uiEntityProperties?.length; i++) {
      const lUiEntityProperty: UiEntityProperty = uiEntityProperties[i];
      if (uiEntityProperty.propertyName === lUiEntityProperty.propertyName) {
        result = lUiEntityProperty.getColumnTooltip();
        break;
      }
    }
    return result;
  }
}
